import { WORKORDER_SERVICES_REQUEST, WORKORDER_SERVICES_SUCCESS, WORKORDER_SERVICES_ERROR, UPDATE_SERVICE_REQUEST, REMOVE_SERVICE_REQUEST, COMPLETE_WORK_ORDER_WITH_ZENDESK, COMPLETE_STATUS_CHANGE_SUCCESS, COMPLETE_STATUS_CHANGE_FAILURE, } from '../workLogConstants';
const workorderServicesReducerDefaultState = {
    isFetchingServices: false,
    isCompletingWorkOrder: false,
    errorMessage: '',
    orderedItems: {
        itemSummary: [],
        totalTimeEstimate: 0,
        totalPrice: 0,
    },
    workOrderServiceIdToBeChanged: null,
};
export default (state = workorderServicesReducerDefaultState, action) => {
    const { errorMessage, services, type, workOrderServiceId } = action;
    switch (type) {
        case WORKORDER_SERVICES_REQUEST:
            return Object.assign(Object.assign({}, state), { isFetchingServices: true });
        case WORKORDER_SERVICES_SUCCESS:
            return Object.assign(Object.assign({}, state), { isFetchingServices: false, orderedItems: services, workOrderServiceIdToBeChanged: null });
        case WORKORDER_SERVICES_ERROR:
            return Object.assign(Object.assign({}, state), { isFetchingServices: false, errorMessage });
        case UPDATE_SERVICE_REQUEST:
            return Object.assign(Object.assign({}, state), { workOrderServiceIdToBeChanged: workOrderServiceId, isFetchingServices: !workOrderServiceId });
        case REMOVE_SERVICE_REQUEST:
            return Object.assign(Object.assign({}, state), { workOrderServiceIdToBeChanged: workOrderServiceId });
        case COMPLETE_WORK_ORDER_WITH_ZENDESK:
            return Object.assign(Object.assign({}, state), { isCompletingWorkOrder: true });
        case COMPLETE_STATUS_CHANGE_SUCCESS:
            return Object.assign(Object.assign({}, state), { isCompletingWorkOrder: false });
        case COMPLETE_STATUS_CHANGE_FAILURE:
            return Object.assign(Object.assign({}, state), { isCompletingWorkOrder: false });
        default:
            return state;
    }
};
