import React from 'react';
import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search';
import { SlideDown } from 'react-slidedown';
import Icon from '../../../../icons';
import { ARROW_DOWN } from '../../../../constants/iconNames';
import Variables from '../../../../styles/variables.scss';
export default function CategoryDropdown({ categories, toggleCategoryDropdown, selectedCategory, show, type, value, }) {
    const showDropdown = show && categories.length > 0;
    return (categories.length > 0 && (React.createElement("div", { className: "category_dropdown" },
        React.createElement("button", { onClick: () => toggleCategoryDropdown(type) },
            React.createElement("span", null, value),
            React.createElement("span", { className: showDropdown ? 'category_dropdown--icon-up' : 'category_dropdown--icon-down' },
                React.createElement(Icon, { name: ARROW_DOWN, stroke: Variables.opacityWhiteLight }))),
        React.createElement(SlideDown, { className: "slidedown", transitionOnAppear: true, closed: !showDropdown },
            React.createElement("div", { className: "select-search-box__container" }, showDropdown && (React.createElement(SelectSearch, { autofocus: true, handleClickOutside: () => { }, onChange: selectedCategory, options: categories, value: value, search: false })))))));
}
CategoryDropdown.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.any).isRequired,
    toggleCategoryDropdown: PropTypes.func.isRequired,
    selectedCategory: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};
