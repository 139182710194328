import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { togglePopup } from '../actions/popupActions';
import Icon from '../../../icons';
import Variables from '../../../styles/variables.scss';
import { CROSS_LARGE } from '../../../constants/iconNames';
const Popup = ({ button, feedback, firstLine, closePopup, secondLine, togglePopupFunc, optionalFeedbackClassName, }) => (React.createElement("div", { className: "popup__container" },
    React.createElement("div", { className: "popup" },
        React.createElement("button", { className: "popup__icon", onClick: closePopup ? () => closePopup() : () => togglePopupFunc(true) },
            React.createElement(Icon, { fill: Variables.buttonRed, name: CROSS_LARGE })),
        React.createElement("span", { className: "popup__text" },
            React.createElement("span", null, firstLine),
            React.createElement("span", { className: optionalFeedbackClassName }, secondLine)),
        React.createElement("span", { className: "popup__feedback" }, feedback),
        button)));
Popup.propTypes = {
    button: PropTypes.element.isRequired,
    feedback: PropTypes.element,
    firstLine: PropTypes.string.isRequired,
    closePopup: PropTypes.func,
    secondLine: PropTypes.string,
    togglePopupFunc: PropTypes.func.isRequired,
    optionalFeedbackClassName: PropTypes.string,
};
Popup.defaultProps = {
    feedback: null,
    closePopup: null,
    secondLine: '',
    optionalFeedbackClassName: 'popup__container__second-line',
};
const mapDispatchToProps = dispatch => ({
    togglePopupFunc: close => dispatch(togglePopup({ close })),
});
export default connect(null, mapDispatchToProps)(Popup);
