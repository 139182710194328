// Dropdown
export const EXPANDING = 'EXPANDING';
export const EXPANDED = 'EXPANDED';
// End day
export const END_DAY = 'END_DAY';
// Installation serviceTypeCodes
export const INSTALLATION_SERVICE_TYPE_CODES = ['I', '0', 'F', '9', 'A', 'P'];
// Product list
export const PRODUCT_LIST_FAILURE = 'PRODUCT_LIST_FAILURE';
export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
// Work order list
export const EMPTY_WORKORDER_LIST = 'EMPTY_WORKORDER_LIST';
export const WORKORDER_LIST_FAILURE = 'WORKORDER_LIST_FAILURE';
export const WORKORDER_LIST_REQUEST = 'WORKORDER_LIST_REQUEST';
export const WORKORDER_LIST_SUCCESS = 'WORKORDER_LIST_SUCCESS';
export const WORKORDER_STATUS_UPDATE = 'WORKORDER_STATUS_UPDATE';
export const WORKORDER_LIST_CANCEL = 'WORKORDER_LIST_CANCEL';
// Previous work orders
export const PREVIOUS_WORKORDER_LIST_FAILURE = 'PREVIOUS_WORKORDER_LIST_FAILURE';
export const PREVIOUS_WORKORDER_LIST_REQUEST = 'PREVIOUS_WORKORDER_LIST_REQUEST';
export const PREVIOUS_WORKORDER_LIST_SUCCESS = 'PREVIOUS_WORKORDER_LIST_SUCCESS';
