import { call, put, select, takeEvery } from 'redux-saga/effects';
import { CHANGE_PRODUCT } from '../../../constants/endpoints';
import { CHANGE_PRODUCT_REQUEST } from '../customerProductsConstants';
import { putAPI } from '../../../api/apiConfig';
import * as actionsChangeProduct from '../actions/changeProductActions';
import * as actionsCustomerProducts from '../actions/customerProducts';
function* callChangeProduct({ productId, dpadId, serialNumber, reload }) {
    try {
        const customerId = yield select(state => state.workOrder.workOrder.customer.customerId);
        const customerProducts = yield select(state => state.customerProducts.customerProducts);
        const oldSerial = customerProducts
            .find(product => product.productId === productId)
            .devices.find(device => device.dpadId === parseInt(dpadId, 10)).serialNumber;
        const changeUrl = CHANGE_PRODUCT(dpadId, serialNumber);
        const changeResult = yield call(putAPI, changeUrl, '');
        if (reload) {
            yield put(actionsCustomerProducts.requestCustomerProducts({ customerId }));
        }
        else {
            try {
                customerProducts
                    .find(product => product.productId === productId)
                    .devices.find(device => device.dpadId === parseInt(dpadId, 10)).serialNumber =
                    serialNumber;
                yield put(actionsCustomerProducts.receiveCustomerProducts({ customerProducts }));
            }
            catch (error) {
                yield put(actionsCustomerProducts.errorCustomerProducts({
                    errorMessage: `Error while trying to receive customer products. ${error.message}`,
                }));
            }
        }
        yield put(actionsChangeProduct.receiveChangeProduct({
            changedProduct: serialNumber,
            productName: changeResult,
            oldSerial,
        }));
    }
    catch (error) {
        yield put(actionsChangeProduct.errorChangeProduct({
            errorMessage: error.data,
            errorProducts: [
                {
                    errorMessage: error.data,
                    dpadId,
                },
            ],
        }));
    }
}
export default [takeEvery(CHANGE_PRODUCT_REQUEST, callChangeProduct)];
