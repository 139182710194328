import { LINK_PRODUCT_REQUEST, LINK_PRODUCT_SUCCESS, LINK_PRODUCT_FAILURE, LINK_SINGLE_PRODUCT_REQUEST, } from '../customerProductsConstants';
export const linkProductRequest = (customerId, productId, dpadId) => ({
    type: LINK_PRODUCT_REQUEST,
    customerId,
    productId,
    dpadId,
});
export const linkProductSuccess = () => ({
    type: LINK_PRODUCT_SUCCESS,
});
export const linkProductFailure = error => ({
    type: LINK_PRODUCT_FAILURE,
    error,
});
export const linkSingleDpadRequest = (customerId, productId, dpadId) => ({
    type: LINK_SINGLE_PRODUCT_REQUEST,
    customerId,
    productId,
    dpadId,
});
