import { connect } from 'react-redux';
import SignInForm from '../components/SignInForm';
import { requestSignIn } from '../actions';
import '../signIn.scss';
const mapStateToProps = state => ({
    signInError: state.authentication.signInError,
});
const mapDispatchToProps = dispatch => ({
    requestSignIn: credentials => dispatch(requestSignIn(credentials)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);
