import { FIND_SERVICES_REQUEST, FIND_SERVICES_SUCCESS, FIND_SERVICES_ERROR, } from '../workLogConstants';
export const findServicesRequest = ({ workOrderId }) => ({
    type: FIND_SERVICES_REQUEST,
    workOrderId,
});
export const findServicesSuccess = ({ services }) => ({
    type: FIND_SERVICES_SUCCESS,
    services,
});
export const findServicesError = ({ errorMessage }) => ({
    type: FIND_SERVICES_ERROR,
    errorMessage,
});
