import React from 'react';
import PropTypes from 'prop-types';
import './dataFieldRow.scss';
function DatafieldRow({ title, value, symbol }) {
    return (React.createElement("div", { className: "datafield-row" },
        React.createElement("span", { className: "title" }, title),
        React.createElement("span", { className: "value" },
            value,
            " ",
            symbol)));
}
DatafieldRow.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    symbol: PropTypes.string,
};
DatafieldRow.defaultProps = {
    symbol: null,
};
export default DatafieldRow;
