import React from "react";
import Variables from "../styles/variables.scss";
const SVG = () => (React.createElement("svg", { width: "13px", height: "17px", viewBox: "0 0 13 17", version: "1.1" },
    React.createElement("title", null, "region_bl\u00E5@2x"),
    React.createElement("desc", null, "Created with Sketch."),
    React.createElement("defs", null),
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "icon/region_bl\u00E5", transform: "translate(1.000000, 1.000000)", fill: Variables.iconBlue, stroke: Variables.iconBlue },
            React.createElement("g", { id: "region_bl\u00E5" },
                React.createElement("path", { d: "M0.207084886,5.69726019 C0.206959413,5.68182415 0.206896552,5.66637278 0.206896552,5.65090633 C0.206896552,2.64735745 2.57754883,0.2125 5.50189663,0.2125 C8.42624442,0.2125 10.7968967,2.64735745 10.7968967,5.65090633 C10.7968967,5.65555195 10.796891,5.66019621 10.7968797,5.66483911 C10.796891,5.66539587 10.7968967,5.66595288 10.7968967,5.66651013 C10.7968967,5.70192471 10.7964502,5.73779996 10.7955645,5.77412068 C10.7884888,6.10103097 10.7533271,6.42082585 10.6924598,6.73106037 C10.1068409,10.0578135 7.19676211,15.3623462 5.50189663,15.3623462 C3.61082517,15.3623462 0.206896557,8.75856703 0.206896557,5.70535585 C0.206896557,5.70264886 0.206959436,5.69995031 0.207084886,5.69726019 Z M5.50189663,7.98165189 C6.75518854,7.98165189 7.77118237,6.93814156 7.77118237,5.65090633 C7.77118237,4.36367109 6.75518854,3.32016076 5.50189663,3.32016076 C4.24860471,3.32016076 3.23261088,4.36367109 3.23261088,5.65090633 C3.23261088,6.93814156 4.24860471,7.98165189 5.50189663,7.98165189 Z", id: "Oval-82" }))))));
export default SVG;
