import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import usePrevious from '../../utils/customHooks/usePrevious';
function ScrollToTop({ location, children }) {
    const [newLocation, setNewLocation] = useState(location);
    const prevLocation = usePrevious(newLocation);
    useEffect(() => {
        if (prevLocation && location.pathname !== prevLocation.pathname) {
            setNewLocation(location);
            window.scrollTo(0, 0);
        }
    });
    return children;
}
ScrollToTop.propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    children: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default withRouter(ScrollToTop);
