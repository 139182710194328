import React from 'react';
import timeInHoursAndMinutes from '../../../../utils/timeInHoursAndMinutes';
import '../../renderService.scss';
/**
 * Helper method to highlight matched words in a search with bold. Used by RenderService which is supplied to react-select-search as "option"
 * Taken from the basic usage in markSearch.js
 */
function checkMatch(service, input) {
    const regex = input && new RegExp(input.search.trim(), 'i');
    const segments = service.name.split(regex);
    const match = service.name.match(regex);
    if (input && input.search) {
        if (match) {
            return (React.createElement("div", { className: "service_name" },
                segments[0],
                React.createElement("span", { className: "searched" }, match),
                segments[1]));
        }
        return React.createElement("div", { className: "service_name" }, service.name);
    }
    return React.createElement("div", { className: "service_name" }, service.name);
}
const RenderService = (service, input) => {
    const { hours, minutes } = timeInHoursAndMinutes(service.timeUnitPerService);
    const serviceName = checkMatch(service, input);
    return (React.createElement("div", { className: "service_outer" },
        serviceName,
        React.createElement("div", { className: "service_time" }, `${hours}t ${minutes}m`),
        React.createElement("div", { className: "service_price" }, `${service.amount}-,`)));
};
export default RenderService;
