import { call, put, takeLatest } from 'redux-saga/effects';
import { REMOVE_SERVICE_REQUEST } from '../../workLogConstants';
import * as removeServiceActions from '../../actions/removeServiceActions';
import { REMOVE_SERVICE } from '../../../../constants/endpoints';
import { deleteAPI } from '../../../../api/apiConfig';
import { workorderServicesSuccess } from '../../actions/workOrderServiceActions';
export function* callRemoveService({ workOrderId, workOrderServiceId }) {
    try {
        const url = REMOVE_SERVICE(workOrderId, workOrderServiceId);
        const newServiceCodeList = yield call(deleteAPI, url);
        yield put(workorderServicesSuccess({ services: newServiceCodeList }));
    }
    catch (error) {
        yield put(removeServiceActions.errorRemoveService({
            errorMessage: `Could not remove service. ${error.message}`,
        }));
    }
}
export default [takeLatest(REMOVE_SERVICE_REQUEST, callRemoveService)];
