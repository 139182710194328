import React from 'react';
import capitalize from './capitalizeString';
import getFormattedDate from './getFormattedDate';
export default function statusTimeDiv(status, time, bold, date, isPastDue) {
    const formattedDate = getFormattedDate(date);
    const statusTime = (React.createElement("div", { className: "status_time" },
        React.createElement("span", { className: `${bold ? 'status_time--bold-status' : ''} ${isPastDue ? 'warning' : ''}` },
            React.createElement("span", { className: "date" }, formattedDate),
            React.createElement("span", { className: "status" }, capitalize(status))),
        React.createElement("span", null, time)));
    return statusTime;
}
