import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/devicePollingMacActions';
import { DEVICE_POLLING_MAC_REQUEST } from '../constants';
import { getAPI } from '../../../api/apiConfig';
import { DEVICE_POLL_URL } from '../../../constants/endpoints';
export function* callDevicePollingMac({ pollUrl }) {
    try {
        const result = yield call(getAPI, DEVICE_POLL_URL(pollUrl));
        yield put(actions.receiveDevicePollingMac({ devicePollingMac: result }));
    }
    catch (error) {
        yield put(actions.errorDevicePollingMac({
            errorMessage: `Could not get device polling. ${error.message}`,
        }));
    }
}
export default [takeLatest(DEVICE_POLLING_MAC_REQUEST, callDevicePollingMac)];
