import React from 'react';
import PropTypes from 'prop-types';
import './checkbox.scss';
const Checkbox = ({ checked, isFetching, onClick, label, name }) => (React.createElement("label", { htmlFor: name, className: "checkbox" },
    React.createElement("span", { className: checked ? 'label--checked' : 'label' }, label),
    React.createElement("input", { id: name, checked: checked, value: checked, type: "checkbox", name: name, onClick: onClick, readOnly: true }),
    React.createElement("span", { className: "checkmark" }, isFetching && (React.createElement("span", { className: `${checked ? 'circle-loader-green' : 'circle-loader-white'}` })))));
Checkbox.propTypes = {
    checked: PropTypes.bool,
    isFetching: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};
Checkbox.defaultProps = {
    checked: false,
    isFetching: false,
};
export default Checkbox;
