import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
const SVG = ({ fill }) => (React.createElement("svg", { width: "15px", height: "15px", viewBox: "0 0 15 15", version: "1.1" },
    React.createElement("title", null, "icon/Rediger gul Copy 2"),
    React.createElement("desc", null, "Created with Sketch."),
    React.createElement("defs", null),
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: fill, fillRule: "evenodd" },
        React.createElement("g", { id: "icon/Rediger-gul-Copy-2", fill: fill },
            React.createElement("path", { d: "M11.0228459,0.91456354 C11.4140484,0.523361012 12.0474458,0.522493791 12.4352765,0.910324489 L14.5909031,3.06595107 C14.9797645,3.45481243 14.9814441,4.08360159 14.586664,4.47838169 L5.87095693,13.1940888 L2.30713878,9.63027066 L11.0228459,0.91456354 Z M0.499567466,15 L1.98462878,9.95112054 L5.54844693,13.5149387 L0.499567466,15 Z", id: "Rectangle-178-Copy" })))));
SVG.propTypes = {
    fill: PropTypes.string,
};
SVG.defaultProps = {
    fill: Variables.blackPrimary,
};
export default SVG;
