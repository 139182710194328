import { SIGN_IN_FAILURE, SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_OUT_REQUEST, VERIFY_TOKEN_FAILURE, VERIFY_TOKEN_REQUEST, VERIFY_TOKEN_SUCCESS, EXTERNAL_INSTALLER, DEV_SIGN_IN_REQUEST, SIGN_IN_SUCCESS_WITH_PIAM, } from '../constants';
import { TOKEN, USERNAME, OLDLOGIN } from '../../../constants/localStorage';
import { setToken } from '../../../api/apiConfig';
const signInReducerDefaultState = {
    isLoggedInWithOldAuth: localStorage.getItem(OLDLOGIN) ? true : false,
    isAuthenticated: false,
    isFetching: false,
    role: EXTERNAL_INSTALLER,
    signInError: false,
};
export default (state = signInReducerDefaultState, action) => {
    const { credentials, role, token, type, username } = action;
    switch (type) {
        case SIGN_IN_REQUEST:
            return Object.assign(Object.assign({}, state), { isFetching: true, credentials });
        case DEV_SIGN_IN_REQUEST:
            return Object.assign(Object.assign({}, state), { isFetching: true, credentials });
        case SIGN_IN_SUCCESS_WITH_PIAM:
            localStorage.setItem(USERNAME, username);
            localStorage.setItem(TOKEN, token);
            localStorage.removeItem(OLDLOGIN);
            return {
                role,
                isFetching: false,
                isLoggedInWithOldAuth: false,
                isAuthenticated: true,
                signInError: false,
            };
        case SIGN_IN_SUCCESS:
            localStorage.setItem(TOKEN, token);
            localStorage.setItem(OLDLOGIN, 'true');
            return {
                isFetching: false,
                isLoggedInWithOldAuth: true,
                isAuthenticated: true,
                signInError: false,
            };
        case SIGN_IN_FAILURE:
            return {
                isFetching: false,
                isLoggedInWithOldAuth: false,
                isAuthenticated: false,
                signInError: true,
            };
        case VERIFY_TOKEN_REQUEST:
            return {
                isFetching: true,
                isAuthenticated: false,
            };
        case VERIFY_TOKEN_SUCCESS:
            return {
                token,
                role,
                isAuthenticated: true,
                isLoggedInWithOldAuth: localStorage.getItem(OLDLOGIN) ? true : false,
                isFetching: false,
                signInError: false,
            };
        case VERIFY_TOKEN_FAILURE:
            localStorage.removeItem(TOKEN);
            return {
                isAuthenticated: false,
                isLoggedInWithOldAuth: localStorage.getItem(OLDLOGIN) ? true : false,
                isFetching: false,
                signInError: false,
            };
        case SIGN_OUT_REQUEST:
            localStorage.removeItem(TOKEN);
            return {
                isFetching: false,
                isLoggedInWithOldAuth: localStorage.getItem(OLDLOGIN) ? true : false,
                isAuthenticated: false,
                signInError: false,
            };
        default:
            return state;
    }
};
