import styled from 'styled-components';
export const PebbleSpinnerWrapper = styled.div `
  /* To get path length: document.querySelector('path').getTotalLength() */
  --telia-loading-pebble-length: 196.47589111328125;

  --telia-loading-pebble-pattern: calc(var(--telia-loading-pebble-length) * 3 / 4)
    calc(var(--telia-loading-pebble-length) * 1 / 4);

  --telia-loading-pebble-first-position: 0;
  --telia-loading-pebble-last-position: var(--telia-loading-pebble-length);

  @keyframes spinner {
    /* Trick to a smooth animation is that the first and last positions must look identical */
    0% {
      stroke-dashoffset: var(--telia-loading-pebble-first-position);
    }
    100% {
      stroke-dashoffset: var(--telia-loading-pebble-last-position);
    }
  }

  align-items: center;
  display: flex;
  flex-direction: column;

  .LoadingPebble-fg {
    animation-delay: 0;
    animation-direction: normal;
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: spinner;
    animation-play-state: running;
    animation-timing-function: linear;
    fill: none;
    stroke-dasharray: 147.35692 49.11897;
    stroke-linecap: butt;
    stroke-width: 3px;
    stroke: #c158f8;
  }

  .LoadingPebble-bg {
    fill: none;
    opacity: 0.04;
    stroke-linecap: butt;
    stroke-width: 3px;
    stroke: #c158f8;
  }
`;
