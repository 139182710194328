import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import FetchingSpinner from '../utils/FetchingSpinner';
import { QA } from '../constants/paths';
import { requestTokenVerification } from '../modules/Authentication/actions/verifyTokenActions';
import Routes from './routes';
import SignIn from '../modules/Authentication/containers/SignInContainer';
import { TOKEN, URL, USERNAME } from '../constants/localStorage';
import '../modules/WorkorderList/components/workOrderListBottom/workOrderListBottom.scss';
import './offline.scss';
import Header from './Header/Header';
import SlideInPagePortal from '../components/SlideInPagePortal/SlideInPagePortal';
import ScrollToTop from '../components/ScrollTopTop/ScrollToTop';
import { ErrorPage } from '../modules/ErrorPage';
import AuthenticationDev from '../modules/authentication-dev/authentication-dev-container';
import { useAuth } from 'react-oidc-context';
import { requestLogout, signInSuccessWithPIAM } from 'modules/Authentication/actions';
const PROD_ENV = 'getservice.get.no';
const PROD_ENV_AWS = 'homeservice.telia.no';
const hostname = window.location.hostname;
const StyledButton = styled.button `
  position: fixed;
  top: 12px;
  left: 16px;
  z-index: 10;
`;
let oldToken = '';
const app = ({ location, isAuthenticated, isAuthenticatedFetching, requestTokenVerificationFunc }) => {
    const [devLogin, setDevLogin] = useState(false);
    const [showRedirectionModal, setShowRedirectionModal] = useState(true);
    const auth = useAuth();
    const dispatch = useDispatch();
    const isLoggedInWithOldAuth = useSelector((state) => state.authentication.isLoggedInWithOldAuth);
    const setUserState = () => {
        var _a, _b, _c;
        if (auth.isLoading)
            return;
        if (isLoggedInWithOldAuth)
            return; // if we have old session then ignore this all
        if (auth.user !== null && auth.user !== undefined) {
            dispatch(signInSuccessWithPIAM({
                role: 'ROLE_TECHNICIAN',
                token: (_a = auth.user) === null || _a === void 0 ? void 0 : _a.access_token,
                username: (_c = (_b = auth.user) === null || _b === void 0 ? void 0 : _b.profile) === null || _c === void 0 ? void 0 : _c.sub
            }));
        }
        else {
            localStorage.removeItem(USERNAME);
            localStorage.removeItem(TOKEN);
            dispatch(requestLogout());
        }
    };
    React.useEffect(() => {
        return auth.events.addUserLoaded(() => {
            setUserState();
        });
    }, [auth.events, auth.signinSilent]);
    React.useEffect(() => {
        setUserState();
    }, [auth.user]);
    const handleClickButton = () => {
        setDevLogin(!devLogin);
    };
    const handleCloseModal = () => {
        setShowRedirectionModal(!showRedirectionModal);
    };
    const token = localStorage.getItem(TOKEN);
    useEffect(() => {
        if (token) {
            requestTokenVerificationFunc({ token: token });
        }
    }, [requestTokenVerificationFunc, token]);
    if (window.location.host.includes(QA)) {
        window.onload = () => {
            document.body.id = 'qa';
        };
    }
    else {
        window.onload = () => {
            document.body.id = 'notQa';
        };
    }
    localStorage.setItem(URL, location.pathname + location.hash);
    const showDevLoginButton = () => !isAuthenticated && hostname !== PROD_ENV && hostname !== PROD_ENV_AWS;
    const showDevLoginForm = () => !isAuthenticated &&
        !isAuthenticatedFetching &&
        devLogin &&
        hostname !== PROD_ENV &&
        hostname !== PROD_ENV_AWS;
    return (React.createElement(Sentry.ErrorBoundary, { fallback: ErrorPage },
        React.createElement("div", { className: "app__main" },
            localStorage.getItem(URL) && localStorage.getItem(URL) !== location.pathname,
            React.createElement(Header, null),
            showDevLoginButton() && (React.createElement(StyledButton, { onClick: () => handleClickButton() }, devLogin ? 'Regular Login' : 'Dev Login')),
            React.createElement("div", { className: "main__main-container" },
                showDevLoginForm() && React.createElement(AuthenticationDev, null),
                !isAuthenticated && !isAuthenticatedFetching && !devLogin && React.createElement(SignIn, null),
                isAuthenticatedFetching && React.createElement(FetchingSpinner, null),
                isAuthenticated && !isAuthenticatedFetching && (React.createElement(ScrollToTop, null,
                    React.createElement(Routes, null))),
                React.createElement(SlideInPagePortal, { hash: location.hash })))));
};
app.defaultProps = {
    close: true,
    popupType: '',
};
const mapStateToProps = (state) => ({
    close: state.popup.close,
    isAuthenticated: state.authentication.isAuthenticated,
    isAuthenticatedFetching: state.authentication.isFetching,
    popupType: state.popup.popupType,
    isFetchingWorkOrder: state.workOrder.isFetching,
});
const mapDispatchToProps = (dispatch) => ({
    requestTokenVerificationFunc: (token) => dispatch(requestTokenVerification(token)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(app));
