import React, { useCallback, useState } from 'react';
import NOB from '../../../constants/language-strings';
import { useAuth } from 'react-oidc-context';
import { OLDLOGIN } from 'constants/localStorage';
const SignInForm = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const handleSubmit = () => {
        props.requestSignIn({ credentials: { username, password } });
    };
    const auth = useAuth();
    const onSsoLoginClicked = useCallback(async () => {
        localStorage.removeItem(OLDLOGIN);
        await auth.signinRedirect();
    }, [auth]);
    const { signInError } = props;
    return (React.createElement("div", { className: "login__container" },
        React.createElement("form", { onSubmit: (e) => { handleSubmit(); e.preventDefault(); } },
            React.createElement("h1", null, NOB.SIGN_IN.HEADER),
            React.createElement("div", { className: "login__container__input" },
                React.createElement("label", { htmlFor: "username", className: "login__container__input--labels" }, NOB.SIGN_IN.INPUT_USERNAME),
                React.createElement("input", { name: "username", type: "text", autoComplete: "on", value: username, onChange: (e) => { setUsername(e.target.value); }, className: signInError ? 'login__container__input--error' : '' })),
            React.createElement("div", { className: "login__container__input" },
                React.createElement("label", { htmlFor: "password", className: "login__container__input--labels" }, NOB.SIGN_IN.INPUT_PASSWORD),
                React.createElement("input", { name: "password", type: "password", autoComplete: "on", value: password, onChange: (e) => { setPassword(e.target.value); }, className: signInError ? 'login__container__input--error' : '' }),
                signInError),
            React.createElement("div", { className: "login__container__input" },
                React.createElement("button", { className: "login-sso-button", type: "button", onClick: () => onSsoLoginClicked() }, "_")),
            signInError && (React.createElement("div", { className: "login__container__error-message" }, NOB.SIGN_IN.ERROR_TEXT)),
            React.createElement("button", { className: "button button--primary", type: "submit" }, NOB.SIGN_IN.SIGN_IN_BUTTON))));
};
export default SignInForm;
