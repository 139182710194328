import { MAP_ID_REQUEST, MAP_ID_SUCCESS, MAP_ID_FAILURE } from '../constants';
const mapIdDefaultState = {
    mapId: '',
    isFetching: false,
    errorMessage: '',
};
export default (state = mapIdDefaultState, action) => {
    switch (action.type) {
        case MAP_ID_REQUEST:
            return Object.assign(Object.assign({}, state), { isFetching: true });
        case MAP_ID_SUCCESS:
            return {
                mapId: action.mapId,
                isFetching: false,
            };
        case MAP_ID_FAILURE:
            return Object.assign(Object.assign({}, state), { isFetching: false, errorMessage: action.errorMessage });
        default:
            return state;
    }
};
