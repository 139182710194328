import NOB from './constants/language-strings';
export default (state = NOB, action) => {
    switch (action.type) {
        case 'NOB':
            return {
                NOB: action.NOB,
            };
        default:
            return state;
    }
};
