var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import { AGREEMENT_ID } from '../../../../../constants/constant-strings';
import { AMOUNT_MONEY } from '../../../../../constants/characters';
import { grayButton } from '../../../../../styles/variables.scss';
import NOB from '../../../../../constants/language-strings';
import priceFixed from '../../../../../utils/priceFixed';
import './agreementPrice.scss';
import { useQuery } from 'react-query';
import { http } from '../../../../../api/apiConfig';
import { CUSTOMER_AGREEMENT_PRODUCT_PRICES_URL } from '../../../../../constants/endpoints';
import { withRouter } from 'react-router';
function AgreementPrice(_a) {
    var { customerId, agreementId, originalPrice, currentPrice, isFetchingPrices } = _a, props = __rest(_a, ["customerId", "agreementId", "originalPrice", "currentPrice", "isFetchingPrices"]);
    const [showDetailedPriceModal, setShowDetailedPriceModal] = useState(false);
    // eslint-disable-next-line react/prop-types
    const showDetailedPricesForCustomerView = props.location.pathname.includes('customerInfo');
    const { data: agreementsWithPrices, refetch, isFetching, isLoading, } = useQuery({
        queryKey: ['agreementsWithPrices', customerId],
        queryFn: async () => {
            const { data } = await http.get(CUSTOMER_AGREEMENT_PRODUCT_PRICES_URL(customerId));
            return data;
        },
        enabled: showDetailedPricesForCustomerView,
    });
    const { CUSTOMER_PRODUCTS } = NOB;
    return (React.createElement("div", { className: "customer__products__container__agreement__info" },
        React.createElement("span", { className: "customer__products__container__agreement-id" }, `${AGREEMENT_ID} ${agreementId}`),
        isFetchingPrices ? (React.createElement(ClipLoader, { size: 16, color: grayButton })) : (React.createElement("span", { className: "customer__products__container__price" },
            `${CUSTOMER_PRODUCTS.PRICE_PREFIX} ${priceFixed(currentPrice)}${AMOUNT_MONEY}`,
            currentPrice !== originalPrice && (React.createElement("span", { className: "customer__products__container__price--original" }, `${priceFixed(originalPrice)}${AMOUNT_MONEY}`)),
            showDetailedPricesForCustomerView && (React.createElement("button", { className: "details", onClick: () => {
                    void refetch();
                    setShowDetailedPriceModal(state => !state);
                } }, "Detaljer")))),
        showDetailedPriceModal && showDetailedPricesForCustomerView && (React.createElement("div", { className: "modal" },
            React.createElement("div", { className: "modalHeader" },
                React.createElement("div", { className: "fetchInfo" }, !isLoading && isFetching ? React.createElement(React.Fragment, null, "Refetching prices...") : React.createElement(React.Fragment, null)),
                React.createElement("button", { className: "closeButton", onClick: () => setShowDetailedPriceModal(state => !state) }, "Close")),
            agreementsWithPrices ? (agreementsWithPrices.agreements.map(item => {
                if (item.agreementId !== agreementId) {
                    return React.createElement(React.Fragment, null);
                }
                else {
                    return (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "singleProduct tableHeading" },
                            React.createElement("div", { className: "productName" }, "Name"),
                            React.createElement("div", { className: "productPrice" }, "Monthly Price"),
                            React.createElement("div", { className: "productPrice" }, "Installation Price")),
                        !!item.products.length ? (item.products.map(product => (React.createElement("div", { className: "singleProduct", key: product.name },
                            React.createElement("div", { className: "productName" }, product.name),
                            React.createElement("div", { className: "productPrice" }, product.monthlyPrice),
                            React.createElement("div", { className: "productPrice" }, product.installationPrice))))) : (React.createElement(React.Fragment, null, "No products found"))));
                }
            })) : (React.createElement("div", { className: "fetchInfo" }, "Loading..."))))));
}
AgreementPrice.propTypes = {
    agreementId: PropTypes.number.isRequired,
    customerId: PropTypes.number.isRequired,
    originalPrice: PropTypes.number.isRequired,
    currentPrice: PropTypes.number.isRequired,
    isFetchingPrices: PropTypes.bool.isRequired,
};
export default withRouter(AgreementPrice);
