import { put, takeLatest } from 'redux-saga/effects';
import * as actions from '../../actions/workOrder';
import { STATUS_CHANGE_CHECK_SKIP } from '../../workOrderConstants';
import { statusToSkip } from '../../../../constants/status-codes';
import { updateSingleWorkOrderStatus } from '../../../WorkorderList/actions/workOrderList';
function* callStatusChangeSkip({ workOrderId, currentStatus }) {
    if (statusToSkip.includes(currentStatus)) {
        yield put(actions.requestStatusChange(currentStatus));
    }
    else {
        yield put(actions.receiveStatusChange(currentStatus));
        yield put(updateSingleWorkOrderStatus(currentStatus, workOrderId));
    }
}
export default [takeLatest(STATUS_CHANGE_CHECK_SKIP, callStatusChangeSkip)];
