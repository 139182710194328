import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
const SVG = ({ fill, height, width }) => (React.createElement("svg", { width: width, height: height, viewBox: "0 0 48 48", version: "1.1" },
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "icon/kryss", fill: fill },
            React.createElement("path", { d: "M24,22.8882429 L32.6224085,14.2369969 C32.9281114,13.9302715 33.4345288,13.9199111 33.7535232,14.2138563 C34.0725176,14.5078015 34.0832924,14.9947412 33.7775895,15.3014666 L25.1080512,24 L33.7775895,32.6985334 C34.0832924,33.0052588 34.0725176,33.4921985 33.7535232,33.7861437 C33.4345288,34.0800889 32.9281114,34.0697285 32.6224085,33.7630031 L24,25.1117571 L15.3775915,33.7630031 C15.0718886,34.0697285 14.5654712,34.0800889 14.2464768,33.7861437 C13.9274824,33.4921985 13.9167076,33.0052588 14.2224105,32.6985334 L22.8919488,24 L14.2224105,15.3014666 C13.9167076,14.9947412 13.9274824,14.5078015 14.2464768,14.2138563 C14.5654712,13.9199111 15.0718886,13.9302715 15.3775915,14.2369969 L24,22.8882429 Z", id: "Combined-Shape-Copy" })))));
SVG.propTypes = {
    fill: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};
SVG.defaultProps = {
    fill: Variables.buttonRed,
    height: "48px",
    width: "48px",
};
export default SVG;
