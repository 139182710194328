import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './componentHeader.scss';
import IconButton from '../Buttons/IconButton/IconButton';
import { ARROW_LEFT, CROSS } from '../../constants/iconNames';
const ComponentHeader = ({ leftIcon, mainHeading, rightIcon, showLeftIcon, showRightIcon, subHeading, history, }) => (React.createElement("div", { className: "component-header" },
    showLeftIcon ? (React.createElement(IconButton, { icon: ARROW_LEFT, className: "component-header--left-icon", onClick: history.goBack })) : (React.createElement("span", { className: "component-header--left-icon" }, leftIcon)),
    React.createElement("div", { className: "component-header__text" },
        React.createElement("span", { className: subHeading ? 'component-header__text--main' : '' }, mainHeading),
        React.createElement("span", { className: "component-header__text--sub" }, subHeading)),
    showRightIcon ? (React.createElement(IconButton, { icon: CROSS, onClick: history.goBack })) : (React.createElement("span", { className: "component-header--right-icon" }, rightIcon))));
ComponentHeader.propTypes = {
    leftIcon: PropTypes.element,
    mainHeading: PropTypes.string.isRequired,
    rightIcon: PropTypes.element,
    showLeftIcon: PropTypes.bool,
    showRightIcon: PropTypes.bool,
    subHeading: PropTypes.string,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
};
ComponentHeader.defaultProps = {
    leftIcon: null,
    rightIcon: null,
    showLeftIcon: false,
    showRightIcon: false,
    subHeading: '',
};
const mapStateToProps = state => ({
    pathname: state.router.location.pathname,
});
export default withRouter(connect(mapStateToProps)(ComponentHeader));
