import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
function Overlay({ children }) {
    return ReactDOM.createPortal(React.createElement("div", { className: "overlay" }, children), document.getElementById('modal-root'));
}
Overlay.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
export default Overlay;
