import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../Buttons/IconButton/IconButton';
import { TRASH } from '../../constants/iconNames';
import Variables from '../../styles/variables.scss';
function ProductItemContentInactive({ onActivate, name, showDeleteButton, removeProduct, leftText, }) {
    return (React.createElement("div", { className: "product_item--inactive" },
        React.createElement("button", { className: "product_item--inactive-left", onClick: onActivate },
            React.createElement("span", null, name),
            React.createElement("span", { className: "product_item--inactive-status" }, leftText)),
        React.createElement("div", { className: "product--right" }, showDeleteButton && (React.createElement(IconButton, { icon: TRASH, onClick: removeProduct, fill: Variables.blackPrimary })))));
}
ProductItemContentInactive.propTypes = {
    onActivate: PropTypes.func,
    name: PropTypes.string.isRequired,
    showDeleteButton: PropTypes.bool,
    removeProduct: PropTypes.func,
    leftText: PropTypes.string.isRequired,
};
ProductItemContentInactive.defaultProps = {
    onActivate: null,
    showDeleteButton: false,
    removeProduct: null,
};
export default ProductItemContentInactive;
