import { LINK_PRODUCT_REQUEST, LINK_PRODUCT_SUCCESS, LINK_PRODUCT_FAILURE, LINK_SINGLE_PRODUCT_REQUEST, } from '../customerProductsConstants';
const linkProductReducerDefaultState = {
    errorMessage: '',
    isFetching: {
        isFetchingSingleDpad: false,
        dpadId: 0,
    },
};
export default (state = linkProductReducerDefaultState, action) => {
    const { error, type, dpadId, product } = action;
    switch (type) {
        case LINK_PRODUCT_REQUEST:
            return {
                errorMessage: linkProductReducerDefaultState.errorMessage,
                isFetching: {
                    isFetchingSingleDpad: true,
                    dpadId,
                },
            };
        case LINK_SINGLE_PRODUCT_REQUEST:
            return {
                errorMessage: linkProductReducerDefaultState.errorMessage,
                isFetching: {
                    isFetchingSingleDpad: true,
                    dpadId,
                },
            };
        case LINK_PRODUCT_SUCCESS:
            return Object.assign(Object.assign({}, state), { customerProducts: Object.assign(Object.assign({}, state.customerProducts), { customerProducts: Object.assign(Object.assign({}, state.customerProducts), { product }) }), isFetching: {
                    isFetchingSingleDpad: false,
                    dpadId: 0,
                } });
        case LINK_PRODUCT_FAILURE:
            return Object.assign(Object.assign({}, state), { errorMessage: error, isFetching: {
                    isFetchingSingleDpad: false,
                    dpadId: 0,
                } });
        default:
            return state;
    }
};
