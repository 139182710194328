import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NOB from '../../../constants/language-strings';
import NavigationButton from '../../../components/Buttons/NavigationButton/NavigationButton';
import { FOLLOW_UP_CUSTOMER_URL, NEW_WORK_ORDER_URL } from '../../../constants/paths';
import { CHECK } from '../../../constants/iconNames';
function WorkOrderCompletionNavigationButtons({ createdFollowUpForm: { created, workOrderId }, currentWorkOrderId, createFollowUpIsFetching, createWorkOrderIsFetching, createdWorkOrderdId, }) {
    return (React.createElement("div", { className: "work_log-buttons" },
        React.createElement(NavigationButton, { URL: FOLLOW_UP_CUSTOMER_URL(currentWorkOrderId), title: created && currentWorkOrderId === workOrderId
                ? NOB.WORK_LOG.FOLLOW_UP_CREATED
                : NOB.WORK_LOG.FOLLOW_UP, isFetching: createFollowUpIsFetching, disabled: created && currentWorkOrderId === workOrderId, rightIcon: created && currentWorkOrderId === workOrderId ? CHECK : null }),
        React.createElement(NavigationButton, { URL: NEW_WORK_ORDER_URL(currentWorkOrderId), title: createdWorkOrderdId === currentWorkOrderId
                ? NOB.WORK_LOG.WORK_ORDER_CREATED
                : NOB.WORK_LOG.CREATE_NEW_WORK_ORDER, rightIcon: createdWorkOrderdId === currentWorkOrderId ? CHECK : null, disabled: createdWorkOrderdId === currentWorkOrderId, isFetching: createWorkOrderIsFetching })));
}
WorkOrderCompletionNavigationButtons.propTypes = {
    currentWorkOrderId: PropTypes.number.isRequired,
    createdFollowUpForm: PropTypes.objectOf(PropTypes.any).isRequired,
    createFollowUpIsFetching: PropTypes.bool.isRequired,
    createWorkOrderIsFetching: PropTypes.bool.isRequired,
    createdWorkOrderdId: PropTypes.number,
};
WorkOrderCompletionNavigationButtons.defaultProps = {
    createdWorkOrderdId: 0,
};
const mapStateToProps = state => ({
    createFollowUpIsFetching: state.zendesk.followUpForm.isFetching,
    createdFollowUpForm: state.zendesk.followUpForm,
    createWorkOrderIsFetching: state.createWorkOrder.isFetching,
    currentWorkOrderId: state.workOrder.workOrder.workOrderId,
    role: state.authentication.role,
    createdWorkOrderdId: state.createWorkOrder.workOrderId,
});
export default connect(mapStateToProps)(WorkOrderCompletionNavigationButtons);
