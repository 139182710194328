import React from 'react';
import PropTypes from 'prop-types';
export default function Products({ productList }) {
    const products = Object.entries(productList).map(([key, value]) => (React.createElement("div", { className: "product__list__container--items--item", key: key },
        React.createElement("span", null, value),
        React.createElement("span", null, key))));
    return products;
}
Products.propTypes = {
    productList: PropTypes.objectOf(PropTypes.any).isRequired,
};
