import { FIND_SERVICES_REQUEST, FIND_SERVICES_SUCCESS, FIND_SERVICES_ERROR, } from '../workLogConstants';
const findServicesReducerDefaultState = {
    isFetchingServices: false,
    errorMessage: '',
    services: [],
};
export default (state = findServicesReducerDefaultState, action) => {
    const { errorMessage, services, type } = action;
    switch (type) {
        case FIND_SERVICES_REQUEST:
            return Object.assign(Object.assign({}, state), { isFetchingServices: true });
        case FIND_SERVICES_SUCCESS:
            return Object.assign(Object.assign({}, state), { isFetchingServices: false, services });
        case FIND_SERVICES_ERROR:
            return Object.assign(Object.assign({}, state), { isFetchingServices: false, errorMessage });
        default:
            return state;
    }
};
