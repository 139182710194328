import { INPUT_TYPES } from '../modules/FollowUpForms/FollowUpConstans';
const isEmpty = value => value === '' || value === undefined || !value;
export default (values, formInputs) => formInputs.inputs
    .filter(input => input.type === INPUT_TYPES.TEXT || input.type === INPUT_TYPES.RADIO)
    .map(input => {
    if (input.type === INPUT_TYPES.TEXT)
        return !isEmpty(values[input.headline]);
    return !isEmpty(values[input.title]);
})
    .every(value => value === true);
