import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import { DEVICE_POLLING_CUSTOMER } from '../../../constants/endpoints';
import { DEVICE_POLLING_CUSTOMER_REQUEST } from '../constants';
import { getAPI } from '../../../api/apiConfig';
export function* callDevicePollingCustomer({ customerId }) {
    try {
        const url = DEVICE_POLLING_CUSTOMER(customerId);
        const result = yield call(getAPI, url);
        yield put(actions.receiveDevicePollingCustomer({ devicePollingCustomer: result }));
    }
    catch (error) {
        yield put(actions.errorDevicePollingCustomer({
            errorMessage: `Could not get device polling for customer. ${error.message}`,
        }));
    }
}
export default [takeLatest(DEVICE_POLLING_CUSTOMER_REQUEST, callDevicePollingCustomer)];
