import React from "react";
import FeedbackHmm from "./FeedbackHmm";
import FeedbackJuggle from "./FeedbackJuggle";
import FeedbackNotFound from "./FeedbackNotFound";
import FeedbackPopcorn from "./FeedbackPopcorn";
import FeedbackThinking from "./FeedbackThinking";
import * as FEEDBACK from "../constants/feedbackNames";
const Feedback = props => {
    switch (props.name) {
        case FEEDBACK.FEEDBACK_JUGGLE:
            return React.createElement(FeedbackJuggle, Object.assign({}, props));
        case FEEDBACK.FEEDBACK_NOT_FOUND:
            return React.createElement(FeedbackNotFound, Object.assign({}, props));
        case FEEDBACK.FEEDBACK_POPCORN:
            return React.createElement(FeedbackPopcorn, Object.assign({}, props));
        case FEEDBACK.FEEDBACK_THINKING:
            return React.createElement(FeedbackThinking, Object.assign({}, props));
        default:
            return React.createElement(FeedbackHmm, Object.assign({}, props));
    }
};
export default Feedback;
