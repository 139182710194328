import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
const SVG = ({ stroke }) => (React.createElement("svg", { width: "20px", height: "18px", viewBox: "0 0 20 18", version: "1.1" },
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("g", { id: "Arrow/Arrow-left_gul", stroke: stroke, strokeWidth: "2" },
            React.createElement("g", { id: "Group", transform: "translate(10.000000, 9.000000) scale(-1, 1) translate(-10.000000, -9.000000) translate(1.000000, 1.000000)" },
                React.createElement("polyline", { id: "Path-2", transform: "translate(14.000000, 8.000000) scale(-1, 1) rotate(90.000000) translate(-14.000000, -8.000000) ", points: "6 4 14 12 22 4" }),
                React.createElement("path", { d: "M17,8 L0,8", id: "Line" }))))));
SVG.propTypes = {
    stroke: PropTypes.string,
};
SVG.defaultProps = {
    stroke: Variables.blackSecondary,
};
export default SVG;
