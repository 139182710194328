import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { hashes } from '../../constants/paths';
import CustomerProductsContainer from '../../modules/CustomerProducts/containers/CustomerProductsContainer';
import './slideInPagePortal.scss';
import HealthCheckContainer from '../../modules/HealthCheck/containers/HealthCheckContainer';
import AddressHistoryContainer from '../../modules/AddressHistory/containers/AddressHistoryContainer';
import FollowUpFormsContainer from '../../modules/FollowUpForms/FollowUpFormsContainer';
import UnknownHash from './UnknownHash';
import NotHome from '../../modules/Workorder/components/notHome/NotHome';
import WorkOrderHistoryContainer from '../../modules/WorkOrderHistory/containers/WorkOrderHistoryContainer';
/* children container needs to be added to array for slide in pages in routes to work properly */
function SlideInPagePortal({ hash }) {
    // keep selected hash i state so content does not disappear on slide out.
    const [selectedHash, setSelectedHash] = useState(hash);
    useEffect(() => {
        // keep previous hash so it does not disappear on slide out
        if (hash !== selectedHash && hash) {
            setSelectedHash(hash);
        }
    }, [hash]);
    // To display containers correctly wrap container in SlideInPage component
    const containers = [
        { hash: hashes.customerProducts, container: React.createElement(CustomerProductsContainer, null) },
        { hash: hashes.addressHistory, container: React.createElement(AddressHistoryContainer, null) },
        { hash: hashes.workOrderHistory, container: React.createElement(WorkOrderHistoryContainer, null) },
        { hash: hashes.healthCheck, container: React.createElement(HealthCheckContainer, null) },
        { hash: hashes.followUp, container: React.createElement(FollowUpFormsContainer, null) },
        { hash: hashes.notHome, container: React.createElement(NotHome, null) },
    ];
    const selectedContainer = containers.find(container => container.hash === selectedHash);
    return ReactDOM.createPortal(React.createElement(CSSTransition, { in: !!hash, timeout: 450, classNames: "slide", className: "slide_in_portal", mountOnEnter: true, unmountOnExit: true },
        React.createElement("div", null, selectedContainer ? selectedContainer.container : React.createElement(UnknownHash, null))), document.getElementById('slide-in-page'));
}
export default SlideInPagePortal;
