import { FOLLOW_UP_FORM_FAILURE, FOLLOW_UP_FORM_REQUEST, FOLLOW_UP_FORM_SUCCESS, } from '../FollowUpConstans';
export const followUpFormsRequest = () => ({
    type: FOLLOW_UP_FORM_REQUEST,
});
export const followUpFormsSuccess = forms => ({
    type: FOLLOW_UP_FORM_SUCCESS,
    forms,
});
export const followUpFormsError = ({ errorMessage }) => ({
    type: FOLLOW_UP_FORM_FAILURE,
    errorMessage,
});
