import React from 'react';
import PropTypes from 'prop-types';
import { BeatLoader } from 'react-spinners';
import './simple-button.scss';
import Color from '../Color';
import Icon from '../../../icons/index';
function SimpleButton({ title, onClick, color, isFetching, disabled, icon }) {
    const getColorClass = () => {
        switch (color) {
            case Color.YELLOW:
                return 'simple-button--yellow';
            case Color.BLACK:
                return 'simple-button--black';
            case Color.RED:
                return 'simple-button--red';
            default:
                return '';
        }
    };
    const buttoncContent = (React.createElement("span", { className: "simple-button__content" },
        icon ? React.createElement(Icon, { name: icon }) : null,
        title));
    return (React.createElement("button", { className: `simple-button ${getColorClass()}`, disabled: disabled, onClick: onClick }, isFetching ? React.createElement(BeatLoader, null) : buttoncContent));
}
SimpleButton.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    isFetching: PropTypes.bool,
    icon: PropTypes.string,
};
SimpleButton.defaultProps = {
    color: Color.YELLOW,
    disabled: false,
    isFetching: false,
    icon: '',
};
export default SimpleButton;
