import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-dynamic-swiper/lib/styles.css';
import { EXTERNAL_INSTALLER, TECHNICIAN } from '../../Authentication/constants';
import Feedback from '../../../feedbacks';
import FetchingSpinner from '../../../utils/FetchingSpinner';
import InfoBox from '../../../components/InfoBox/InfoBox';
import NOB from '../../../constants/language-strings';
import SlideContent from '../components/SlideContent';
import WorkOrderListBottom from '../components/workOrderListBottom/WorkOrderListBottom';
import * as productListAction from '../actions/productList';
import * as popupAction from '../../../components/Popup/actions/popupActions';
import * as workOrderAction from '../actions/workOrderList';
import * as zendeskAction from '../../WorkLog/actions/zendeskActions';
import './workOrderList.scss';
import WorkOrderListSlideContainer from './WorkOrderListSlideContainer';
class WorkOrderListContainer extends Component {
    componentDidMount() {
        const { requestProductList, requestWorkOrderList, saveComment, updateFollowUp } = this.props;
        saveComment('');
        updateFollowUp(false, false, false);
        requestWorkOrderList();
        requestProductList();
    }
    componentWillUnmount() {
        const { cancelWorkOrderListFetching } = this.props;
        cancelWorkOrderListFetching();
    }
    render() {
        const { endDay, errorMessage, isProductListFetching, isWorkOrderListFetching, role, togglePopup, workOrders, previousWorkOrders, } = this.props;
        const isTechnician = role === TECHNICIAN;
        const isExternalInstaller = role === EXTERNAL_INSTALLER;
        const productsLoading = isTechnician ? false : isProductListFetching;
        const isWorkOrderListEmpty = !workOrders.length;
        const unfinishedPastWorkOrders = previousWorkOrders.filter(item => item.status === 'SENT');
        if (isWorkOrderListFetching || productsLoading) {
            return (React.createElement("div", { className: "work_order_list--fetching" },
                React.createElement(FetchingSpinner, null)));
        }
        if (errorMessage !== '' && !workOrders.length) {
            return (React.createElement("div", { className: "workorder__list--no--workorder" },
                React.createElement(InfoBox, { feedback: React.createElement(Feedback, null), firstInfoBoxLine: NOB.FEEDBACKS.SORRY, link: false, secondInfoBoxLine: errorMessage }),
                React.createElement(WorkOrderListBottom, { endDayFunction: endDay, role: role, togglePopup: togglePopup })));
        }
        return (React.createElement("div", { className: "workOrder__list", style: { overflowX: 'hidden' } },
            !!unfinishedPastWorkOrders.length && (React.createElement("div", { className: "unfinished_work_orders_message" },
                React.createElement("b", null, "VIKTIG!"),
                " Du har",
                React.createElement("span", { className: "unfinished_work_orders_message__number" }, unfinishedPastWorkOrders.length),
                "WO'er som ikke er meldt inn. Vennligst meld inn disse s\u00E5 fort fort du kan! Jobber skal alltid meldes inn samme dag.")),
            isExternalInstaller && React.createElement(WorkOrderListSlideContainer, null),
            isTechnician && (React.createElement(SlideContent, { isTechnician: true, workOrdersNotDone: workOrders, isWorkOrderListEmpty: isWorkOrderListEmpty })),
            React.createElement(WorkOrderListBottom, { endDayFunction: endDay, role: role, togglePopup: togglePopup })));
    }
}
WorkOrderListContainer.propTypes = {
    endDay: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    isProductListFetching: PropTypes.bool.isRequired,
    isWorkOrderListFetching: PropTypes.bool.isRequired,
    requestProductList: PropTypes.func.isRequired,
    requestWorkOrderList: PropTypes.func.isRequired,
    cancelWorkOrderListFetching: PropTypes.func.isRequired,
    role: PropTypes.string,
    saveComment: PropTypes.func.isRequired,
    togglePopup: PropTypes.func.isRequired,
    updateFollowUp: PropTypes.func.isRequired,
    workOrders: PropTypes.arrayOf(PropTypes.any).isRequired,
    previousWorkOrders: PropTypes.arrayOf(PropTypes.any).isRequired,
};
WorkOrderListContainer.defaultProps = {
    errorMessage: '',
    role: EXTERNAL_INSTALLER,
};
const mapStateToProps = state => ({
    errorMessage: state.workOrders.errorMessage,
    isProductListFetching: state.productList.isFetching,
    isWorkOrderListFetching: state.workOrders.isFetching,
    productList: state.productList.productList,
    role: state.authentication.role,
    workOrders: state.workOrders.workOrders,
    numberOfPrevWorkOrders: state.previousWorkOrders.workOrders.length
        ? state.previousWorkOrders.latestPreviousWorkOrderRequest.totalElements
        : 0,
    previousWorkOrders: state.previousWorkOrders.workOrders,
});
const mapDispatchToProps = dispatch => ({
    endDay: endDay => dispatch(workOrderAction.endDayFunction({ endDay })),
    requestWorkOrderList: () => dispatch(workOrderAction.requestWorkOrderList()),
    cancelWorkOrderListFetching: () => dispatch(workOrderAction.workOrderListCancel()),
    requestProductList: () => dispatch(productListAction.requestProductList()),
    saveComment: commentBody => dispatch(zendeskAction.saveComment(commentBody)),
    togglePopup: (close, popupType) => dispatch(popupAction.togglePopup({ close, popupType })),
    updateFollowUp: (followUp, customerNotHome, allChecked) => dispatch(zendeskAction.setFollowUp({ followUp, customerNotHome, allChecked })),
});
export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderListContainer);
