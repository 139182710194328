import React from 'react';
import PropTypes from 'prop-types';
import { SlideToggle } from 'react-slide-toggle';
import DropdownButton from './DropdownButton';
import Products from './Products';
import * as Animations from '../../../../../constants/animation';
export default function ProductList({ productList }) {
    return (React.createElement(SlideToggle, { collapsed: true, duration: Animations.DURATION, easeCollapse: Animations.COLLAPSE, easeExpand: Animations.EXPAND, render: ({ onToggle, setCollapsibleElement, toggleState }) => (React.createElement("div", { className: "product__list" },
            React.createElement(DropdownButton, { onToggle: onToggle, toggleState: toggleState }),
            React.createElement("div", { className: "product__list__container", ref: setCollapsibleElement },
                React.createElement("div", { className: "product__list__container--items" },
                    React.createElement(Products, { productList: productList }))))) }));
}
ProductList.propTypes = {
    productList: PropTypes.objectOf(PropTypes.any).isRequired,
};
