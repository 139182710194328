import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/addProductActions';
import { AVAILABLE_PRODUCTS } from '../../../constants/endpoints';
import { AVAILABLE_PRODUCTS_REQUEST } from '../customerProductsConstants';
import { getAPI } from '../../../api/apiConfig';
export function* callGetProducts({ agreementId }) {
    try {
        const URL = AVAILABLE_PRODUCTS(agreementId);
        const result = yield call(getAPI, URL);
        const filteredProducts = result.map(product => ({
            value: product.id,
            name: product.name,
        }));
        yield put(actions.receiveAvailableProducts(filteredProducts));
    }
    catch (error) {
        yield put(actions.errorAvailableProducts(error.message));
    }
}
export default [takeLatest(AVAILABLE_PRODUCTS_REQUEST, callGetProducts)];
