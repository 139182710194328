import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import EsriLoader from '../components/EsriLoader';
import { requestMapId, requestMapToken } from '../actions/mapIdActions';
class MapContainer extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            shouldExpand: false,
        };
        this.handleExpand = () => {
            this.setState({ shouldExpand: !this.state.shouldExpand });
        };
    }
    componentDidMount() {
        const { id, newWorkOrderEntered, requestMapIdFunc, token, requestMapTokenFunc } = this.props;
        if (newWorkOrderEntered || !id) {
            requestMapIdFunc();
        }
        if (newWorkOrderEntered || !token) {
            requestMapTokenFunc();
        }
    }
    render() {
        const { customerAddress: { city, houseNumberAlpha, houseNumberNumeric, postalCode, street }, id, mapIsFetching, mapTokenIsFetching, technicalDetails, token, } = this.props;
        const address = `${street} ${houseNumberNumeric}${houseNumberAlpha}, ${postalCode} ${city}`;
        return (React.createElement("div", { className: "map__container" },
            React.createElement(EsriLoader, { address: address, handleExpand: this.handleExpand, id: id, isFetching: mapIsFetching || mapTokenIsFetching, mapTokenIsFetching: mapTokenIsFetching, position: {
                    latitude: parseFloat(technicalDetails.Latitude),
                    longitude: parseFloat(technicalDetails.Longitude),
                }, shouldExpand: this.state.shouldExpand, token: token })));
    }
}
MapContainer.propTypes = {
    customerAddress: PropTypes.objectOf(PropTypes.any).isRequired,
    id: PropTypes.string.isRequired,
    mapIsFetching: PropTypes.bool.isRequired,
    mapTokenIsFetching: PropTypes.bool.isRequired,
    newWorkOrderEntered: PropTypes.bool.isRequired,
    requestMapIdFunc: PropTypes.func.isRequired,
    requestMapTokenFunc: PropTypes.func.isRequired,
    technicalDetails: PropTypes.objectOf(PropTypes.any).isRequired,
    token: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
    customerAddress: state.workOrder.workOrder.address,
    id: state.map.mapId,
    mapIsFetching: state.map.isFetching,
    mapTokenIsFetching: state.mapToken.isFetching,
    technicalDetails: state.workOrder.workOrder.technicalDetails,
    token: state.mapToken.mapToken,
});
const mapDispatchToProps = dispatch => ({
    requestMapIdFunc: () => dispatch(requestMapId()),
    requestMapTokenFunc: () => dispatch(requestMapToken()),
});
export default connect(mapStateToProps, mapDispatchToProps)(MapContainer);
