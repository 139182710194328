import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Service from './service';
import serviceQuantityRules from '../../constants/service-rules';
import './serviceList.scss';
import * as removeServiceAction from '../WorkLog/actions/removeServiceActions';
import * as updateServiceAction from '../WorkLog/actions/updateServiceActions';
import { TECHNICIAN } from '../Authentication/constants';
import PriceRow from './PriceRow';
function ServiceListContainer({ requestRemoveService, requestUpdateService, workOrderServices, workOrderServiceIdToBeChanged, role, workOrderId, totalServicesPrice, languageStrings, }) {
    const technician = role === TECHNICIAN;
    const ServiceList = ({ services }) => services.map(service => (React.createElement(Service, { workOrderServiceIdToBeChanged: workOrderServiceIdToBeChanged, key: service.workOrderServiceId, service: service, max: serviceQuantityRules(service.name), requestRemoveService: requestRemoveService, requestUpdateService: requestUpdateService, technician: technician, workOrderId: workOrderId })));
    return (React.createElement("div", { className: "services" },
        React.createElement(ServiceList, { services: workOrderServices }),
        workOrderServices.length > 0 ? (React.createElement(PriceRow, { title: languageStrings.TOTAL, amount: totalServicesPrice })) : (React.createElement("span", { className: "services-empty-error" }, languageStrings.EMPTY_SERVICE_LIST))));
}
ServiceListContainer.propTypes = {
    workOrderServiceIdToBeChanged: PropTypes.number,
    requestRemoveService: PropTypes.func.isRequired,
    requestUpdateService: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
    workOrderId: PropTypes.number.isRequired,
    workOrderServices: PropTypes.arrayOf(PropTypes.any).isRequired,
    totalServicesPrice: PropTypes.number.isRequired,
    languageStrings: PropTypes.objectOf(PropTypes.string).isRequired,
};
ServiceListContainer.defaultProps = {
    workOrderServiceIdToBeChanged: null,
};
const mapStateToProps = state => ({
    workOrderServiceIdToBeChanged: state.workOrderServices.workOrderServiceIdToBeChanged,
    role: state.authentication.role,
    workOrderId: state.workOrder.workOrder.workOrderId,
    workOrderServices: state.workOrderServices.orderedItems.itemSummary,
    totalServicesPrice: state.workOrderServices.orderedItems.totalPrice,
    languageStrings: state.NOB.SERVICE_LIST,
});
const mapDispatchToProps = dispatch => ({
    requestRemoveService: (workOrderId, workOrderServiceId) => dispatch(removeServiceAction.requestRemoveService({ workOrderId, workOrderServiceId })),
    requestUpdateService: (workOrderId, workOrderServiceId, serviceId, quantity, added) => dispatch(updateServiceAction.requestUpdateService({
        workOrderId,
        workOrderServiceId,
        serviceId,
        quantity,
        added,
    })),
});
export default connect(mapStateToProps, mapDispatchToProps)(ServiceListContainer);
