export const getEnvironment = () => {
    const host = window.location.hostname;
    switch (host) {
        case 'localhost':
            return 'HomeService local';
        case 'getservice-tt.get.no':
            return 'HomeService TT';
        case 'getservice-qa.get.no':
            return 'HomeService QA';
        case 'getservice.get.no':
            return 'HomeService production';
        case 'homeservice-test.telia.io':
            return 'HomeService AWS TT';
        case 'homeservice.telia.no':
            return 'HomeService AWS production';
        default:
            return 'HomeService unknown';
    }
};
