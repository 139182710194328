import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
const SVG = ({ houseColor, width, height }) => (React.createElement("svg", { width: width, height: height, viewBox: "0 0 17 15", version: "1.1" },
    React.createElement("title", null, "icon/Hus_bl\u00E5"),
    React.createElement("desc", null, "Created with Sketch."),
    React.createElement("defs", null),
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd", strokeLinecap: "round" },
        React.createElement("g", { id: "icon/Hus_bl\u00E5", transform: "translate(0.000000, 1.000000)", fill: houseColor, stroke: houseColor },
            React.createElement("g", { id: "Hus_bl\u00E5", transform: "translate(2.000000, 0.000000)" },
                React.createElement("path", { d: "M4.5,12.9549812 L2.20755677,12.9549812 L2.20755677,6.88420008 L0,6.88420008 L5.85081156,0.649708727 C6.22874721,0.246989115 6.86159335,0.226897747 7.26431296,0.604833401 C7.27974241,0.6193133 7.29470839,0.634279277 7.30918829,0.649708727 L13.1599998,6.88420008 L10.7787129,6.88420008 L10.7787129,12.9549812 L8.5,12.9549812 L8.5,9.3338261 C8.5,8.77773526 8.04809015,8.33401861 7.4906311,8.33401861 L5.5093689,8.33401861 C4.94335318,8.33401861 4.5,8.78164767 4.5,9.3338261 L4.5,12.9549812 Z", id: "Combined-Shape" }))))));
SVG.propTypes = {
    houseColor: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};
SVG.defaultProps = {
    houseColor: Variables.turquoiseBlue,
    width: "17px",
    height: "15px",
};
export default SVG;
