import React from 'react';
import styled from 'styled-components';
const ResultListButton = styled.button `
  background: inherit;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 2rem 24px;

  &:hover,
  &:focus {
    background-color: rgb(251, 240, 255);
    color: rgb(120, 0, 180);
  }

  &:active {
    background-color: rgb(237, 200, 255);
    color: rgb(120, 0, 180);
  }
`;
const CustomerInfo = styled.div `
  flex-grow: 1;
  text-align: left;
`;
const CustomerId = styled.div `
  padding-bottom: 0.2rem;
  font-weight: normal;
  letter-spacing: 2px;
`;
const FullName = styled.div `
  font-size: 1.4rem;
  font-weight: normal;
`;
const Address = styled.div `
  font-weight: normal;
  padding-top: 0.3rem;
`;
const CustomerStatus = styled.div `
  display: flex;
  align-items: center;
  height: 100%;
`;
const Platforms = styled.div `
  font-weight: normal;
  padding-top: 0.3rem;
`;
export const SearchResultItem = ({ customer: it, onClick }) => (React.createElement(ResultListButton, { type: "button", onClick: () => onClick() },
    React.createElement(CustomerInfo, null,
        React.createElement(CustomerId, null,
            "#",
            it.customerId),
        React.createElement(FullName, null, `${it.fullName}`),
        React.createElement(Address, null, `${it.street} ${it.houseNumberNumeric}${it.houseNumberAlpha}, ${it.flatOrApartment}, ${it.postalCode}, ${it.bigCity}`),
        React.createElement(Platforms, null, it.platforms.join(' · '))),
    React.createElement(CustomerStatus, null,
        React.createElement("div", null, `${it.customerStatus}`))));
