import React from 'react';
import PropTypes from 'prop-types';
import capitalize from '../../../../utils/capitalizeString';
import Feedback from '../../../../feedbacks';
import InfoBox from '../../../../components/InfoBox/InfoBoxError';
import ListItem from './ListItem';
import NOB from '../../../../constants/language-strings';
import { FEEDBACK_NOT_FOUND } from '../../../../constants/feedbackNames';
import { PASSED } from '../../constants';
import UpdateHealthCheck from './UpdateHealthCheck';
export default function HealthCheckList({ healthCheck, updateHealthCheck, devicePollingCheckPassed, isIccFetching, isFetchingProvisioning, }) {
    const iccCheckListItems = Object.entries(healthCheck.iccCheck).map(([key, value]) => (React.createElement(ListItem, { passed: value === PASSED, description: capitalize(key.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2')), key: key })));
    const provisioningListItems = healthCheck.provisioning.healthCheckResponses.map(item => (React.createElement(ListItem, { description: item.description, passed: item.checksPassed, key: item.description })));
    return (React.createElement("div", { className: "health__check__list__container" },
        React.createElement("div", null,
            React.createElement("div", { className: "health__check__list__container--header" }, NOB.HEALTH_CHECK.ICC_CHECKS),
            !isIccFetching && (React.createElement("div", null, Object.entries(healthCheck.iccCheck).length > 0 ? (iccCheckListItems) : (React.createElement(InfoBox, { feedback: React.createElement(Feedback, { name: FEEDBACK_NOT_FOUND }), firstInfoBoxLine: NOB.HEALTH_CHECK.ICC_CHECK_ERROR, link: false }))))),
        React.createElement("div", null,
            React.createElement("div", { className: "health__check__list__container--header" }, NOB.HEALTH_CHECK.PROVISIONING_HEADING),
            !isFetchingProvisioning && !healthCheck.errorMessage && (React.createElement("div", null, provisioningListItems.length > 0 ? (React.createElement("div", null,
                provisioningListItems,
                React.createElement(UpdateHealthCheck, { healthCheck: healthCheck, updateHealthCheck: updateHealthCheck, devicePollingCheckPassed: devicePollingCheckPassed }))) : (React.createElement(InfoBox, { feedback: React.createElement(Feedback, { name: FEEDBACK_NOT_FOUND }), firstInfoBoxLine: NOB.HEALTH_CHECK.PROVISIONING_ERROR, link: false })))))));
}
HealthCheckList.propTypes = {
    healthCheck: PropTypes.objectOf(PropTypes.any).isRequired,
    updateHealthCheck: PropTypes.func.isRequired,
    devicePollingCheckPassed: PropTypes.bool,
    isIccFetching: PropTypes.bool.isRequired,
    isFetchingProvisioning: PropTypes.bool.isRequired,
};
HealthCheckList.defaultProps = {
    devicePollingCheckPassed: false,
};
