import { DEVICE_POLLING_CUSTOMER_ERROR, DEVICE_POLLING_CUSTOMER_RECEIVE, DEVICE_POLLING_CUSTOMER_REQUEST, } from '../constants';
const devicePollingDefaultState = {
    isFetching: true,
    devicePollingCustomer: {
        checkPassed: false,
        platform: '',
        deviceStatus: [],
    },
    errorMessage: '',
};
export default (state = devicePollingDefaultState, action) => {
    const { devicePollingCustomer, errorMessage, type } = action;
    switch (type) {
        case DEVICE_POLLING_CUSTOMER_REQUEST:
            return Object.assign(Object.assign({}, state), { isFetching: true });
        case DEVICE_POLLING_CUSTOMER_RECEIVE:
            return Object.assign(Object.assign({}, state), { devicePollingCustomer, isFetching: false });
        case DEVICE_POLLING_CUSTOMER_ERROR:
            return Object.assign(Object.assign({}, state), { isFetching: false, errorMessage });
        default:
            return state;
    }
};
