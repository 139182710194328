import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ExtendedImage from '../../../../components/ImageViewer/ImageViewer';
import './textBox.scss';
import Icon from '../../../../icons';
import { PDF } from '../../../../constants/iconNames';
import isImage from '../../../../utils/isImage';
function TextBox({ heading, text, attachments, languageStrings }) {
    const [expanded, setIsExpanded] = useState(false);
    const [chosenImage, setChosenImage] = useState(0);
    const attachmentList = attachments.attachmentList.map((attachment, index) => {
        if (!isImage(attachment.href)) {
            const fileEnding = attachment.href
                .substring(attachment.href.lastIndexOf('.') + 1)
                .toUpperCase();
            const title = fileEnding.length > 4 ? languageStrings.DEFAULT_FILE_NAME : fileEnding;
            return (React.createElement("a", { href: attachment.href, rel: "noopener noreferrer", target: "_blank", key: attachment.href },
                React.createElement(Icon, { name: PDF, title: title })));
        }
        return (React.createElement("button", { onClick: () => {
                setIsExpanded(true);
                setChosenImage(index);
            }, key: attachment.href },
            React.createElement("img", { src: attachment.href, alt: "Vedlegg" })));
    });
    const imageFiles = attachments.attachmentList
        .map(image => image.href)
        .filter(link => isImage(link));
    return (React.createElement("div", { className: "comment__container" },
        React.createElement("div", { className: "comment-header" }, heading),
        React.createElement("div", { className: "comment__content" },
            text,
            attachments.hasError && React.createElement("div", { className: "error" }, languageStrings.ATTACHMENT_ERROR),
            attachmentList.length > 0 && !attachments.hasError && (React.createElement("div", { className: "attachment-list" }, attachmentList)),
            expanded && (React.createElement(ExtendedImage, { expandedIndex: chosenImage, imagesFiles: imageFiles, toggleExpandImage: () => setIsExpanded(!expanded) })))));
}
TextBox.propTypes = {
    heading: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    attachments: PropTypes.objectOf(PropTypes.any),
    languageStrings: PropTypes.objectOf(PropTypes.string).isRequired,
};
TextBox.defaultProps = {
    attachments: {
        hasError: false,
        attachmentList: [],
    },
};
const mapStateToProps = state => ({
    languageStrings: state.NOB.COMMENT,
});
export default connect(mapStateToProps)(TextBox);
