import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WorkOrderList from './workOrderList/WorkOrderList';
import Feedback from '../../../feedbacks';
import { feedbackBlue, feedbackGreen, feedbackPurple, yellowOnBlack, } from '../../../styles/variables.scss';
import { FEEDBACK_POPCORN, FEEDBACK_JUGGLE } from '../../../constants/feedbackNames';
import NOB from '../../../constants/language-strings';
import InfoBox from '../../../components/InfoBox/InfoBox';
export default function WorkOrderListContent({ isWorkOrderListEmpty, areAllWorkOrdersDone, isTechnician, isExternalInstaller, workOrdersCancelled, workOrdersDone, workOrdersNotDone, }) {
    const [clickCount, setClickCount] = useState({ count: 0, reverse: false });
    const countClick = () => {
        const { count, reverse } = clickCount;
        if (count === 3 && !reverse) {
            setClickCount({ count: count + 1, reverse: true });
        }
        else if (count === 1 && reverse) {
            setClickCount({ count: count - 1, reverse: false });
        }
        else if (count < 5 && reverse) {
            setClickCount({ count: count - 1, reverse });
        }
        else {
            setClickCount({ count: count + 1, reverse });
        }
    };
    const juggleButton = (React.createElement("button", { onClick: countClick },
        React.createElement(Feedback, { name: FEEDBACK_JUGGLE, reverse: clickCount.reverse, ball1Fill: clickCount.reverse ? feedbackBlue : feedbackGreen, ball3Fill: clickCount.reverse ? feedbackPurple : yellowOnBlack })));
    return (React.createElement("div", { className: "workOrder__list__content" },
        workOrdersNotDone.length > 0 && React.createElement(WorkOrderList, { workOrders: workOrdersNotDone }),
        areAllWorkOrdersDone && isExternalInstaller && (React.createElement(InfoBox, { feedback: React.createElement(Feedback, { name: FEEDBACK_POPCORN }), link: false, secondInfoBoxLine: NOB.NO_WORK_ORDERS.DONE })),
        isWorkOrderListEmpty && isTechnician && (React.createElement(InfoBox, { feedback: juggleButton, link: false, secondInfoBoxLine: NOB.NO_WORK_ORDERS.NO_WORK_ORDERS, thirdInfoBoxLine: NOB.NO_WORK_ORDERS.WAIT_FOR_NEXT })),
        workOrdersDone.length > 0 && (React.createElement("div", { className: "workOrder__list--complete" },
            React.createElement("span", { className: "workOrder__list--complete--heading" }, NOB.WORKORDER_LIST.COMPLETED_HEADER),
            React.createElement(WorkOrderList, { workOrders: workOrdersDone }))),
        workOrdersCancelled.length > 0 && (React.createElement("div", { className: "workOrder__list--complete" },
            React.createElement("span", { className: "workOrder__list--complete--heading" }, NOB.WORKORDER_LIST.CANCELLED_JOBS),
            React.createElement(WorkOrderList, { workOrders: workOrdersCancelled }))),
        isWorkOrderListEmpty && isExternalInstaller && (React.createElement(InfoBox, { feedback: React.createElement(Feedback, null), firstInfoBoxLine: NOB.FEEDBACKS.HMM, link: false, secondInfoBoxLine: NOB.NO_WORK_ORDERS.NO_WORK_ORDERS }))));
}
WorkOrderListContent.propTypes = {
    isWorkOrderListEmpty: PropTypes.bool,
    areAllWorkOrdersDone: PropTypes.bool,
    isTechnician: PropTypes.bool,
    isExternalInstaller: PropTypes.bool,
    workOrdersCancelled: PropTypes.arrayOf(PropTypes.any),
    workOrdersDone: PropTypes.arrayOf(PropTypes.any),
    workOrdersNotDone: PropTypes.arrayOf(PropTypes.any).isRequired,
};
WorkOrderListContent.defaultProps = {
    isWorkOrderListEmpty: false,
    isTechnician: false,
    isExternalInstaller: false,
    areAllWorkOrdersDone: false,
    workOrdersCancelled: [],
    workOrdersDone: [],
};
