import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createRootReducer from './rootReducer';
import sagas from './rootSagas';
const sagaMiddleware = createSagaMiddleware();
const configureStore = (initialState = {}, history) => {
    // Create the store with two middlewares
    // 1. sagaMiddleware: Makes redux-sagas work
    // 2. routerMiddleware: Syncs the location/URL path to the state
    const middlewares = [sagaMiddleware, routerMiddleware(history)];
    const enhancers = [applyMiddleware(...middlewares)];
    // If Redux DevTools Extension is installed use it, otherwise use Redux compose
    /* eslint-disable no-underscore-dangle */
    const composeEnhancers = process.env.NODE_ENV !== 'production' &&
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ shouldHotReload: false })
        : compose;
    /* eslint-enable */
    const store = createStore(connectRouter(history)(createRootReducer(history)), initialState, composeEnhancers(...enhancers));
    sagaMiddleware.run(sagas);
    return store;
};
export default configureStore;
