import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
import "./feedback.scss";
const SVG = ({ armStroke, ball1Fill, ball3Fill, bodyFill, darkerStroke, darkestStroke, eyeFill, mouthStroke, reverse, }) => (React.createElement("svg", { overflow: "visible", width: "140", height: "120", "data-name": "Layer 1", viewBox: "0 0 139.04 107.5" },
    React.createElement("g", { stroke: darkerStroke, fill: bodyFill, strokeWidth: "2.5" },
        React.createElement("path", { strokeMiterlimit: "10", d: "M101.93 101.83h-63.2a3 3 0 0 1-3-3v-41a3 3 0 0 1 3-3h63.1a3 3 0 0 1 3 3v41a2.86 2.86 0 0 1-2.9 3z" }),
        React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", d: "M60.53 106.03h19.6" })),
    React.createElement("path", { fill: eyeFill, d: "M56.43 70.63a1.8 1.8 0 1 1 3.6 0 1.8 1.8 0 0 1-3.6 0z" }),
    React.createElement("path", { fill: eyeFill, d: "M78.33 70.63a1.8 1.8 0 1 1 3.6 0 1.8 1.8 0 0 1-3.6 0z" }),
    React.createElement("path", { id: "Imported-Layers-Copy", fill: "none", stroke: mouthStroke, strokeLinecap: "round", strokeWidth: "2.5", d: "M78.33 78.83a12 12 0 0 1-17 0" }),
    React.createElement("path", { fill: "none", stroke: armStroke, strokeMiterlimit: "10", strokeWidth: "2.5", d: "M35.83 79.83c-6.6 1.2-14.7 1.3-19-3-2.65-2.57-4.28-7.3-4.65-11.67" }),
    React.createElement("path", { fill: bodyFill, stroke: darkestStroke, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2.5", d: "M12.93 64.83c-.2.2-2.9-.5-3.2-.6a16.57 16.57 0 0 1-3.7-1.4 10 10 0 0 1-4.4-4.9 4.25 4.25 0 0 1-.3-2.5 2.44 2.44 0 0 1 1.6-1.8c3.32-.78 6.7 3 7.9 4.1 1.6.8.4-1.1.6-2a1.37 1.37 0 0 1 1.2-1.1c.7 0 1.2.6 1.6 1.2 1.9 3.2 1.1 6.5-1.3 9z" }),
    React.createElement("path", { fill: "none", stroke: armStroke, strokeMiterlimit: "10", strokeWidth: "2.5", d: "M105.53 79.83c6.6 1.2 14.7 1.3 19-3 2.49-2.42 4.08-6.77 4.57-10.92" }),
    React.createElement("path", { fill: bodyFill, stroke: darkestStroke, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2.5", d: "M128.33 65.73c.3.2 2.7-1.1 3-1.3a14.61 14.61 0 0 0 3.3-2.2 9.26 9.26 0 0 0 3.1-5.7 4.35 4.35 0 0 0-.3-2.5 2.27 2.27 0 0 0-1.9-1.4c-3.59.09-6.1 5.3-6.8 5.9-1.4 1.1-.6-1-1-1.8a1.56 1.56 0 0 0-1.5-.8c-.7.1-1.1.9-1.3 1.5-1 3.4.53 6.4 3.4 8.3z" }),
    React.createElement("path", { className: reverse ? "ball5" : "ball1", fill: ball1Fill, stroke: darkestStroke, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2.5", d: "M14.71 28.13c0-3.039 2.461-5.5 5.5-5.5s5.5 2.461 5.5 5.5-2.461 5.5-5.5 5.5a5.499 5.499 0 0 1-5.5-5.5z" }),
    React.createElement("path", { className: reverse ? "ball6" : "ball2", fill: bodyFill, stroke: darkestStroke, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2.5", d: "M43.34 6.75c0-3.039 2.461-5.5 5.5-5.5s5.5 2.461 5.5 5.5-2.461 5.5-5.5 5.5a5.499 5.499 0 0 1-5.5-5.5z" }),
    React.createElement("path", { className: reverse ? "ball7" : "ball3", fill: ball3Fill, stroke: darkestStroke, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2.5", d: "M87.34 6.75c0-3.039 2.461-5.5 5.5-5.5s5.5 2.461 5.5 5.5-2.461 5.5-5.5 5.5a5.499 5.499 0 0 1-5.5-5.5z" }),
    React.createElement("path", { className: reverse ? "ball8" : "ball4", fill: bodyFill, stroke: darkestStroke, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2.5", d: "M115.21 28c0-3.039 2.461-5.5 5.5-5.5s5.5 2.461 5.5 5.5-2.461 5.5-5.5 5.5a5.499 5.499 0 0 1-5.5-5.5z" }),
    React.createElement("path", { fill: "none", stroke: darkestStroke, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2.5", d: "M8.46 101.63c0-2.486 2.014-4.5 4.5-4.5s4.5 2.014 4.5 4.5-2.014 4.5-4.5 4.5a4.499 4.499 0 0 1-4.5-4.5z" }),
    React.createElement("path", { fill: ball3Fill, stroke: darkestStroke, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2.5", d: "M14.96 100.63c0-3.039 2.461-5.5 5.5-5.5s5.5 2.461 5.5 5.5-2.461 5.5-5.5 5.5a5.499 5.499 0 0 1-5.5-5.5z" }),
    React.createElement("path", { fill: ball1Fill, stroke: darkestStroke, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2.5", d: "M116.84 100.75c0-3.039 2.461-5.5 5.5-5.5s5.5 2.461 5.5 5.5-2.461 5.5-5.5 5.5a5.499 5.499 0 0 1-5.5-5.5z" })));
SVG.propTypes = {
    armStroke: PropTypes.string,
    ball1Fill: PropTypes.string,
    ball3Fill: PropTypes.string,
    bodyFill: PropTypes.string,
    darkerStroke: PropTypes.string,
    darkestStroke: PropTypes.string,
    eyeFill: PropTypes.string,
    mouthStroke: PropTypes.string,
    reverse: PropTypes.bool,
};
SVG.defaultProps = {
    armStroke: Variables.feedbackBlack,
    ball1Fill: Variables.feedbackGreen,
    ball3Fill: Variables.yellowOnBlack,
    bodyFill: Variables.whiteSecondary,
    darkerStroke: Variables.feedbackDarkerGray,
    darkestStroke: Variables.feedbackDarkestGray,
    eyeFill: Variables.blackSecondary,
    mouthStroke: Variables.feedbackDarkGray,
    reverse: false,
};
export default SVG;
