import React from 'react';
import Textarea from 'react-textarea-autosize';
import PropTypes from 'prop-types';
import './text-input.scss';
function TextInput({ hasError, label, value, onChange, maxLength, name, placeholder }) {
    return (React.createElement("div", { className: hasError ? 'text-input--error' : 'text-input' },
        React.createElement("label", { htmlFor: name },
            React.createElement("span", { className: "label" }, hasError ? `* ${label}` : label),
            React.createElement(Textarea, { name: name, value: value, className: "textarea", onChange: onChange, maxLength: maxLength, placeholder: placeholder }))));
}
TextInput.propTypes = {
    hasError: PropTypes.bool,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    maxLength: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
};
TextInput.defaultProps = {
    maxLength: '',
    placeholder: '',
    hasError: false,
};
export default TextInput;
