import { call, put, takeLatest } from 'redux-saga/effects';
import { WORKORDER_SERVICES } from '../../../../constants/endpoints';
import { WORKORDER_SERVICES_REQUEST } from '../../workLogConstants';
import { getAPI } from '../../../../api/apiConfig';
import * as actions from '../../actions/workOrderServiceActions';
export function* callWorkorderServices({ workOrderId }) {
    try {
        const url = WORKORDER_SERVICES({ workOrderId });
        const result = yield call(getAPI, url);
        yield put(actions.workorderServicesSuccess({ services: result }));
    }
    catch (error) {
        yield put(actions.workorderServicesError({
            errorMessage: `Could not find service. ${error.message}`,
        }));
    }
}
export default [takeLatest(WORKORDER_SERVICES_REQUEST, callWorkorderServices)];
