import { FIND_SERVICE_TYPES_REQUEST, FIND_SERVICE_TYPES_SUCCESS, FIND_SERVICE_TYPES_ERROR, SELECT_SERVICE_TYPE, UPDATE_DIAGNOSTIC_SERVICE, DEFAULT_SERVICE_TYPE, } from '../createNewWorkOrderConstants';
const serviceTypesReducerDefaultState = {
    isFetching: true,
    errorMessage: '',
    selectedDiagnosticServices: [],
    serviceType: DEFAULT_SERVICE_TYPE,
    serviceTypes: [],
};
export default (state = serviceTypesReducerDefaultState, action) => {
    const { errorMessage, selectedDiagnosticServices, serviceType, serviceTypes, type } = action;
    switch (type) {
        case FIND_SERVICE_TYPES_REQUEST:
            return Object.assign({}, state);
        case FIND_SERVICE_TYPES_SUCCESS:
            return Object.assign(Object.assign({}, state), { isFetching: false, serviceTypes });
        case FIND_SERVICE_TYPES_ERROR:
            return Object.assign(Object.assign({}, state), { isFetching: false, errorMessage });
        case SELECT_SERVICE_TYPE:
            return Object.assign(Object.assign({}, state), { serviceType });
        case UPDATE_DIAGNOSTIC_SERVICE:
            return Object.assign(Object.assign({}, state), { selectedDiagnosticServices });
        default:
            return state;
    }
};
