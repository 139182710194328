import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './changeProduct.scss';
import ProductListElement from '../../../../../components/ProductListElement/ProductListElement';
import Scanner from '../../../../../components/Scanner/Scanner';
function DeviceElement({ device, onReset, hasChanged, serialNumberInput, onScannerDetect, isFetching, handleChange, handleSubmit, status, }) {
    const [showScanner, setShowScanner] = useState(false);
    const onDetected = result => {
        onScannerDetect(result);
        setShowScanner(false);
    };
    return (React.createElement("div", null,
        React.createElement(ProductListElement, { isFetching: isFetching, onScannerClick: () => setShowScanner(true), title: device.name, serialNumberInputs: !hasChanged ? [serialNumberInput] : [], handleSubmit: handleSubmit, handleChange: handleChange, serialNumbers: [device.serialNumber], status: status, onReset: onReset }),
        showScanner && (React.createElement(Scanner, { onHeaderClick: () => setShowScanner(false), onDetected: onDetected }))));
}
DeviceElement.propTypes = {
    device: PropTypes.shape({
        name: PropTypes.string.isRequired,
        serialNumber: PropTypes.string,
    }).isRequired,
    onReset: PropTypes.func,
    hasChanged: PropTypes.bool,
    serialNumberInput: PropTypes.shape({
        dpadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        errorMessage: PropTypes.string,
    }),
    onScannerDetect: PropTypes.func,
    isFetching: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
};
DeviceElement.defaultProps = {
    onReset: () => { },
    hasChanged: false,
    serialNumberInput: null,
    onScannerDetect: () => { },
    isFetching: false,
};
export default DeviceElement;
