import { FOLLOW_UP_FORM_FAILURE, FOLLOW_UP_FORM_REQUEST, FOLLOW_UP_FORM_SUCCESS, } from '../FollowUpConstans';
const followUpFormsReducerDefaultState = {
    isFetching: false,
    errorMessage: '',
    forms: [],
};
export default (state = followUpFormsReducerDefaultState, action) => {
    const { errorMessage, forms, type } = action;
    switch (type) {
        case FOLLOW_UP_FORM_REQUEST:
            return Object.assign(Object.assign({}, state), { isFetching: true });
        case FOLLOW_UP_FORM_SUCCESS:
            return Object.assign(Object.assign({}, state), { isFetching: false, forms });
        case FOLLOW_UP_FORM_FAILURE:
            return Object.assign(Object.assign({}, state), { isFetching: false, errorMessage });
        default:
            return state;
    }
};
