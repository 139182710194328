import { call, put, select, takeLatest } from 'redux-saga/effects';
import querystring from 'querystring';
import * as actions from '../actions/devicePollingMacActions';
import { DEVICE_POLLING_MAC } from '../../../constants/endpoints';
import { postAPI } from '../../../api/apiConfig';
import { UPDATE_DEVICE_POLLING_MAC_REQUEST } from '../constants';
export function* callUpdateHealthCheck({ macAddress }) {
    try {
        const devicePollingMac = yield select(state => state.devicePollingMac.devicePollingMac);
        const url = DEVICE_POLLING_MAC(macAddress);
        const body = devicePollingMac;
        const result = yield call(postAPI, url, querystring.stringify({
            lastPoll: JSON.stringify(body),
        }));
        yield put(actions.receiveUpdateDevicePollingMac({ devicePollingMac: result }));
        yield put(actions.receiveDevicePollingMac({ devicePollingMac: result }));
    }
    catch (error) {
        yield put(actions.errorUpdateDevicePollingMac({
            errorMessage: `Error in health check response. ${error.message}`,
        }));
    }
}
export default [takeLatest(UPDATE_DEVICE_POLLING_MAC_REQUEST, callUpdateHealthCheck)];
