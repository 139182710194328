import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/mapIdActions';
import { getAPI } from '../../../api/apiConfig';
import { MAP_ID } from '../../../constants/endpoints';
import { MAP_ID_REQUEST } from '../constants';
export function* callMapId() {
    try {
        const result = yield call(getAPI, MAP_ID);
        yield put(actions.receiveMapId({ mapId: result.mapId }));
    }
    catch (error) {
        yield put(actions.errorMapId({
            errorMessage: `Could not receive map id. ${error.message}`,
        }));
    }
}
export default [takeLatest(MAP_ID_REQUEST, callMapId)];
