import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
const SVG = ({ fill }) => (React.createElement("svg", { width: "48px", height: "48px", viewBox: "0 0 48 48", version: "1.1" },
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "icon/delete", fill: fill, fillRule: "nonzero" },
            React.createElement("g", { id: "baseline-delete_outline-24px", transform: "translate(17.000000, 15.000000)" },
                React.createElement("path", { d: "M1,16 C1,17.1 1.9,18 3,18 L11,18 C12.1,18 13,17.1 13,16 L13,4 L1,4 L1,16 Z M3,6 L11,6 L11,16 L3,16 L3,6 Z M10.5,1 L9.5,0 L4.5,0 L3.5,1 L0,1 L0,3 L14,3 L14,1 L10.5,1 Z", id: "Shape" }))))));
SVG.propTypes = {
    fill: PropTypes.string,
};
SVG.defaultProps = {
    fill: Variables.blackPrimary,
};
export default SVG;
