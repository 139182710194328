import { call, put, takeLatest } from 'redux-saga/effects';
import { errorUpdateService } from '../../actions/updateServiceActions';
import { putAPI } from '../../../../api/apiConfig';
import { UPDATE_SERVICE } from '../../../../constants/endpoints';
import { UPDATE_SERVICE_REQUEST } from '../../workLogConstants';
import { workorderServicesSuccess } from '../../actions/workOrderServiceActions';
export function* callUpdateService({ workOrderId, serviceId, quantity, workOrderServiceId }) {
    try {
        const updateServiceUrl = UPDATE_SERVICE(workOrderId);
        const body = {
            serviceId,
            workOrderServiceId,
            quantity,
        };
        const newServiceCodeList = yield call(putAPI, updateServiceUrl, body);
        yield put(workorderServicesSuccess({ services: newServiceCodeList }));
    }
    catch (error) {
        yield put(errorUpdateService({
            errorMessage: `Could not update service. ${error.message}`,
        }));
    }
}
export default [takeLatest(UPDATE_SERVICE_REQUEST, callUpdateService)];
