import React from 'react';
import PropTypes from 'prop-types';
import { AMOUNT_MONEY } from '../../constants/characters';
import { ARROW_DOWN, CROSS_LARGE } from '../../constants/iconNames';
import Icon from '../../icons';
import { whitePrimary } from '../../styles/variables.scss';
import { hours, minutes } from '../../utils/timeCalculator';
export default function ServicecodeContent({ amount, noDropdown, removeClick, service: { name, price, timeEstimate, quantity }, technician, }) {
    const totalMinutes = timeEstimate.toFixed(2) * quantity;
    return (React.createElement("div", { className: noDropdown ? 'service__long' : 'service__short' },
        noDropdown && (React.createElement("div", { className: "service__long__left" },
            amount,
            React.createElement(Icon, { stroke: whitePrimary, name: ARROW_DOWN }))),
        React.createElement("div", { className: "service__center" },
            React.createElement("span", null, name),
            React.createElement("div", { className: "service__center--bottom" }, !technician ? (React.createElement("div", { className: "service__center--bottom-price-duation" },
                React.createElement("span", null, `${hours(totalMinutes)} ${minutes(totalMinutes)}`),
                React.createElement("span", null,
                    price,
                    AMOUNT_MONEY))) : (React.createElement("span", null,
                price,
                AMOUNT_MONEY)))),
        React.createElement("button", { onClick: removeClick },
            React.createElement(Icon, { name: CROSS_LARGE }))));
}
ServicecodeContent.propTypes = {
    amount: PropTypes.number.isRequired,
    noDropdown: PropTypes.bool.isRequired,
    removeClick: PropTypes.func.isRequired,
    service: PropTypes.objectOf(PropTypes.any).isRequired,
    technician: PropTypes.bool.isRequired,
};
