import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/serviceTypesActions';
import { FIND_SERVICE_TYPES, WORKORDER } from '../../../constants/endpoints';
import { FIND_SERVICE_TYPES_REQUEST } from '../createNewWorkOrderConstants';
import { getAPI } from '../../../api/apiConfig';
export function* callFindServiceTypes({ workOrderId }) {
    try {
        const workOrderUrl = WORKORDER({ workOrderId });
        const workOrder = yield call(getAPI, workOrderUrl);
        const { validAddressId } = workOrder.address;
        const serviceTypesUrl = FIND_SERVICE_TYPES(validAddressId);
        const serviceTypes = yield call(getAPI, serviceTypesUrl);
        yield put(actions.findServiceTypesSuccess({ serviceTypes }));
    }
    catch (error) {
        yield put(actions.findServiceTypesError({
            errorMessage: `Could not find service types. ${error.message}`,
        }));
    }
}
export default [takeLatest(FIND_SERVICE_TYPES_REQUEST, callFindServiceTypes)];
