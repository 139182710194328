import React from 'react';
import PropTypes from 'prop-types';
import NOB from '../../../../constants/language-strings';
import './orderedProducts.scss';
import { hours, minutes } from '../../../../utils/timeCalculator';
const OrderedProducts = ({ done, orderedItems, technician }) => {
    const showDiagnosis = technician && !done;
    const itemSummary = showDiagnosis
        ? orderedItems.itemSummary.filter(item => item.name.split(' > ').includes(NOB.ORDERED_PRODUCTS.DIAGNOSIS))
        : orderedItems.itemSummary.filter(item => !item.name.includes(NOB.ORDERED_PRODUCTS.DIAGNOSIS));
    const removeDiagnosis = diagnosisItemName => diagnosisItemName.replace(`${NOB.ORDERED_PRODUCTS.DIAGNOSIS} >`, '');
    const productsTable = itemSummary.map(product => (React.createElement("div", { className: "ordered__products--items", key: product.workOrderServiceId },
        React.createElement("div", null, product.quantity),
        React.createElement("div", null, showDiagnosis ? removeDiagnosis(product.name) : product.name),
        React.createElement("div", null, `${hours(product.timeEstimate)} ${minutes(product.timeEstimate)}`))));
    return (itemSummary.length > 0 && (React.createElement("div", { className: "ordered__products" },
        React.createElement("div", { className: "ordered__products--headings" },
            React.createElement("div", null, NOB.ORDERED_PRODUCTS.QUANTITY),
            done || !technician
                ? NOB.ORDERED_PRODUCTS.SERVICE_CODES
                : NOB.ORDERED_PRODUCTS.DIAGNOSIS,
            React.createElement("div", null, NOB.ORDERED_PRODUCTS.TIME)),
        React.createElement("div", { className: "ordered__products--productlist" }, productsTable))));
};
OrderedProducts.propTypes = {
    done: PropTypes.bool.isRequired,
    orderedItems: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
    technician: PropTypes.bool.isRequired,
};
export default OrderedProducts;
