import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ARROW_LEFT } from '../../constants/iconNames';
import { ADDRESS_SEARCH, FRONT_PAGE_PATH, SIGN_OUT_PATH } from '../../constants/paths';
import TeliaLogo from '../../images/Telia_Primary_Logo_RGB.png';
import Icon from '../../icons';
import NOB from '../../constants/language-strings';
import Variables from '../../styles/variables.scss';
import './Header.scss';
import styled from 'styled-components';
import { useSearchStore } from '../../features/address-search/useSearchStore';
const AddressSearchLink = styled(Link) `
  display: flex;
  align-items: center;
  color: #ffdc00;
  margin-left: 16px;
`;
const Header = ({ errorMessage, isAuthenticated, router, workOrder, location, history }) => {
    const previousPath = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
    const isWorkOrderPath = router.location.pathname.match(/workOrder\/([0-9]{7})/);
    const isAuthenticatedAndInWorkOrder = isAuthenticated && isWorkOrderPath;
    const workOrderHeader = (React.createElement("div", { className: "header" },
        errorMessage === '' && (React.createElement("div", { className: "header__service-type" }, workOrder.serviceTypeName)),
        React.createElement(Link, { className: "header__button-back", to: previousPath === '/workOrder' ? '/' : previousPath },
            React.createElement(Icon, { stroke: Variables.yellowPrimary, name: ARROW_LEFT }))));
    function onGoBackClicked(e) {
        e.preventDefault();
        history.goBack();
    }
    const customerInfoHeader = (React.createElement("div", { className: "header" },
        errorMessage === '' && React.createElement("div", { className: "header__service-type" }, "Kunde"),
        React.createElement("a", { className: "header__button-back", onClick: e => onGoBackClicked(e), href: "#" },
            React.createElement(Icon, { stroke: Variables.yellowPrimary, name: ARROW_LEFT }))));
    const { clearState } = useSearchStore();
    const defaultHeader = (React.createElement("div", { className: "header" },
        isAuthenticated && (React.createElement(AddressSearchLink, { to: ADDRESS_SEARCH, onClick: () => clearState() }, "Kundes\u00F8k")),
        React.createElement(Link, { className: "header__logo", to: FRONT_PAGE_PATH },
            React.createElement("img", { src: TeliaLogo, alt: "Telia logo", className: "header__logo" })),
        isAuthenticated && (React.createElement(Link, { className: "header__logout", to: SIGN_OUT_PATH }, NOB.HEADER.LOGOUT))));
    const header = (() => {
        if (!isAuthenticated)
            return defaultHeader;
        if (isAuthenticatedAndInWorkOrder)
            return workOrderHeader;
        if (router.location.pathname.indexOf('customerInfo') !== -1)
            return customerInfoHeader;
        return defaultHeader;
    })();
    return React.createElement("div", null, header);
};
const mapStateToProps = state => ({
    errorMessage: state.workOrder.errorMessage,
    isAuthenticated: state.authentication.isAuthenticated,
    router: state.router,
    user: state.user,
    workOrder: state.workOrder.workOrder,
});
Header.defaultProps = {
    errorMessage: '',
    workOrder: '',
};
Header.propTypes = {
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isAuthenticated: PropTypes.bool.isRequired,
    router: PropTypes.objectOf(PropTypes.any).isRequired,
    workOrder: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default withRouter(connect(mapStateToProps)(Header));
