import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import FetchingSpinner from '../../utils/FetchingSpinner';
function InfiniteScroller({ children, isFetching, onLoadMore, hasMore }) {
    return (React.createElement(InfiniteScroll, { pageStart: 0, hasMore: hasMore, initialLoad: false, loadMore: onLoadMore },
        children,
        isFetching && React.createElement(FetchingSpinner, null)));
}
InfiniteScroller.propTypes = {
    children: PropTypes.node.isRequired,
    onLoadMore: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    hasMore: PropTypes.bool.isRequired,
};
export default InfiniteScroller;
