import PropTypes from 'prop-types';
import React from 'react';
import NOB from '../../../../constants/language-strings';
import { PASSED } from '../../constants';
import StatusCheck from './StatusCheck';
import DeviceStatus from '../../../../constants/deviceStatus';
export default function HealthCheckHeader({ deviceCheck, healthCheck, isFetching, isIccFetching, isFetchingProvisioning, }) {
    const icxCheck = healthCheck.iccCheck.deviceLinks === PASSED && healthCheck.iccCheck.devicePairing === PASSED
        ? DeviceStatus.OK
        : DeviceStatus.ERROR;
    const provisionCheck = healthCheck.provisioning.allChecksPassed
        ? DeviceStatus.OK
        : DeviceStatus.ERROR;
    return (React.createElement("div", { className: "health__check__status__container" },
        React.createElement(StatusCheck, { checkTitle: NOB.HEALTH_CHECK.ICC_CHECKS, isFetching: isIccFetching, deviceStatus: icxCheck }),
        React.createElement(StatusCheck, { checkTitle: NOB.HEALTH_CHECK.PROVISIONING_STATUS, isFetching: isFetchingProvisioning, deviceStatus: provisionCheck }),
        React.createElement(StatusCheck, { checkTitle: NOB.HEALTH_CHECK.DEVICE_STATUS, isFetching: isFetching, deviceStatus: deviceCheck })));
}
HealthCheckHeader.propTypes = {
    deviceCheck: PropTypes.string,
    healthCheck: PropTypes.objectOf(PropTypes.any),
    isFetching: PropTypes.bool.isRequired,
    isIccFetching: PropTypes.bool.isRequired,
    isFetchingProvisioning: PropTypes.bool.isRequired,
};
HealthCheckHeader.defaultProps = {
    healthCheck: {},
    deviceCheck: '',
};
