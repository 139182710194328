import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
const SVG = ({ fill }) => (React.createElement("svg", { width: "16px", height: "16px", viewBox: "0 0 16 16", version: "1.1" },
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "Icon/Save", fill: fill, fillRule: "nonzero" },
            React.createElement("g", { id: "Group" },
                React.createElement("path", { d: "M14.25,7.78683181 C14.25,4.33767511 11.4518959,1.54139505 8,1.54139505 C4.54810412,1.54139505 1.75,4.33767511 1.75,7.78683181 C1.75,11.2359885 4.54810412,14.0322686 8,14.0322686 C11.4518959,14.0322686 14.25,11.2359885 14.25,7.78683181 Z M15.75,7.78683181 C15.75,12.0646347 12.2801041,15.5322686 8,15.5322686 C3.71989588,15.5322686 0.25,12.0646347 0.25,7.78683181 C0.25,3.50902894 3.71989588,0.0413950456 8,0.0413950456 C12.2801041,0.0413950456 15.75,3.50902894 15.75,7.78683181 Z M5.53015714,7.25632884 C5.23716844,6.96353113 4.76229473,6.96368597 4.46949703,7.25667467 C4.17669932,7.54966337 4.17685416,8.02453708 4.46984286,8.31733478 L6.46984286,10.316031 C6.73371912,10.5797353 7.15129752,10.6094089 7.44981219,10.3856688 L11.4498122,7.38762451 C11.7812608,7.1392001 11.8485652,6.66912013 11.6001408,6.33767151 C11.3517164,6.0062229 10.8816364,5.93891848 10.5501878,6.18734289 L7.07035128,8.79551894 L5.53015714,7.25632884 Z", id: "Combined-Shape" }))))));
SVG.propTypes = {
    fill: PropTypes.string,
};
SVG.defaultProps = {
    fill: Variables.iconBlue,
};
export default SVG;
