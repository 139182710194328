import { CUSTOMER_PRODUCTS_REQUEST, CUSTOMER_PRODUCTS_SUCCESS, CUSTOMER_PRODUCTS_FAILURE, CUSTOMER_PRODUCTS_UPDATE, CUSTOMER_PRODUCTS_REQUEST_BACKGROUND, UPDATE_SINGLE_PRODUCT, REMOVE_PRODUCT_SUCCESS, } from '../customerProductsConstants';
const customerProductsReducerDefaultState = {
    customerProducts: [],
    errorMessage: '',
    isFetching: true,
    isFetchingInBackground: false,
    selectedProductId: 0,
};
export default (state = customerProductsReducerDefaultState, action) => {
    const { customerProducts, errorMessage, type, newProduct, removedProductId } = action;
    switch (type) {
        case CUSTOMER_PRODUCTS_REQUEST:
            return Object.assign(Object.assign({}, state), { customerProducts: [], isFetching: true, errorMessage: '' });
        case CUSTOMER_PRODUCTS_REQUEST_BACKGROUND:
            return Object.assign(Object.assign({}, state), { isFetchingInBackground: true });
        case CUSTOMER_PRODUCTS_SUCCESS:
            return Object.assign(Object.assign({}, state), { customerProducts, isFetching: false, isFetchingInBackground: false });
        case CUSTOMER_PRODUCTS_FAILURE:
            return Object.assign(Object.assign({}, state), { errorMessage, isFetching: false, isFetchingInBackground: false });
        case CUSTOMER_PRODUCTS_UPDATE:
            return Object.assign(Object.assign({}, state), { customerProducts });
        case UPDATE_SINGLE_PRODUCT:
            return Object.assign(Object.assign({}, state), { customerProducts: state.customerProducts.map(oldProduct => oldProduct.productId === newProduct.productId ? newProduct : oldProduct) });
        case REMOVE_PRODUCT_SUCCESS: {
            return Object.assign(Object.assign({}, state), { customerProducts: state.customerProducts.filter(product => product.productId !== removedProductId) });
        }
        default:
            return state;
    }
};
