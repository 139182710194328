import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import capitalize from '../../../../utils/capitalizeString';
import { HOUSE, USER } from '../../../../constants/iconNames';
import Icon from '../../../../icons/index';
import NOB from '../../../../constants/language-strings';
import setStatusTimeValue from '../../../../utils/setStatusTimeValue';
import setStatusClassName from '../../../../utils/setStatusClassName';
import Status, { CANCELLED, INCOMPLETE_WITHOUT_CODE } from '../../../../constants/status-codes';
import Variables from '../../../../styles/variables.scss';
import isPast from '../../../../utils/isPastDue';
export default function WorkOrderList({ workOrders }) {
    const workOrderStatus = workOrder => /\d/.test(workOrder.status)
        ? workOrder.status.slice(workOrder.status.lastIndexOf(' ') + 1)
        : workOrder.status;
    const startedStatuses = [...Status.TRAVELING, ...Status.ON_SITE];
    const houseColor = (status, isPastDue) => {
        if (status.includes(INCOMPLETE_WITHOUT_CODE) || isPastDue)
            return Variables.buttonRed;
        else if (status === CANCELLED)
            return Variables.blackPrimary;
        return Variables.iconBlue;
    };
    const workOrderList = workOrders.map(workOrder => {
        const { address: { city, flatOrApartment, houseNumberAlpha, houseNumberNumeric, postalCode, street }, serviceTypeName, workOrderId, serviceDate, customer: { firstName, surname }, } = workOrder;
        const isPastDue = isPast(serviceDate);
        return (React.createElement(Link, { key: workOrderId, to: `/workOrder/${workOrderId}`, className: `${setStatusClassName(startedStatuses, workOrderStatus(workOrder), workOrder)} ${isPastDue ? 'workorder-warning' : ''}` },
            React.createElement("div", { className: "workOrder__container--top--left" },
                React.createElement("div", null, serviceTypeName),
                React.createElement("div", { className: "new-text" },
                    React.createElement(Icon, { name: USER, color: houseColor(workOrderStatus(workOrder), isPastDue) }),
                    firstName,
                    " ",
                    surname)),
            React.createElement("div", { className: "workOrder__container--top--right" },
                React.createElement("span", null, `${NOB.WORKORDER_LIST.WORKORDER}: `),
                React.createElement("span", null, workOrderId)),
            React.createElement("div", { className: "workOrder__container--bottom--left" },
                React.createElement(Icon, { name: HOUSE, houseColor: houseColor(workOrderStatus(workOrder), isPastDue) }),
                React.createElement("div", { className: "workOrder__container--bottom--left--address" },
                    React.createElement("div", null, `
                ${capitalize(street)}
                ${houseNumberNumeric}
                ${houseNumberAlpha && ` ${houseNumberAlpha}, `}
              `),
                    React.createElement("div", { className: "workOrder__container--bottom--left--address--postalCode" },
                        React.createElement("span", null, `${postalCode} ${capitalize(city)}`),
                        React.createElement("span", null, flatOrApartment)))),
            React.createElement("div", { className: "workOrder__container__bottom--right" }, setStatusTimeValue(workOrder, workOrderStatus(workOrder), isPastDue))));
    });
    return React.createElement("div", { className: "workOrder__list__container" }, workOrderList);
}
WorkOrderList.propTypes = {
    workOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
};
