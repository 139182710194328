import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions/addProductActions';
import { requestAgreementsPrice } from '../actions/agreementPriceActions';
import { ADD_PRODUCT_REQUEST } from '../customerProductsConstants';
import { postAPI } from '../../../api/apiConfig';
import { POST_PRODUCT } from '../../../constants/endpoints';
import { updateCustomerProducts } from '../actions/customerProducts';
export function* callAddProduct({ agreementId, customerId, product }) {
    try {
        const body = {
            agreementId,
            customerId,
            productIds: [product.value],
        };
        const url = POST_PRODUCT(agreementId);
        const newProduct = yield call(postAPI, url, body);
        const customerProducts = yield select(state => state.customerProducts.customerProducts);
        // Add new product to current product list.
        for (let i = 0; i < newProduct.length; i++) {
            newProduct[i].newProduct = true;
            yield put(updateCustomerProducts({
                customerProducts: customerProducts.concat(newProduct[i]),
            }));
        }
        yield put(actions.receiveAddProduct());
        yield put(requestAgreementsPrice());
    }
    catch (error) {
        yield put(actions.errorAddProduct(error.message));
    }
}
export default [takeEvery(ADD_PRODUCT_REQUEST, callAddProduct)];
