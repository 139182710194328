import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestPrevWorkOrderList } from '../actions/workOrderList';
import WorkOrderList from '../components/workOrderList/WorkOrderList';
import InfiniteScroller from '../../../components/InfiniteScroll/InfiniteScroller';
import InfoBox from '../../../components/InfoBox/InfoBox';
import Feedback from '../../../feedbacks';
import { FEEDBACK_POPCORN } from '../../../constants/feedbackNames';
function PreviousWorkOrderListContainer({ workOrders, fetchPreviousWorkOrders, isFetching, workOrderRequest, languageStrings, }) {
    useEffect(() => {
        if (!isFetching)
            fetchPreviousWorkOrders();
    }, []);
    if (!workOrders.length) {
        return (React.createElement(InfoBox, { feedback: React.createElement(Feedback, { name: FEEDBACK_POPCORN }), link: false, firstInfoBoxLine: languageStrings.UNDONE.TITLE, secondInfoBoxLine: languageStrings.UNDONE.SUB_TITLE }));
    }
    return (React.createElement(InfiniteScroller, { hasMore: workOrderRequest ? !workOrderRequest.last && !isFetching : true && !isFetching, onLoadMore: () => fetchPreviousWorkOrders(workOrderRequest.number + 1), isFetching: isFetching },
        React.createElement(WorkOrderList, { workOrders: workOrders })));
}
PreviousWorkOrderListContainer.propTypes = {
    workOrders: PropTypes.arrayOf(PropTypes.any),
    fetchPreviousWorkOrders: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    workOrderRequest: PropTypes.objectOf(PropTypes.any).isRequired,
    languageStrings: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};
PreviousWorkOrderListContainer.defaultProps = {
    workOrders: [],
};
const mapDispatchToProps = dispatch => ({
    fetchPreviousWorkOrders: pageNumber => dispatch(requestPrevWorkOrderList(pageNumber)),
});
const mapStateToProps = state => ({
    workOrders: state.previousWorkOrders.workOrders,
    isFetching: state.previousWorkOrders.isFetching,
    workOrderRequest: state.previousWorkOrders.latestPreviousWorkOrderRequest,
    languageStrings: state.NOB.NO_WORK_ORDERS,
});
export default connect(mapStateToProps, mapDispatchToProps)(PreviousWorkOrderListContainer);
