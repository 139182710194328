import { connect } from 'react-redux';
import { requestDevSignIn } from '../Authentication/actions';
import { AuthenticationDev } from './authentication-dev';
const mapStateToProps = state => ({
    signInError: state.authentication.signInError,
});
const mapDispatchToProps = dispatch => ({
    requestDevSignIn: credentials => dispatch(requestDevSignIn(credentials)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationDev);
