import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search';
import { SlideDown } from 'react-slidedown';
import { whiteSecondary, blackPrimary } from '../../styles/variables.scss';
import { ARROW_DOWN } from '../../constants/iconNames';
import Icon from '../../icons';
import './simple-dropdown.scss';
function SimpleDropdown({ isWhite, values, title, onChange, customClass }) {
    const [isOpen, setIsOpen] = useState(false);
    return (React.createElement("div", { className: `${isWhite ? 'simple-dropdown--white ' : 'simple-dropdown '}${customClass}` },
        React.createElement("button", { onClick: () => setIsOpen(!isOpen) },
            React.createElement("span", null, title),
            React.createElement("span", { className: isOpen ? 'rotate-up' : 'rotate-down' },
                React.createElement(Icon, { stroke: isWhite ? blackPrimary : whiteSecondary, name: ARROW_DOWN }))),
        React.createElement(SlideDown, { className: "slidedown", closed: !isOpen, transitionOnAppear: true },
            React.createElement(SelectSearch, { autofocus: true, handleClickOutside: () => { }, onChange: e => {
                    onChange(e);
                    setIsOpen(!isOpen);
                }, options: values, search: false }))));
}
SimpleDropdown.propTypes = {
    isWhite: PropTypes.bool,
    values: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.any,
        value: PropTypes.any,
    })).isRequired,
    title: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    customClass: PropTypes.string,
};
SimpleDropdown.defaultProps = {
    isWhite: false,
    customClass: '',
};
export default SimpleDropdown;
