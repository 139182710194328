import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import CustomerProductList from '../components/ProductList/CustomerProductList';
import Feedback from '../../../feedbacks';
import InfoBox from '../../../components/InfoBox/InfoBox';
import NOB from '../../../constants/language-strings';
import SlideInPage from '../../../components/SlideInPage/SlideInPage';
function CustomerProductsContainer({ agreements, areAgreementsFetching, customerProducts: { customerProducts, errorMessage, isFetching }, customerId, addProductErrorMessage, isFetchingWorkOrder, isAdding, }) {
    return (React.createElement(SlideInPage, { mainHeading: NOB.CUSTOMER_PRODUCTS.CUSTOMER_PRODUCTS, showLeftIcon: true, isFetching: isFetching || (areAgreementsFetching && agreements.length === 0) || isFetchingWorkOrder },
        !customerProducts.length > 0 ? (React.createElement(InfoBox, { feedback: React.createElement(Feedback, null), firstInfoBoxLine: NOB.FEEDBACKS.SORRY, secondInfoBoxLine: NOB.CUSTOMER_PRODUCTS.NO_CUSTOMER_PRODUCTS })) : null,
        React.createElement(CustomerProductList, { agreements: agreements, customerProducts: customerProducts, errorMessage: errorMessage, addProductErrorMessage: addProductErrorMessage, isAdding: isAdding, customerId: customerId })));
}
CustomerProductsContainer.propTypes = {
    agreements: PropTypes.objectOf(PropTypes.any),
    areAgreementsFetching: PropTypes.bool.isRequired,
    customerProducts: PropTypes.objectOf(PropTypes.any),
    isFetchingWorkOrder: PropTypes.bool.isRequired,
    customerId: PropTypes.number.isRequired,
    addProductErrorMessage: PropTypes.string.isRequired,
    isAdding: PropTypes.bool.isRequired,
};
CustomerProductsContainer.defaultProps = {
    agreements: [],
    customerProducts: {},
};
const mapStateToProps = state => ({
    isAdding: state.addProduct.isAdding,
    agreements: state.agreements,
    areAgreementsFetching: state.agreements.isFetching,
    customerProducts: state.customerProducts,
    isFetchingWorkOrder: state.workOrder.isFetching,
    customerId: state.workOrder.workOrder.customer.customerId,
    addProductErrorMessage: state.addProduct.errorMessage,
});
export default withRouter(connect(mapStateToProps)(CustomerProductsContainer));
