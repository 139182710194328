import { TOGGLE_POPUP } from '../popupConstants';
const popupReducerDefaultState = {
    close: true,
    popupType: '',
};
export default (state = popupReducerDefaultState, action) => {
    const { close, popupType, type } = action;
    switch (type) {
        case TOGGLE_POPUP:
            return Object.assign(Object.assign({}, state), { close,
                popupType });
        default:
            return state;
    }
};
