import React from 'react';
import PropTypes from 'prop-types';
import NOB from '../../../../constants/language-strings';
const ListItem = ({ passed, description }) => (React.createElement("div", { className: "health__check__list__item" },
    React.createElement("div", { className: "health__check__list__item-description" }, description),
    passed ? (React.createElement("div", { className: "health__check__list__item-status" },
        React.createElement("div", { className: "health__check__list__item-status-ok" }),
        NOB.HEALTH_CHECK.STATUS_OK)) : (React.createElement("div", { className: "health__check__list__item-status" },
        React.createElement("div", { className: "health__check__list__item-status-error" }),
        NOB.HEALTH_CHECK.STATUS_ERROR))));
ListItem.propTypes = {
    passed: PropTypes.bool,
    description: PropTypes.string.isRequired,
};
ListItem.defaultProps = {
    passed: false,
};
export default ListItem;
