import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getNextStatus, NOT_FOUND } from '../constants/status-codes';
import { putAPI } from '../api/apiConfig';
import { STATUS } from '../constants/endpoints';
import { STATUS_CHANGE_REQUEST } from '../modules/Workorder/workOrderConstants';
import * as workOrderAction from '../modules/Workorder/actions/workOrder';
import { workorderServicesRequest } from '../modules/WorkLog/actions/workOrderServiceActions';
import NOB from '../constants/language-strings';
function* callChangeStatus({ currentStatus }) {
    const currentWorkOrder = Object.assign({}, (yield select(state => state.workOrder.workOrder)));
    const nextStatus = getNextStatus(currentStatus);
    try {
        if (nextStatus === NOT_FOUND) {
            throw new Error('No next status code found');
        }
        const url = STATUS(currentWorkOrder.workOrderId);
        const requestBody = { status: nextStatus };
        const newStatus = yield call(putAPI, url, requestBody);
        yield put(workOrderAction.checkForStatusSkip(newStatus, currentWorkOrder.workOrderId));
        // Update services after workorder has changed status
        yield put(workorderServicesRequest(currentWorkOrder.workOrderId));
    }
    catch (error) {
        const errorMessage = error.message
            ? error.message
            : `${NOB.WORKORDER_BUTTON.ERROR} ${error.response.data.message} ${NOB.WORKORDER_BUTTON.CONTACT_KETIL}`;
        yield put(workOrderAction.errorStatusChange({
            errorMessage,
            workOrder: currentWorkOrder,
        }));
    }
}
export default [takeLatest(STATUS_CHANGE_REQUEST, callChangeStatus)];
