import React from "react";
import Variables from "../styles/variables.scss";
const SVG = props => (React.createElement("svg", { width: "60px", height: "80px", viewBox: "0 0 60 79", version: "1.1", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("title", null, "icon/vedlegg"),
    React.createElement("desc", null, "Created with Sketch."),
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "icon/vedlegg" },
            React.createElement("path", { d: "M40,0 L60,17 L60,74 C60,76.7614237 57.7614237,79 55,79 L6,79 C3.23857625,79 1,76.7614237 1,74 L1,5 C1,2.23857625 3.23857625,5.07265313e-16 6,0 L40,0 Z", id: "Combined-Shape", fill: "#086670" }),
            React.createElement("path", { d: "M40,0 L40,12 C40,14.7614237 42.2385763,17 45,17 L60,17 L60,74 C60,76.7614237 57.7614237,79 55,79 L6,79 C3.23857625,79 1,76.7614237 1,74 L1,5 C1,2.23857625 3.23857625,5.07265313e-16 6,0 L40,0 Z", id: "Combined-Shape", fill: "#339BA7" }),
            React.createElement("text", { id: "PDF", fontFamily: "Metric-Semibold, Metric", fontSize: "20", fontWeight: "500", linespacing: "24", letterSpacing: "0.945", fill: "#F4F4F4", dominanbaseline: "middle", textAnchor: "middle" },
                React.createElement("tspan", { x: "50%", y: "48" }, props.title))))));
export default SVG;
