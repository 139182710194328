import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FRONT_PAGE_PATH } from '../../../constants/paths';
import * as action from '../actions';
import * as verifyAction from '../actions/verifyTokenActions';
import * as workOrderListAction from '../../WorkorderList/actions/workOrderList';
import { useAuth } from 'react-oidc-context';
import { useEffect } from 'react';
const SignOut = ({ emptyWorkOrderList, endDay, failureTokenVerification, history, requestEndDay, requestLogout, }) => {
    const auth = useAuth();
    const isLoggedInWithOldAuth = useSelector(state => state.authentication.isLoggedInWithOldAuth);
    useEffect(() => {
        emptyWorkOrderList();
        if (isLoggedInWithOldAuth) {
            requestLogout();
            failureTokenVerification('Unauthorized');
            if (endDay) {
                requestEndDay();
            }
            history.push(FRONT_PAGE_PATH);
        }
        else {
            auth.signoutRedirect();
            history.push(FRONT_PAGE_PATH);
        }
    }, [isLoggedInWithOldAuth]);
    return null;
};
SignOut.propTypes = {
    endDay: PropTypes.bool.isRequired,
};
SignOut.defaultProps = {
    endDay: false,
};
const mapStateToProps = state => ({
    endDay: state.workOrders.endDay,
});
const mapDispatchToProps = dispatch => ({
    emptyWorkOrderList: () => dispatch(workOrderListAction.emptyWorkOrderList()),
    requestEndDay: () => dispatch(action.requestEndDay()),
    requestLogout: () => dispatch(action.requestLogout()),
    failureTokenVerification: errorMessage => dispatch(verifyAction.failureTokenVerification({ errorMessage })),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignOut));
