import PropTypes from 'prop-types';
import React from 'react';
import NOB from '../../../../constants/language-strings';
import './customerInfo.scss';
import CustomerInfo from './CustomerInfo';
import isPast from '../../../../utils/isPastDue';
const CustomerInfoContainer = ({ address, addressDetails, customer, togglePopup, workOrder }) => {
    const isPastDue = isPast(workOrder.serviceDate);
    return (React.createElement("div", { className: "customer__info__container" },
        React.createElement("div", { className: "customer__info__container--header" },
            React.createElement("div", null,
                React.createElement("span", null, NOB.CUSTOMER_INFO.CUSTOMER_ID),
                "\u00A0",
                React.createElement("span", { className: "customer__info__container--header--id" }, customer.customerId)),
            React.createElement("div", null,
                React.createElement("span", null, NOB.CUSTOMER_INFO.CUSTOMER_TYPE),
                "\u00A0",
                React.createElement("span", { className: "customer__info__container--header--id" }, addressDetails.addressType)),
            React.createElement("div", null,
                React.createElement("span", null, NOB.CUSTOMER_INFO.NETWORK),
                "\u00A0",
                React.createElement("span", { className: "customer__info__container--header--id" }, addressDetails.networkPlatform
                    .map(it => {
                    switch (it.name) {
                        case 'HFC':
                            const twoWay = addressDetails.hfcTwoWayNetwork
                                ? '2-veis (TV og internett)'
                                : '1-veis (kun TV)';
                            return `HFC ${addressDetails.hfcDocsisVersion} ${twoWay}`;
                        default:
                            return it.platformName;
                    }
                })
                    .join(' | ')))),
        React.createElement("div", { className: "customer__info__container__content" },
            React.createElement(CustomerInfo, { address: address, customer: customer, togglePopup: togglePopup, isPastDue: isPastDue }))));
};
CustomerInfoContainer.propTypes = {
    address: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
    customer: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
        .isRequired,
    addressDetails: PropTypes.object,
    workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
    togglePopup: PropTypes.func.isRequired,
};
export default CustomerInfoContainer;
