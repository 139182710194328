import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
const SVG = ({ fill }) => (React.createElement("svg", { width: "18px", height: "18px", viewBox: "0 0 18 18", version: "1.1" },
    React.createElement("title", null, "icon/arbeid"),
    React.createElement("desc", null, "Created with Sketch."),
    React.createElement("defs", null),
    React.createElement("g", { id: "icon/arbeid", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "Group-9-Copy-3", transform: "translate(2.000000, 1.000000)", fill: fill, stroke: "#FFE500", strokeWidth: "0.1" },
            React.createElement("path", { d: "M2.07930538,3.58198902 L3.20488259,2.30471666 C3.25657424,2.25489737 3.2738048,2.18847166 3.2738048,2.12204595 C3.2738048,2.03901381 3.25657424,1.93937524 3.13596038,1.8563431 C2.98088542,1.72349167 1.87813012,0.89317026 1.36121357,0.478009554 C1.36121357,0.478009554 1.58521074,0.162487418 1.92982177,0.0794552774 C2.18828005,0.0130295645 2.39504667,-0.0201832919 2.72242715,0.0130295645 C3.20488259,0.0628488491 3.72179914,0.129274562 5.08301271,1.32493739 C5.25531822,1.5076081 5.39316264,1.75670453 5.41039319,1.97258809 C5.42762374,2.13865238 5.5137765,4.01517876 5.5482376,5.01156446 C5.56546815,5.41011873 5.73777367,5.80867301 6.03069304,6.09098229 L13.5864294,13.7216071 C14.1550376,14.2362403 14.1204042,15.0533976 13.5864294,15.6014098 C13.0522823,16.1494219 12.0673162,16.116043 11.4988803,15.6014098 L3.85964355,7.35307083 C3.80795189,7.28664512 3.72179914,7.23682584 3.65287693,7.20361298 L0,5.94152444 L0.986426157,4.82215665 C0.568642311,4.05997684 0.098260105,3.16752385 0.0267524984,2.88793067 C-0.0909961742,2.36998658 0.218094091,2.00899525 0.218094091,2.00899525 L2.07930538,3.58198902 Z", id: "Combined-Shape" })))));
SVG.propTypes = {
    fill: PropTypes.string,
};
SVG.defaultProps = {
    fill: Variables.blackSecondary,
};
export default SVG;
