import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Modal from 'react-modal';
import AddProductsContainer from '../../containers/addProduct/AddProductsContainer';
import NOB from '../../../../constants/language-strings';
import { NUMBERS_IN_LETTERS_NOB } from '../../../../constants/numbers-in-letters';
import AgreementPrice from './AgreementPrice/AgreementPrice';
import filterCustomerProducts from '../../../../utils/filterCustomerProducts';
import './customerProductList.scss';
import SimpleButton from '../../../../components/Buttons/SimpleButton/SimpleButton';
import Color from '../../../../components/Buttons/Color';
import ListItem from '../../../../components/ListItem/ListItem';
import ProductListElementContainer from '../../containers/CustomerProduct/ProductListElementContainer';
import { QueryClient, QueryClientProvider } from 'react-query';
Modal.setAppElement('#app');
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 0,
            cacheTime: 0,
        },
    },
});
export default function CustomerProductList({ addProductErrorMessage, customerId, agreements, customerProducts, errorMessage, isAdding, }) {
    const [IsVisibleAddProductModal, setIsVisibleAddProductModal] = useState(false);
    const [selectedAgreement, setSelectedAgreement] = useState(0);
    const [showAddProductErrorMessage, setShowAddProductErrorMessage] = useState(false);
    const allCustomerProducts = filterCustomerProducts(customerProducts);
    // If there is a new product, move this to the bottom of list.
    if (allCustomerProducts.find(product => product.newProduct)) {
        const findNewProductIndex = allCustomerProducts.findIndex(product => product.newProduct);
        const newProductItem = allCustomerProducts.splice(findNewProductIndex, 1);
        allCustomerProducts.push(newProductItem[0]);
    }
    const showAddProductButton = !errorMessage && customerProducts && agreements.agreements.length > 0;
    /* list out all products */
    const productList = products => (React.createElement("div", null, products.map(product => (React.createElement(ProductListElementContainer, { product: product, key: product.productId })))));
    /* show productlist with prices and add products button */
    const agreementProductList = agreements.agreements.map(agreement => {
        const productListFilter = allCustomerProducts.filter(singleCustomerProduct => singleCustomerProduct.agreementId === agreement.agreementId);
        const productWithDevices = productListFilter.map(it => it.name);
        const productsInAgreementWithoutDevice = agreement.productNames.filter(it => !productWithDevices.includes(it));
        return (React.createElement("div", { className: "customer__products__list__container", key: agreement.agreementId },
            React.createElement(QueryClientProvider, { client: queryClient },
                React.createElement(AgreementPrice, { customerId: customerId, agreementId: agreement.agreementId, originalPrice: agreement.originalPrice, currentPrice: agreement.currentPrice, isFetchingPrices: agreements.isFetchingPrices })),
            React.createElement("div", { className: "customer__products__container__products-without-devices-list" }, productsInAgreementWithoutDevice.map(it => (React.createElement("p", { key: it }, it)))),
            productList(productListFilter),
            isAdding && React.createElement(ListItem, { isFetching: isAdding }),
            showAddProductButton && (React.createElement("div", { className: "customer__products__container__add-button" },
                (addProductErrorMessage || showAddProductErrorMessage) && (React.createElement("div", { className: "customer__products__container__add-button--error" }, showAddProductErrorMessage
                    ? 'Kan ikke legge til produkter, fikk ikke hentet ut kundens agreements'
                    : addProductErrorMessage)),
                React.createElement(SimpleButton, { onClick: () => {
                        if (agreements.errorMessage) {
                            setShowAddProductErrorMessage(true);
                        }
                        else {
                            setIsVisibleAddProductModal(state => !state);
                            setSelectedAgreement(agreement.agreementId);
                        }
                    }, deactivated: !!agreements.errorMessage, color: Color.BLACK, title: "Legg til produkt" })))));
    });
    return (React.createElement("div", { className: "customer__products__container" },
        agreements.length > 1 && (React.createElement("div", { className: "customer__products__container--heading" },
            React.createElement("span", null, NOB.CUSTOMER_PRODUCTS.CUSTOMER_HAS),
            "\u00A0",
            NUMBERS_IN_LETTERS_NOB[agreements.length],
            "\u00A0",
            React.createElement("span", null, NOB.CUSTOMER_PRODUCTS.AGREEMENTS))),
        agreementProductList.length > 0 ? agreementProductList : productList(allCustomerProducts),
        React.createElement(AddProductsContainer, { agreementId: selectedAgreement, isVisible: IsVisibleAddProductModal, onClose: () => setIsVisibleAddProductModal(state => !state) })));
}
CustomerProductList.propTypes = {
    agreements: PropTypes.objectOf(PropTypes.any).isRequired,
    customerProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
    errorMessage: PropTypes.string,
    isAdding: PropTypes.bool.isRequired,
    customerId: PropTypes.number.isRequired,
    addProductErrorMessage: PropTypes.string.isRequired,
};
CustomerProductList.defaultProps = {
    errorMessage: '',
};
