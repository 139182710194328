import { AGREEMENTS_FAILURE, AGREEMENTS_REQUEST, AGREEMENTS_SUCCESS, } from '../customerProductsConstants';
export const requestAgreements = ({ customerId }) => ({
    type: AGREEMENTS_REQUEST,
    customerId,
});
export const receiveAgreements = ({ agreements }) => ({
    type: AGREEMENTS_SUCCESS,
    agreements,
});
export const errorAgreements = ({ errorMessage }) => ({
    type: AGREEMENTS_FAILURE,
    errorMessage,
});
