import { create } from 'zustand';
export const useSearchStore = create(set => ({
    searchInput: '',
    setSearchInput: value => set({ searchInput: value }),
    searchQuery: '',
    setSearchQuery: value => set({ searchQuery: value }),
    cache: null,
    setCache: value => set({ cache: value }),
    cacheKey: '',
    setCacheKey: value => set({ cacheKey: value }),
    onlyActive: true,
    setSearchOnlyActive: value => set({ onlyActive: value }),
    clearState: () => {
        set({ searchQuery: '', searchInput: '', cache: null, cacheKey: '' });
    },
}));
