import { CATEGORY_MAIN, CATEGORY_SUB } from '../modules/AddServiceCode/addServiceCodeConstants';
export default function setCategoriesDropdown(category, allCategories, main, sub) {
    let values = [];
    const mainCategories = allCategories.map(singleCategory => singleCategory.mainCategories)[0];
    switch (category) {
        case CATEGORY_MAIN: {
            // Assigning main categories
            mainCategories.map(mainCategory => {
                values.push({
                    name: mainCategory.description,
                    value: mainCategory.serviceId,
                });
                return values;
            });
            return values;
        }
        case CATEGORY_SUB: {
            // Categories containing the selected main category
            const subCategories = mainCategories
                .filter(filterSub => filterSub.description === main)
                .map(singleSub => singleSub.subcategories)[0];
            // Assigning sub categories
            subCategories.map(subCategory => {
                values.push({
                    name: subCategory.description,
                    value: subCategory.serviceId,
                });
                return values;
            });
            return values;
        }
        default: {
            // Categories containing the selected main category and the selected sub category
            const errorCategories = allCategories.filter(mainCategory => mainCategory.main === main && mainCategory.sub === sub && mainCategory.error);
            values = errorCategories.map(service => ({
                name: service.error,
                value: service.id,
            }));
            return values;
        }
    }
}
