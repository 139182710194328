import { ZENDESK_POST_REQUEST, ZENDESK_POST_SUCCESS, ZENDESK_POST_ERROR, SAVE_COMMENT, SET_FOLLOW_UP, ZENDESK_FOLLOW_UP_POST_REQUEST, ZENDESK_FOLLOW_UP_POST_SUCCESS, SAVE_FOLLOW_UP_FILE, SAVE_FILE, CLEAR_SAVED_FILES, } from '../workLogConstants';
export const postZendeskRequest = ({ body, status, workOrder }) => ({
    type: ZENDESK_POST_REQUEST,
    body,
    status,
    workOrder,
});
export const postZendeskSuccess = ({ zendesk }) => ({
    type: ZENDESK_POST_SUCCESS,
    zendesk,
});
export const postZendeskError = ({ errorMessage }) => ({
    type: ZENDESK_POST_ERROR,
    errorMessage,
});
export const saveComment = commentBody => ({
    type: SAVE_COMMENT,
    commentBody,
});
export const saveFile = files => ({
    type: SAVE_FILE,
    files,
});
export const saveFollowUpFile = files => ({
    type: SAVE_FOLLOW_UP_FILE,
    files,
});
export const clearSavedFiles = () => ({
    type: CLEAR_SAVED_FILES,
});
export const setFollowUp = ({ allChecked, customerNotHome, followUp }) => ({
    type: SET_FOLLOW_UP,
    allChecked,
    customerNotHome,
    followUp,
});
export const postZendeskFollowUpRequest = body => ({
    type: ZENDESK_FOLLOW_UP_POST_REQUEST,
    body,
});
export const postZendeskFollowUpSuccess = workOrderId => ({
    type: ZENDESK_FOLLOW_UP_POST_SUCCESS,
    workOrderId,
});
export const postZendeskFollowUpError = ({ errorMessage }) => ({
    type: ZENDESK_POST_ERROR,
    errorMessage,
});
