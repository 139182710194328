import { PRODUCT_LIST_REQUEST, PRODUCT_LIST_SUCCESS, PRODUCT_LIST_FAILURE, } from '../workOrderListConstants';
export const requestProductList = () => ({
    type: PRODUCT_LIST_REQUEST,
});
export const receiveProductList = productList => ({
    type: PRODUCT_LIST_SUCCESS,
    productList,
});
export const errorProductList = ({ errorMessage }) => ({
    type: PRODUCT_LIST_FAILURE,
    errorMessage,
});
