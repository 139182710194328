import { ZENDESK_POST_REQUEST, ZENDESK_POST_SUCCESS, ZENDESK_POST_ERROR, SAVE_FILE, SAVE_COMMENT, SET_FOLLOW_UP, ZENDESK_FOLLOW_UP_POST_REQUEST, ZENDESK_FOLLOW_UP_POST_ERROR, ZENDESK_FOLLOW_UP_POST_SUCCESS, SAVE_FOLLOW_UP_FILE, CLEAR_SAVED_FILES, } from '../workLogConstants';
const zendeskReducerDefaultState = {
    commentBody: '',
    files: [],
    isFetching: false,
    errorMessage: '',
    customerNotHome: false,
    allChecked: false,
    followUp: false,
    followUpForm: {
        created: false,
        isFetching: false,
        errorMessage: '',
        files: [],
    },
};
export default (state = zendeskReducerDefaultState, action) => {
    switch (action.type) {
        // Regular zendesk
        case ZENDESK_POST_REQUEST:
            return Object.assign(Object.assign({}, state), { isFetching: true });
        case ZENDESK_POST_SUCCESS:
            return Object.assign(Object.assign({}, state), { isFetching: false });
        case ZENDESK_POST_ERROR:
            return Object.assign(Object.assign({}, state), { isFetching: false, errorMessage: action.errorMessage });
        // Zendesk follow up
        case ZENDESK_FOLLOW_UP_POST_REQUEST:
            return Object.assign(Object.assign({}, state), { followUpForm: Object.assign(Object.assign({}, state.followUpForm), { isFetching: true }) });
        case ZENDESK_FOLLOW_UP_POST_SUCCESS:
            return Object.assign(Object.assign({}, state), { followUpForm: Object.assign(Object.assign({}, state.followUpForm), { isFetching: false, created: true, workOrderId: action.workOrderId }) });
        case ZENDESK_FOLLOW_UP_POST_ERROR:
            return Object.assign(Object.assign({}, state), { followUpForm: Object.assign(Object.assign({}, state.followUpForm), { isFetching: false, errorMessage: action.errorMessage }) });
        case SAVE_FOLLOW_UP_FILE:
            return Object.assign(Object.assign({}, state), { followUpForm: Object.assign(Object.assign({}, state.followUpForm), { files: action.files }) });
        // Other
        case SAVE_FILE:
            return Object.assign(Object.assign({}, state), { files: action.files });
        case SAVE_COMMENT:
            return Object.assign(Object.assign({}, state), { commentBody: action.commentBody, file: action.file, fileName: action.fileName });
        case SET_FOLLOW_UP:
            return Object.assign(Object.assign({}, state), { customerNotHome: action.customerNotHome, allChecked: action.allChecked, followUp: action.followUp });
        case CLEAR_SAVED_FILES:
            return Object.assign(Object.assign({}, state), { files: [] });
        default:
            return state;
    }
};
