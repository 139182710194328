// Activate product
export const ACTIVATE_PRODUCT_FAILURE = 'ACTIVATE_PRODUCT_FAILURE';
export const ACTIVATE_PRODUCT_REMOVE_ERROR = 'ACTIVATE_PRODUCT_REMOVE_ERROR';
export const ACTIVATE_PRODUCT_REQUEST = 'ACTIVATE_PRODUCT_REQUEST';
export const ACTIVATE_PRODUCT_SUCCESS = 'ACTIVATE_PRODUCT_SUCCESS';
export const ACTIVATE_PRODUCT_CLEAR = 'ACTIVATE_PRODUCT_CLEAR';
export const DPAD_DASH = 'dpad-';
export const SMOOTH = 'smooth';
// Add product
export const ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE';
export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
// Agreements
export const AGREEMENTS_FAILURE = 'AGREEMENTS_FAILURE';
export const AGREEMENTS_REQUEST = 'AGREEMENTS_REQUEST';
export const AGREEMENTS_SUCCESS = 'AGREEMENTS_SUCCESS';
// Agreement price
export const AGREEMENTS_PRICE_FAILURE = 'AGREEMENTS_PRICE_FAILURE';
export const AGREEMENTS_PRICE_REQUEST = 'AGREEMENTS_PRICE_REQUEST';
export const AGREEMENTS_PRICE_SUCCESS = 'AGREEMENTS_PRICE_SUCCESS';
// Available products
export const AVAILABLE_PRODUCTS_ERROR = 'AVAILABLE_PRODUCTS_ERROR';
export const AVAILABLE_PRODUCTS_REQUEST = 'AVAILABLE_PRODUCTS_REQUEST';
export const AVAILABLE_PRODUCTS_SUCCESS = 'AVAILABLE_PRODUCTS_SUCCESS';
export const AVAILABLE_PRODUCTS_CLEAR_ERROR = 'AVAILABLE_PRODUCTS_CLEAR_ERROR';
// Change product
export const CHANGE_PRODUCT_FAILURE = 'CHANGE_PRODUCT_FAILURE';
export const CHANGE_PRODUCT_REQUEST = 'CHANGE_PRODUCT_REQUEST';
export const CHANGE_PRODUCT_SUCCESS = 'CHANGE_PRODUCT_SUCCESS';
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
// Customer product
export const CUSTOMER_PRODUCTS_REQUEST = 'CUSTOMER_PRODUCTS_REQUEST';
export const CUSTOMER_PRODUCTS_SUCCESS = 'CUSTOMER_PRODUCTS_SUCCESS';
export const CUSTOMER_PRODUCTS_FAILURE = 'CUSTOMER_PRODUCTS_FAILURE';
export const CUSTOMER_PRODUCTS_UPDATE = 'CUSTOMER_PRODUCTS_UPDATE';
export const CUSTOMER_PRODUCTS_REQUEST_BACKGROUND = 'CUSTOMER_PRODUCTS_REQUEST_BACKGROUND';
export const UPDATE_SINGLE_PRODUCT = 'UPDATE_SINGLE_PRODUCT';
// Link product
export const LINK_PRODUCT_REQUEST = 'LINK_PRODUCT_REQUEST';
export const LINK_PRODUCT_SUCCESS = 'LINK_PRODUCT_SUCCESS';
export const LINK_PRODUCT_FAILURE = 'LINK_PRODUCT_FAILURE';
export const LINK_SINGLE_PRODUCT_REQUEST = 'LINK_SINGLE_PRODUCT_REQUEST';
// Remove customer product
export const TO_BE_REMOVED = 'TO_BE_REMOVED';
export const REMOVE_PRODUCT_REQUEST = 'REMOVE_PRODUCT_REQUEST';
export const REMOVE_PRODUCT_SUCCESS = 'REMOVE_PRODUCT_SUCCESS';
export const REMOVE_PRODUCT_FAILURE = 'REMOVE_PRODUCT_FAILURE';
export const CLEAR_REMOVE_ERROR_MESSAGE = 'CLEAR_REMOVE_ERROR_MESSAGE';
export const ENTER = 'Enter';
