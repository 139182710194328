import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/agreementsActions';
import { AGREEMENTS, AGREEMENT_PRICE } from '../../../constants/endpoints';
import { AGREEMENTS_REQUEST } from '../customerProductsConstants';
import { getAPI } from '../../../api/apiConfig';
function* callAgreements({ customerId }) {
    try {
        const agreementsURL = AGREEMENTS(customerId);
        const agreementsResult = yield call(getAPI, agreementsURL);
        const agreementPriceUrl = AGREEMENT_PRICE(customerId);
        const priceResult = yield call(getAPI, agreementPriceUrl);
        const agreements = agreementsResult.map(agreement => {
            const price = priceResult.find(priceAgreement => agreement.agreementId === priceAgreement.agreementId);
            return {
                agreementId: agreement.agreementId,
                productNames: agreement.productNames,
                originalPrice: price.agreementPrice,
                currentPrice: price.agreementPrice,
            };
        });
        yield put(actions.receiveAgreements({ agreements }));
    }
    catch (error) {
        yield put(actions.errorAgreements({
            errorMessage: `Could not receive agreements. ${error.message}`,
        }));
    }
}
export default [takeLatest(AGREEMENTS_REQUEST, callAgreements)];
