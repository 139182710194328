import React from "react";
import Variables from "../styles/variables.scss";
const SVG = () => (React.createElement("svg", { width: "42px", height: "42px", viewBox: "0 0 42 42", version: "1.1" },
    React.createElement("title", null, "icon/minske"),
    React.createElement("desc", null, "Created with Sketch."),
    React.createElement("defs", null),
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd", strokeLinecap: "round", strokeLinejoin: "round" },
        React.createElement("g", { id: "icon/minske", stroke: Variables.iconBlue },
            React.createElement("g", { id: "Group" },
                React.createElement("g", { id: "Arrow/Arrow-left", transform: "translate(28.435029, 13.435029) rotate(-45.000000) translate(-28.435029, -13.435029) translate(18.435029, 4.435029)" },
                    React.createElement("g", { id: "Group", transform: "translate(10.000000, 9.000000) scale(-1, 1) translate(-10.000000, -9.000000) translate(1.000000, 1.000000)" },
                        React.createElement("polyline", { id: "Path-2", strokeWidth: "2", transform: "translate(14.000000, 8.000000) scale(-1, 1) rotate(90.000000) translate(-14.000000, -8.000000) ", points: "6 4 14 12 22 4" }),
                        React.createElement("path", { d: "M17,8 L0,8", id: "Line", strokeWidth: "2" }))),
                React.createElement("g", { id: "Arrow/Arrow-right", transform: "translate(13.435029, 28.435029) rotate(-45.000000) translate(-13.435029, -28.435029) translate(3.435029, 19.435029)" },
                    React.createElement("g", { id: "Group", transform: "translate(1.000000, 1.000000)" },
                        React.createElement("polyline", { id: "Path-2", strokeWidth: "2", transform: "translate(14.000000, 8.000000) scale(-1, 1) rotate(90.000000) translate(-14.000000, -8.000000) ", points: "6 4 14 12 22 4" }),
                        React.createElement("path", { d: "M17,8 L0,8", id: "Line", strokeWidth: "2" }))))))));
export default SVG;
