import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/productList';
import { getAPI } from '../../../api/apiConfig';
import { PRODUCT_LIST } from '../../../constants/endpoints';
import { PRODUCT_LIST_REQUEST } from '../workOrderListConstants';
function* callProductList() {
    try {
        const result = yield call(getAPI, PRODUCT_LIST);
        yield put(actions.receiveProductList(result));
    }
    catch (error) {
        yield put(actions.errorProductList({
            errorMessage: `Could not receive product list. ${error.message}`,
        }));
    }
}
export default [takeLatest(PRODUCT_LIST_REQUEST, callProductList)];
