import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
import "./feedback.scss";
const SVG = ({ bodyFill, closedMouthFill, darkerStroke, eyeFill, popcornFill, popcornHolderFill, }) => (React.createElement("svg", { overflow: "visible", width: "117", height: "60", "data-name": "Layer 1", viewBox: "0 0 117.07 60.26" },
    React.createElement("path", { fill: bodyFill, stroke: darkerStroke, strokeMiterlimit: "10", strokeWidth: "2.5", d: "M82.92 48.25h-63.1a3 3 0 0 1-3-3v-41a3.08 3.08 0 0 1 3-3h63.1a3.08 3.08 0 0 1 3 3v41a3 3 0 0 1-3 3z" }),
    React.createElement("path", { fill: "none", stroke: darkerStroke, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2.5", d: "M41.52 52.35h19.7" }),
    React.createElement("path", { className: "eyes", fill: eyeFill, d: "M37.52 17.05a1.8 1.8 0 1 1 3.6 0 1.8 1.8 0 0 1-3.6 0zm21.9 0a1.8 1.8 0 1 1 3.6 0 1.8 1.8 0 0 1-3.6 0z" }),
    React.createElement("path", { className: "closed-mouth", fill: "none", stroke: closedMouthFill, strokeLinecap: "round", strokeWidth: "2.5", d: "M59.32 25.15a12 12 0 0 1-17 0" }),
    React.createElement("path", { fill: bodyFill, stroke: darkerStroke, strokeMiterlimit: "10", strokeWidth: "2.5", d: "M15.22 32.45s-3.2 2.9-5.6.1 1.3-8.8 4.8-6.2c0 0 2.1-3.8 4.5-2.7 2.6 1.2 2.5 3 1.3 5.1 0 0 2 2.9.4 5.1-2.5 3.2-5.1.8-5.4-1.4z" }),
    React.createElement("path", { fill: bodyFill, stroke: darkerStroke, strokeMiterlimit: "10", strokeWidth: "2.5", d: "M9.92 27.55s2.6 3.5-.5 5.6-8.6-2.3-5.7-5.5c0 0-3.6-2.5-2.1-4.8 1.5-2.5 3.2-2.1 5.2-.7 0 0 3.1-1.7 5.1.2 2.9 2.8.3 5.2-2 5.2z" }),
    React.createElement("path", { fill: popcornHolderFill, stroke: darkerStroke, strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2.5", d: "M29.42 53.75l-16 5.2a1 1 0 0 1-1.3-.7l-8.4-23.4a1.08 1.08 0 0 1 .7-1.4l17.6-5.6a1 1 0 0 1 1.3.7l6.8 23.9a1 1 0 0 1-.7 1.3z" }),
    React.createElement("path", { fill: bodyFill, stroke: darkerStroke, strokeMiterlimit: "10", strokeWidth: "2.5", d: "M4.92 41.25c1.3-.2 3.5.7 4.3 1.1a10.62 10.62 0 0 1 4.8 4.1 3.14 3.14 0 0 1 .5 2.1 2.38 2.38 0 0 1-.6 1.1 6.77 6.77 0 0 1-6.1 2.5 2.83 2.83 0 0 1-1.6-.6 3.7 3.7 0 0 1-1.2-2.2 25.8 25.8 0 0 1-1.3-6 2.54 2.54 0 0 1 .4-1.7 2 2 0 0 1 .8-.4zm80.8-14.8s13.3 3.7 22.7 3.2" }),
    React.createElement("path", { className: "arm", fill: bodyFill, stroke: darkerStroke, strokeMiterlimit: "10", strokeWidth: "2.5", d: "M115.52 29.85c-.9 1-4 1.5-4.9 1.7a11.26 11.26 0 0 1-6.3-.4 3 3 0 0 1-1.7-1.4 2.54 2.54 0 0 1-.2-1.2c.1-2.3 1.4-3.5 3.3-4.7a3.32 3.32 0 0 1 1.6-.5 4.3 4.3 0 0 1 2.3 1c1.7 1.2 4.1 1.4 5.4 3a2.57 2.57 0 0 1 .7 1.6c.2.3.1.6-.2.9z" }),
    React.createElement("path", { className: "popcorn", fill: popcornFill, stroke: darkerStroke, strokeMiterlimit: "10", strokeWidth: "2.5", d: "M111.02 24.45s.2 4.3-3.5 4.3-5.9-6.7-1.6-7.7c0 0-1.6-4 .9-5.2s3.9 0 4.7 2.3c0 0 3.5.3 4.1 3 .9 4-2.7 4.5-4.6 3.3z" }),
    React.createElement("circle", { className: "open-mouth", cx: "50.82", cy: "26", r: "6.2", fill: eyeFill })));
SVG.propTypes = {
    bodyFill: PropTypes.string,
    closedMouthFill: PropTypes.string,
    darkerStroke: PropTypes.string,
    eyeFill: PropTypes.string,
    popcornFill: PropTypes.string,
    popcornHolderFill: PropTypes.string,
};
SVG.defaultProps = {
    bodyFill: Variables.whiteSecondary,
    closedMouthFill: Variables.feedbackDarkGray,
    darkerStroke: Variables.feedbackDarkerGray,
    eyeFill: Variables.blackSecondary,
    popcornFill: Variables.whiteSecondary,
    popcornHolderFill: Variables.yellowOnBlack,
};
export default SVG;
