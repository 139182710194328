import React from 'react';
import PropTypes from 'prop-types';
import './fetchingSpinnerWithOverlay.scss';
import Overlay from '../Overlay/Overlay';
import FetchingSpinner from '../../utils/FetchingSpinner';
import { iconBlue } from '../../styles/variables.scss';
function FetchingSpinnerWithOverlay({ color, size }) {
    return (React.createElement(Overlay, null,
        React.createElement("div", { className: "fetching-spinner-with-overlay-container" },
            React.createElement(FetchingSpinner, { color: color, size: size }))));
}
FetchingSpinnerWithOverlay.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
};
FetchingSpinnerWithOverlay.defaultProps = {
    color: iconBlue,
    size: 40,
};
export default FetchingSpinnerWithOverlay;
