import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import './slideInModal.scss';
import IconButton from '../Buttons/IconButton/IconButton';
import { CROSS_LARGE } from '../../constants/iconNames';
import ComponentHeader from '../ComponentHeader/ComponentHeader';
import FetchingSpinner from '../../utils/FetchingSpinner';
function SlideInModal({ children, onClose, cleanUp, display, mainHeading, subHeading, leftIcon, isFetching, fetchingSpinnerColor, onBackClick, }) {
    useEffect(() => {
        if (display)
            document.body.classList.add('popup-open');
        return function cleanup() {
            document.body.classList.remove('popup-open');
            cleanUp();
        };
    }, [display]);
    const closeIcon = React.createElement(IconButton, { onClick: onClose, icon: CROSS_LARGE, className: "fullscreen_icon" });
    return ReactDOM.createPortal(React.createElement("div", { className: display ? 'overlay' : null },
        React.createElement(CSSTransition, { className: "appear fullscreen_popup_container", timeout: 450, in: display, unmountOnExit: true },
            React.createElement("div", { className: "fullscreen_popup_container" },
                React.createElement(ComponentHeader, { rightIcon: closeIcon, mainHeading: mainHeading, subHeading: subHeading, leftIcon: leftIcon, onClick: onBackClick }),
                React.createElement("div", { className: "fullscreen_popup_content" },
                    isFetching && React.createElement(FetchingSpinner, { color: fetchingSpinnerColor }),
                    display && !isFetching && children)))), document.getElementById('modal-root'));
}
SlideInModal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    cleanUp: PropTypes.func,
    display: PropTypes.bool.isRequired,
    mainHeading: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string]),
    subHeading: PropTypes.string,
    leftIcon: PropTypes.objectOf(PropTypes.any),
    isFetching: PropTypes.bool,
    fetchingSpinnerColor: PropTypes.string,
};
SlideInModal.defaultProps = {
    mainHeading: '',
    subHeading: '',
    leftIcon: null,
    isFetching: false,
    cleanUp: () => { },
};
export default SlideInModal;
