import { call, put, takeLatest } from 'redux-saga/effects';
import JWT from 'jsonwebtoken';
import { EXTERNAL_INSTALLER, TECHNICIAN, VERIFY_TOKEN_REQUEST, START_DAY_REQUEST, } from '../constants';
import * as action from '../actions/verifyTokenActions';
function verifyToken({ token }) {
    const decodedToken = JWT.decode(token);
    if (decodedToken) {
        const expDate = new Date(0).setUTCSeconds(decodedToken.exp);
        const dateNow = new Date().getTime();
        const role = decodedToken.roles.includes(TECHNICIAN) ? TECHNICIAN : EXTERNAL_INSTALLER;
        return {
            isExpired: dateNow > expDate,
            token,
            role,
        };
    }
    return null;
}
function* callVerifyToken(token) {
    const result = yield call(verifyToken, token);
    if (!result.isExpired) {
        yield put(action.successTokenVerification({
            token: result.token,
            role: result.role,
        }));
        yield put({ type: START_DAY_REQUEST });
    }
    else {
        yield put(action.failureTokenVerification({
            errorMessage: 'Unauthorized',
        }));
    }
}
export default [takeLatest(VERIFY_TOKEN_REQUEST, callVerifyToken)];
