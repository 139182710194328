import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import { DEV_AUTHORIZATION } from '../../../constants/endpoints';
import { postAPI } from '../../../api/apiConfig';
import { requestTokenVerification } from '../actions/verifyTokenActions';
import { DEV_SIGN_IN_REQUEST } from '../constants';
import { TOKEN, USERNAME } from '../../../constants/localStorage';
export function* callLoginUser({ credentials }) {
    const body = {
        password: credentials.password,
        username: credentials.username,
        technician: credentials.technician,
    };
    try {
        const token = yield call(postAPI, DEV_AUTHORIZATION, body);
        yield put(actions.receiveSignIn({ token: token.token }));
        window.localStorage.setItem(USERNAME, credentials.username);
        yield put(requestTokenVerification({
            token: localStorage.getItem(TOKEN),
        }));
    }
    catch (error) {
        yield put(actions.errorSignIn({
            errorMessage: `No customer found. ${error.message}`,
        }));
    }
}
export default [takeLatest(DEV_SIGN_IN_REQUEST, callLoginUser)];
