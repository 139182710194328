import React from 'react';
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
const SVG = ({ fill }) => (React.createElement("svg", { width: "24", height: "24", fill: "none", viewBox: "0 0 24 24" },
    React.createElement("title", null, "icon/archive"),
    React.createElement("path", { stroke: fill, strokeLinejoin: "round", strokeWidth: "2", d: "M10 12v1h4v-1m4 7H6a1 1 0 0 1-1-1V9h14v9a1 1 0 0 1-1 1ZM4 5h16a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z" })));
SVG.propTypes = {
    fill: PropTypes.string,
};
SVG.defaultProps = {
    fill: Variables.iconBlue,
};
export default SVG;
