import { ADD_PRODUCT_REQUEST, ADD_PRODUCT_SUCCESS, ADD_PRODUCT_FAILURE, AVAILABLE_PRODUCTS_REQUEST, AVAILABLE_PRODUCTS_SUCCESS, AVAILABLE_PRODUCTS_ERROR, AVAILABLE_PRODUCTS_CLEAR_ERROR, } from '../customerProductsConstants';
const addProductReducerDefaultState = {
    addProductRequest: 0,
    availableProducts: [],
    errorMessage: '',
    isAdding: false,
    isFetching: false,
    availableProductsError: '',
};
export default (state = addProductReducerDefaultState, action) => {
    const { agreementId, availableProducts, errorMessage, type } = action;
    switch (type) {
        case ADD_PRODUCT_REQUEST:
            return Object.assign(Object.assign({}, state), { addProductRequest: agreementId, errorMessage: '', isAdding: true });
        case ADD_PRODUCT_SUCCESS:
            return Object.assign(Object.assign({}, state), { addProductRequest: 0, errorMessage: '', isAdding: false });
        case ADD_PRODUCT_FAILURE:
            return Object.assign(Object.assign({}, state), { isAdding: false, addProductRequest: 0, errorMessage });
        case AVAILABLE_PRODUCTS_REQUEST:
            return Object.assign(Object.assign({}, state), { isFetching: true });
        case AVAILABLE_PRODUCTS_SUCCESS:
            return Object.assign(Object.assign({}, state), { availableProducts, isFetching: false });
        case AVAILABLE_PRODUCTS_ERROR:
            return Object.assign(Object.assign({}, state), { isFetching: false, availableProductsError: errorMessage });
        case AVAILABLE_PRODUCTS_CLEAR_ERROR:
            return Object.assign(Object.assign({}, state), { availableProductsError: '' });
        default:
            return state;
    }
};
