import { call, put, select, takeEvery, race, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as actions from '../actions/workOrderList';
import { getAPI } from '../../../api/apiConfig';
import { TECHNICIAN } from '../../Authentication/constants';
import { WORKORDER_LIST_REQUEST, PREVIOUS_WORKORDER_LIST_REQUEST, WORKORDER_LIST_CANCEL, } from '../workOrderListConstants';
import { WORKORDERS_EXTERNAL_INSTALLER, WORKORDERS_TECHNICIAN, WORKORDER_PREVIOUS, } from '../../../constants/endpoints';
import NOB from '../../../constants/language-strings';
function* callWorkOrderList() {
    yield put(actions.workOrderListCancel());
    yield race({
        task: call(callWorkOrderListWorker),
        cancel: take(WORKORDER_LIST_CANCEL),
    });
}
function* callWorkOrderListWorker() {
    try {
        while (true) {
            const role = yield select(state => state.authentication.role);
            const URL = role === TECHNICIAN ? WORKORDERS_TECHNICIAN : WORKORDERS_EXTERNAL_INSTALLER;
            const workOrders = yield call(getAPI, URL);
            yield put(actions.receiveWorkOrderList({ workOrders }));
            if (workOrders.length)
                yield put(actions.workOrderListCancel());
            yield call(delay, 8000);
        }
    }
    catch (error) {
        if (error.response.status === 401) {
            yield put(actions.errorWorkOrderList(NOB.NO_WORK_ORDERS.NO_ACCESS));
        }
        else
            yield put(actions.errorWorkOrderList(NOB.NO_WORK_ORDERS.FAILED));
    }
}
function* callPreviousWorkOrders({ pageNumber }) {
    try {
        const pageSelector = pageNumber ? `?page=${pageNumber}` : '';
        const url = WORKORDER_PREVIOUS + pageSelector;
        const previousWorkOrders = yield call(getAPI, url);
        yield put(actions.receivePrevWorkOrderList(previousWorkOrders));
    }
    catch (e) {
        yield put(actions.errorPrevWorkOrderList(e.message));
    }
}
export default [
    takeEvery(WORKORDER_LIST_REQUEST, callWorkOrderList),
    takeEvery(PREVIOUS_WORKORDER_LIST_REQUEST, callPreviousWorkOrders),
];
