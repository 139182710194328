import { REMOVE_PRODUCT_FAILURE, REMOVE_PRODUCT_REQUEST, REMOVE_PRODUCT_SUCCESS, TO_BE_REMOVED, CLEAR_REMOVE_ERROR_MESSAGE, } from '../customerProductsConstants';
export const toBeRemoved = ({ agreementDetailId }) => ({
    type: TO_BE_REMOVED,
    agreementDetailId,
});
export const requestRemoveProduct = ({ agreementDetailId, customerId }) => ({
    type: REMOVE_PRODUCT_REQUEST,
    agreementDetailId,
    customerId,
});
export const receiveRemoveProduct = removedProductId => ({
    type: REMOVE_PRODUCT_SUCCESS,
    removedProductId,
});
export const errorRemoveProduct = ({ errorMessage }) => ({
    type: REMOVE_PRODUCT_FAILURE,
    errorMessage,
});
export const clearRemoveErrorMessage = () => ({
    type: CLEAR_REMOVE_ERROR_MESSAGE,
});
