import { all } from 'redux-saga/effects';
import signInSaga from '../modules/Authentication/sagas/signInSaga';
import devSignInSaga from '../modules/Authentication/sagas/devSignInSaga';
import verifyTokenSaga from '../modules/Authentication/sagas/verifyTokenSaga';
import workOrders from '../modules/WorkorderList/sagas/workOrderList';
import productList from '../modules/WorkorderList/sagas/productList';
import workOrder from '../modules/Workorder/sagas/workOrder/workOrder';
import customerProducts from '../modules/CustomerProducts/sagas/customerProducts';
import changeProduct from '../modules/CustomerProducts/sagas/changeProductSaga';
import checkForStatusSkip from '../modules/Workorder/sagas/statusChange/checkForStatusSkip';
import mapIdSaga from '../modules/Map/sagas/mapIdSaga';
import mapTokenSaga from '../modules/Map/sagas/mapTokenSaga';
import getHealthCheckSaga from '../modules/HealthCheck/sagas/getHealthCheck';
import updateHealthCheckSaga from '../modules/HealthCheck/sagas/updateHealthCheck';
import removeServiceSaga from '../modules/WorkLog/sagas/services/removeServiceSaga';
import updateServiceSaga from '../modules/WorkLog/sagas/services/updateServiceSaga';
import findServicesSaga from '../modules/WorkLog/sagas/services/findServicesSaga';
import zendeskSaga from '../modules/WorkLog/sagas/complete/zendeskSaga';
import completeStatusSaga from '../modules/WorkLog/sagas/complete/completeStatusSaga';
import addressHistorySaga from '../modules/AddressHistory/sagas/addressHistorySaga';
import getProductsSaga from '../modules/CustomerProducts/sagas/getProductsSaga';
import addProductSaga from '../modules/CustomerProducts/sagas/addProductSaga';
import devicePollingMacSaga from '../modules/HealthCheck/sagas/devicePollingMacSaga';
import devicePollingCustomer from '../modules/HealthCheck/sagas/getDevicePollingCustomer';
import iccCheckSaga from '../modules/HealthCheck/sagas/getIccCheck';
import agreementsSaga from '../modules/CustomerProducts/sagas/agreementsSaga';
import removeProductSaga from '../modules/CustomerProducts/sagas/removeProductSaga';
import updateDevicePollingMacSaga from '../modules/HealthCheck/sagas/updateDevicePollingMacSaga';
import changeStatusSaga from '../rootSagas/changeStatusSaga';
import startDaySaga from '../modules/Authentication/sagas/startDaySaga';
import endDaySaga from '../modules/Authentication/sagas/endDaySaga';
import agreementPriceSaga from '../modules/CustomerProducts/sagas/agreementPriceSaga';
import findServiceTypesSaga from '../modules/CreateNewWorkOrder/sagas/findServiceTypesSaga';
import linkProductSaga from '../modules/CustomerProducts/sagas/linkProductSaga';
import workorderServicesSaga from '../modules/WorkLog/sagas/services/workorderServices';
import followUpFormsSaga from '../modules/FollowUpForms/sagas/followUpFormsSaga';
import workOrderHistorySaga from '../modules/WorkOrderHistory/sagas/workOrderHistorySaga';
export default function* rootSaga() {
    yield all([
        ...signInSaga,
        ...devSignInSaga,
        ...verifyTokenSaga,
        ...workOrders,
        ...customerProducts,
        ...mapIdSaga,
        ...mapTokenSaga,
        ...workOrder,
        ...productList,
        ...getHealthCheckSaga,
        ...updateHealthCheckSaga,
        ...changeStatusSaga,
        ...checkForStatusSkip,
        ...changeProduct,
        ...removeServiceSaga,
        ...updateServiceSaga,
        ...findServicesSaga,
        ...zendeskSaga,
        ...completeStatusSaga,
        ...addressHistorySaga,
        ...getProductsSaga,
        ...addProductSaga,
        ...devicePollingMacSaga,
        ...iccCheckSaga,
        ...devicePollingCustomer,
        ...agreementsSaga,
        ...agreementPriceSaga,
        ...removeProductSaga,
        ...updateDevicePollingMacSaga,
        ...startDaySaga,
        ...endDaySaga,
        ...findServiceTypesSaga,
        ...linkProductSaga,
        ...workorderServicesSaga,
        ...followUpFormsSaga,
        ...workOrderHistorySaga,
    ]);
}
