import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ERROR, MAIN, SUB, SERVICE_IDS } from '../../../constants/elements';
import filterServices from '../../../utils/filterServices';
import NOB from '../../../constants/language-strings';
import remove360FromServiceType from '../../../utils/remove360FromServiceType';
import setCategoryDropdown from '../../../utils/setCategoryDropdown';
import subMainErrorCategoryValues from '../../../utils/subMainErrorCategoryValues';
import selectDiagnosticServiceUtil from '../../../utils/selectDiagnosticService';
import { CATEGORY_SUB } from '../addServiceCodeConstants';
import hideSelectedDiagnosticServices from '../../../utils/hideSelectedDiagnosticServices';
import setDiagnosisCategoryDropdown from '../../../utils/setDiagnosisCategoryDropdown';
import AddServiceCategoryPicker from '../components/AddServiceCategoryPicker';
import '../addService.scss';
import * as updateServiceAction from '../../WorkLog/actions/updateServiceActions';
import { TECHNICIAN } from '../../Authentication/constants';
import SlideInModal from '../../../components/SlideInModal/SlideInModal';
import SimpleButton from '../../../components/Buttons/SimpleButton/SimpleButton';
import Color from '../../../components/Buttons/Color';
import AddServiceListSearch from '../components/AddServiceListSearch';
import renderService from '../components/content/RenderService';
import objectNameComparator from '../../../utils/objectNameComparator';
class AddServiceCodeContainer extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            showMainCategories: false,
            showSubCategories: false,
            showMainErrors: false,
            mainCategory: '',
            subCategory: '',
            mainError: '',
            isModalOpen: false,
        };
        this.toggleIsModalOpen = () => {
            const { isModalOpen } = this.state;
            this.resetCategories();
            this.setState({
                isModalOpen: !isModalOpen,
            });
        };
        this.toggleCategoryDropdown = type => {
            const { showMainCategories, showMainErrors, showSubCategories } = this.state;
            this.setState({
                showMainCategories: type === MAIN ? !showMainCategories : false,
                showSubCategories: type === SUB ? !showSubCategories : false,
                showMainErrors: type === ERROR ? !showMainErrors : false,
            });
        };
        this.openCategoryDropdown = () => {
            this.setState({
                showMainCategories: false,
            });
        };
        this.openServiceModal = () => {
            this.toggleIsModalOpen();
            this.openCategoryDropdown();
        };
        this.resetCategories = () => {
            this.setState({
                showMainCategories: false,
                showSubCategories: false,
                showMainErrors: false,
                mainCategory: '',
                subCategory: '',
                mainError: '',
            });
        };
        this.selectedCategory = ({ name }) => {
            const { showMainCategories, showSubCategories, showMainErrors, mainCategory, subCategory, mainError, } = this.state;
            const { subCategoryValue, mainErrorValue } = subMainErrorCategoryValues(showSubCategories, showMainErrors, name, subCategory, mainError);
            this.setState({
                showMainCategories: false,
                showSubCategories: showMainCategories,
                showMainErrors: showSubCategories,
                mainCategory: showMainCategories ? name : mainCategory,
                subCategory: subCategoryValue,
                mainError: mainErrorValue,
            });
        };
        this.filteredServiceTypes = list => {
            const { name } = this.props.serviceType;
            return list.filter(service => remove360FromServiceType(service.serviceType) === name);
        };
        this.handleSelectedDiagnosticService = event => {
            const { diagnosticServices, loadTimeSlots, selectedDiagnosticServices, updateDiagnosticServices, } = this.props;
            const selectedServiceType = this.filteredServiceTypes(diagnosticServices)[0].services.filter(service => service.serviceId === event.value);
            const newSelectedDiagnosticServices = selectedDiagnosticServices.concat(selectDiagnosticServiceUtil(selectedServiceType));
            updateDiagnosticServices(newSelectedDiagnosticServices);
            loadTimeSlots(SERVICE_IDS, newSelectedDiagnosticServices.map(service => service.serviceId));
            this.toggleIsModalOpen();
        };
        this.addServiceCode = service => {
            const { workOrderId } = this.props;
            const serviceId = service.id;
            const quantity = 1;
            const shouldAdd = true;
            this.props.requestUpdateService(workOrderId, serviceId, quantity, shouldAdd);
            this.toggleIsModalOpen();
        };
        this.categories = category => {
            const { mainCategory, subCategory } = this.state;
            const { findServices: { services }, workOrderServices, } = this.props;
            const allCategories = filterServices(services, workOrderServices, true, !this.props.addServiceCode);
            const values = setCategoryDropdown(category, allCategories, mainCategory, subCategory);
            return values.filter(value => value.name);
        };
        this.diagnosisCategories = category => {
            const { mainCategory, subCategory } = this.state;
            const { selectedDiagnosticServices, serviceTypes } = this.props;
            let values = setDiagnosisCategoryDropdown(category, this.filteredServiceTypes(serviceTypes), mainCategory, subCategory);
            if (category === CATEGORY_SUB) {
                values = hideSelectedDiagnosticServices(values, selectedDiagnosticServices, true);
            }
            return values.filter(value => value.name);
        };
    }
    render() {
        const { mainCategory, mainError, showMainCategories, showMainErrors, showSubCategories, subCategory, } = this.state;
        const { addServiceCode, diagnosticServices, role, workOrderServices, findServices: { services }, } = this.props;
        const technician = role === TECHNICIAN;
        const diagnosticServiceList = () => {
            const values = [];
            this.filteredServiceTypes(diagnosticServices).map(diagnosticService => diagnosticService.services.map(service => values.push(service)));
            return hideSelectedDiagnosticServices(values, this.props.selectedDiagnosticServices, false);
        };
        const serviceList = filterServices(services, workOrderServices, false, !this.props.addServiceCode);
        const addServiceProps = addServiceCode
            ? {
                categories: this.categories,
                selectService: this.addServiceCode,
                serviceList,
            }
            : {
                categories: this.diagnosisCategories,
                selectService: this.handleSelectedDiagnosticService,
                serviceList: diagnosticServiceList(),
            };
        const filteredServicesBasedOnSelectedCategory = filterServices(addServiceProps.serviceList, workOrderServices, true, !this.props.addServiceCode)
            .filter(service => (mainCategory === '' || service.main === mainCategory) &&
            (subCategory === '' || service.sub === subCategory) &&
            (mainError === '' || service.error === mainError))
            .sort(objectNameComparator);
        const modalAndButtonText = addServiceCode
            ? NOB.ADD_SERVICE.ADD_NEW_SERVICE
            : NOB.CREATE_NEW_WORK_ORDER.ADD_DIAGNOSIS;
        return (React.createElement("div", { className: "add__service" },
            React.createElement("div", { className: !addServiceCode ? 'add_diagnosis_button' : 'add_service_button', id: "openModal" },
                React.createElement(SimpleButton, { onClick: this.openServiceModal, title: modalAndButtonText, color: Color.BLACK })),
            React.createElement(SlideInModal, { display: this.state.isModalOpen, onClose: this.toggleIsModalOpen, mainHeading: modalAndButtonText, cleanUp: () => { } },
                React.createElement("div", { className: `transparent_background_container 
          ${showMainCategories ||
                        showSubCategories ||
                        (showMainErrors && filteredServicesBasedOnSelectedCategory.length > 1)
                        ? 'open_transparent_background'
                        : ''}` },
                    React.createElement("div", { className: "add__service" },
                        React.createElement(AddServiceCategoryPicker, { categories: addServiceProps.categories, mainCategory: mainCategory, mainError: mainError, resetCategories: this.resetCategories, technician: technician, selectedCategory: this.selectedCategory, showMainCategories: showMainCategories, showMainErrors: showMainErrors, showSubCategories: showSubCategories, subCategory: subCategory, toggleCategoryDropdown: this.toggleCategoryDropdown })),
                    React.createElement("div", { className: "hide_overflow" },
                        React.createElement(AddServiceListSearch, { placeholder: NOB.ADD_SERVICE.SEARCH, onChange: addServiceProps.selectService, options: filteredServicesBasedOnSelectedCategory, renderOption: renderService }))))));
    }
}
AddServiceCodeContainer.propTypes = {
    addServiceCode: PropTypes.bool,
    diagnosticServices: PropTypes.arrayOf(PropTypes.any),
    findServices: PropTypes.objectOf(PropTypes.any).isRequired,
    loadTimeSlots: PropTypes.func,
    requestUpdateService: PropTypes.func,
    role: PropTypes.string.isRequired,
    selectedDiagnosticServices: PropTypes.arrayOf(PropTypes.any),
    serviceType: PropTypes.objectOf(PropTypes.any),
    serviceTypes: PropTypes.arrayOf(PropTypes.any),
    workOrderId: PropTypes.number,
    updateDiagnosticServices: PropTypes.func,
    workOrderServices: PropTypes.arrayOf(PropTypes.any).isRequired,
};
AddServiceCodeContainer.defaultProps = {
    addServiceCode: false,
    diagnosticServices: [],
    loadTimeSlots: null,
    requestUpdateService: null,
    selectedDiagnosticServices: [],
    serviceType: {},
    serviceTypes: [],
    updateDiagnosticServices: null,
    workOrderId: null,
};
const mapStateToProps = state => ({
    findServices: state.findServices,
    role: state.authentication.role,
    workOrderId: state.workOrder.workOrder.workOrderId,
});
const mapDispatchToProps = dispatch => ({
    requestUpdateService: (workOrderId, serviceId, quantity, added) => dispatch(updateServiceAction.requestUpdateService({
        workOrderId,
        serviceId,
        quantity,
        added,
    })),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddServiceCodeContainer);
