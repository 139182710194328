import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../../components/TextInput/TextInput';
import useForm from '../../../utils/customHooks/useForm';
import Checkbox from '../../../components/Checkbox/Checkbox';
import RadioButtonGroup from '../../../components/RadioButtonGroup/RadioButtonGroup';
import SimpleButton from '../../../components/Buttons/SimpleButton/SimpleButton';
import Color from '../../../components/Buttons/Color';
import InfoText from './InfoText';
import NOB from '../../../constants/language-strings';
import { INPUT_TYPES } from '../FollowUpConstans';
import AddFiles from '../../../components/addFiles/addFiles';
function FollowUpForm({ files, form, onSubmit, isSubmitted, saveFiles }) {
    const { values, handleChange, handleSubmit } = useForm(() => {
        onSubmit(values);
    });
    const inputs = form.inputs.map(input => {
        switch (input.type) {
            case INPUT_TYPES.TEXT:
                return (React.createElement(TextInput, { onChange: handleChange, label: input.headline, value: values[input.headline] ? values[input.headline] : '', name: input.headline, placeholder: input.placeholder, key: input.headline, hasError: isSubmitted && !values[input.headline] }));
            case INPUT_TYPES.CHECKBOX: {
                return (React.createElement(Checkbox, { onClick: handleChange, name: input.option, label: input.option, key: input.option, checked: values[input.option] }));
            }
            case INPUT_TYPES.RADIO:
                return (React.createElement(RadioButtonGroup, { title: input.title, values: input.options, chosenValue: values[input.title], onChange: handleChange, hasError: isSubmitted && !values[input.title], key: input.title }));
            case INPUT_TYPES.INFO_TEXT:
                return React.createElement(InfoText, { text: input.headline, key: input.headline });
            default:
                return null;
        }
    });
    return (React.createElement("div", { className: "follow-up-form" },
        inputs,
        React.createElement(AddFiles, { saveFiles: saveFiles, files: files, type: "FollowUp" }),
        React.createElement(SimpleButton, { color: Color.BLACK, onClick: handleSubmit, title: NOB.FOLLOW_UP.CREATE_BUTTON })));
}
FollowUpForm.propTypes = {
    files: PropTypes.arrayOf(PropTypes.any).isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    onSubmit: PropTypes.func.isRequired,
    isSubmitted: PropTypes.bool.isRequired,
    saveFiles: PropTypes.func.isRequired,
};
export default FollowUpForm;
