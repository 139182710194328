import { DEVICE_POLLING_MAC_REQUEST, DEVICE_POLLING_MAC_ERROR, DEVICE_POLLING_MAC_RECEIVE, } from '../constants';
const devicePollingMacDefaultState = {
    devicePollingMac: {},
    isFetching: true,
    errorMessage: '',
    mainHeading: '',
};
export default (state = devicePollingMacDefaultState, action) => {
    const { devicePollingMac, errorMessage, type } = action;
    switch (type) {
        case DEVICE_POLLING_MAC_REQUEST:
            return Object.assign(Object.assign({}, state), { isFetching: true });
        case DEVICE_POLLING_MAC_RECEIVE:
            return Object.assign(Object.assign({}, state), { devicePollingMac, isFetching: false });
        case DEVICE_POLLING_MAC_ERROR:
            return Object.assign(Object.assign({}, state), { isFetching: false, errorMessage });
        default:
            return state;
    }
};
