export const SENT = 'SENT';
export const CANCELLED = 'CANCELLED';
const NEW = '00 – NEW';
const ALLOCATE = '10 – ALLOCATE';
export const COMMITED = '30 – COMMIT';
const NETWORKED = '32 – NETWORKED';
const DELIVERED = '35 – DELIVERED';
const ACCEPTED = '40 – ACCEPTED';
export const ON_SITE_STATUS = '60 – ON SITE';
export const NOT_FOUND = 'NOT FOUND';
export const TRAVELING_STATUS = '50 – TRAVELING';
export const NEARLY_DONE = '65 – NEARLY DONE';
export const COMPLETE = '70 – COMPLETE';
export const COMPLETE_WITHOUT_CODE = 'COMPLETE';
const COMPLETE_WITHOUT_CUSTOMER = '71 – COMPLETE_WITHOUT_CUSTOMER';
export const INCOMPLETE = '80 – INCOMPLETE';
export const INCOMPLETE_WITHOUT_CODE = 'INCOMPLETE';
const INCOMPLETE_WITHOUT_CUSTOMER = '81 – INCOMPLETE_WITHOUT_CUSTOMER';
export const INCOMPLETE_WITHOUT_CUSTOMER_WITHOUT_CODE = 'INCOMPLETE_WITHOUT_CUSTOMER';
const WAITING = '55 – WAITING';
const Status = {
    ALLOCATED: [SENT, NEW, ALLOCATE, COMMITED, NETWORKED, DELIVERED, ACCEPTED],
    TRAVELING: [TRAVELING_STATUS],
    ON_SITE: [ON_SITE_STATUS, NEARLY_DONE],
    COMPLETE_ARRAY: [COMPLETE, COMPLETE_WITHOUT_CUSTOMER],
    INCOMPLETE_ARRAY: [INCOMPLETE, INCOMPLETE_WITHOUT_CUSTOMER],
};
export const doneStatusCodes = [
    CANCELLED,
    COMPLETE,
    COMPLETE_WITHOUT_CODE,
    COMPLETE_WITHOUT_CUSTOMER,
    INCOMPLETE,
    INCOMPLETE_WITHOUT_CODE,
    INCOMPLETE_WITHOUT_CUSTOMER,
    INCOMPLETE_WITHOUT_CUSTOMER_WITHOUT_CODE,
];
export const statusToSkip = [ALLOCATE, COMMITED, NETWORKED, DELIVERED];
export const getNextStatus = currentStatus => {
    switch (currentStatus) {
        case NEW:
            return 'COMMITTED';
        case ALLOCATE:
            return 'COMMITTED';
        case NETWORKED:
            return 'DELIVERED';
        case DELIVERED:
            return 'ACCEPTED';
        case COMMITED:
            return 'ACCEPTED';
        case ACCEPTED:
            return 'TRAVELING';
        case TRAVELING_STATUS:
            return 'ON_SITE';
        case WAITING:
            return 'ON_SITE';
        case SENT:
            return 'ICC_STATUS';
        case ON_SITE_STATUS:
            return 'NEARLY_DONE';
        default:
            return NOT_FOUND;
    }
};
export default Object.freeze(Status);
