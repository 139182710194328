import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FadeLoader } from 'react-spinners';
import QuantityDropdown from './QuantityDropdown';
import ServicecodeContent from './ServicecodeContent';
import Icon from '../../icons';
import { CHECK } from '../../constants/iconNames';
class Service extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            amount: this.props.service.quantity,
            showDropdown: false,
            value: '',
        };
        this.setDropDownValues = max => {
            const values = [];
            for (let i = 1; i <= max; i++) {
                const selected = i === this.state.amount;
                values.push({
                    name: (React.createElement("span", { className: selected ? 'selected' : '', key: i },
                        i,
                        selected && React.createElement(Icon, { name: CHECK }))),
                    value: i,
                });
            }
            return values;
        };
        this.toggleDropdown = () => {
            this.setState({ showDropdown: !this.state.showDropdown });
        };
        this.selected = event => {
            const { requestUpdateService, service: { workOrderServiceId, id }, workOrderId, } = this.props;
            this.setState({
                showDropdown: false,
                amount: event.value,
            });
            requestUpdateService(workOrderId, workOrderServiceId, id, this.state.amount, false);
        };
        this.removeClick = () => {
            const { requestRemoveService, service: { workOrderServiceId }, workOrderId, } = this.props;
            requestRemoveService(workOrderId, workOrderServiceId);
        };
    }
    render() {
        const { max, workOrderServiceIdToBeChanged, service, technician } = this.props;
        const { amount, showDropdown, value } = this.state;
        return (React.createElement("div", { className: "service" },
            service.workOrderServiceId === workOrderServiceIdToBeChanged && (React.createElement("div", { className: "service__spinner" },
                React.createElement(FadeLoader, null))),
            max > 1 && (React.createElement(QuantityDropdown, { dropDownValues: this.setDropDownValues(max), showDropdown: showDropdown, toggleDropdown: this.toggleDropdown, selected: this.selected, value: value, amount: amount })),
            React.createElement(ServicecodeContent, { service: service, amount: amount, noDropdown: max === 1, removeClick: this.removeClick, workOrderServiceIdToBeChanged: workOrderServiceIdToBeChanged, technician: technician })));
    }
}
Service.propTypes = {
    max: PropTypes.number.isRequired,
    workOrderServiceIdToBeChanged: PropTypes.number,
    requestRemoveService: PropTypes.func.isRequired,
    requestUpdateService: PropTypes.func.isRequired,
    service: PropTypes.objectOf(PropTypes.any).isRequired,
    technician: PropTypes.bool.isRequired,
    workOrderId: PropTypes.number.isRequired,
};
Service.defaultProps = {
    workOrderServiceIdToBeChanged: null,
};
export default Service;
