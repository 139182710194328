import { ADD_PRODUCT_REQUEST, ADD_PRODUCT_SUCCESS, ADD_PRODUCT_FAILURE, AVAILABLE_PRODUCTS_REQUEST, AVAILABLE_PRODUCTS_SUCCESS, AVAILABLE_PRODUCTS_ERROR, AVAILABLE_PRODUCTS_CLEAR_ERROR, } from '../customerProductsConstants';
export const requestAvailableProducts = agreementId => ({
    type: AVAILABLE_PRODUCTS_REQUEST,
    agreementId,
});
export const receiveAvailableProducts = availableProducts => ({
    type: AVAILABLE_PRODUCTS_SUCCESS,
    availableProducts,
});
export const errorAvailableProducts = errorMessage => ({
    type: AVAILABLE_PRODUCTS_ERROR,
    errorMessage,
});
export const requestAddProduct = ({ agreementId, customerId, product }) => ({
    type: ADD_PRODUCT_REQUEST,
    agreementId,
    customerId,
    product,
});
export const receiveAddProduct = () => ({
    type: ADD_PRODUCT_SUCCESS,
});
export const errorAddProduct = errorMessage => ({
    type: ADD_PRODUCT_FAILURE,
    errorMessage,
});
export const clearAvailableProductsError = () => ({
    type: AVAILABLE_PRODUCTS_CLEAR_ERROR,
});
