import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions';
import { HEALTH_CHECK } from '../../../constants/endpoints';
import { putAPI } from '../../../api/apiConfig';
import { UPDATE_HEALTH_CHECK_REQUEST } from '../constants';
export function* callUpdateHealthCheck({ customerId, healthCheckIds }) {
    try {
        const url = HEALTH_CHECK(customerId);
        const body = { healthCheckIds };
        yield call(putAPI, url, body);
        yield put(actions.requestHealthCheck({ customerId }));
    }
    catch (error) {
        yield put(actions.errorHealthCheck({
            errorMessage: `Error in health check response. ${error.message}`,
        }));
    }
}
export default [takeLatest(UPDATE_HEALTH_CHECK_REQUEST, callUpdateHealthCheck)];
