import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import SlideInModal from '../../../components/SlideInModal/SlideInModal';
import SimpleDropdown from '../../../components/Drowpdowns/SimpleDropdown';
import SimpleButton from '../../../components/Buttons/SimpleButton/SimpleButton';
import Color from '../../../components/Buttons/Color';
import { useQuery } from 'react-query';
import { http } from '../../../api/apiConfig';
import { GET_DEVICES_FOR_PROVISIONING, SEND_PROVISIONING_COMMAND, } from '../../../constants/endpoints';
import './HitDecoder.scss';
import Popup from '../../../components/Popup/containers/Popup';
function HitDecoderContainer({ customerId, isVisible, onClose }) {
    const { data: dpads } = useQuery({
        queryKey: ['dpads', customerId],
        queryFn: async () => {
            const { data } = await http.get(GET_DEVICES_FOR_PROVISIONING(customerId));
            return data;
        },
        enabled: true,
    });
    const provisioningCommands = [
        { value: 'RESET_PIN', name: 'Nullstill PIN' },
        { value: 'REAUTH_SOFTWARE_ON_DEVICE', name: 'Oppdater programvare' },
        { value: 'REFRESH_DEVICE', name: 'Refresh device' },
        { value: 'RESEND_ALL_PACKETS', name: 'Resend All Packets' },
    ];
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [selectedCommand, setSelectedCommand] = useState(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState('');
    const handleFirstDropdownChange = selectedValue => {
        setSelectedDevice(selectedValue);
    };
    const handleSecondDropdownChange = selectedValue => {
        setSelectedCommand(selectedValue);
    };
    const handleButtonClick = () => {
        if (selectedDevice && selectedCommand) {
            const requestBody = {
                command: selectedCommand.value,
            };
            http
                .post(SEND_PROVISIONING_COMMAND(selectedDevice.value), requestBody)
                .then(() => {
                setShowSuccessModal(true);
            })
                .catch(error => {
                var _a, _b;
                const errorMessage = ((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) || 'Failed to send provisioning command';
                setShowErrorModal(true);
                setErrorModalMessage(errorMessage);
            });
        }
    };
    return (React.createElement(SlideInModal, { display: isVisible, onClose: onClose, mainHeading: "Reprovisjoner boks", fetchingSpinnerColor: "black" },
        React.createElement("div", { className: "dropdown-container" },
            React.createElement("label", { className: "dropdown-label" }, "Boks"),
            dpads ? (React.createElement(SimpleDropdown, { values: dpads.map(device => ({
                    value: device.deviceId,
                    name: device.modelName,
                })), onChange: handleFirstDropdownChange, title: selectedDevice ? selectedDevice.name : 'Select Boks' })) : (React.createElement("div", { className: "fetchInfo" }, "Loading..."))),
        React.createElement("div", { className: "dropdown-container" },
            React.createElement("label", { className: "dropdown-label" }, "Kommando"),
            React.createElement(SimpleDropdown, { values: provisioningCommands, onChange: handleSecondDropdownChange, title: selectedCommand ? selectedCommand.name : 'Select kommando' })),
        React.createElement("div", { className: "button-container" },
            React.createElement(SimpleButton, { color: Color.BLACK, title: "Gjennomf\u00F8r", onClick: () => {
                    handleButtonClick();
                } })),
        showSuccessModal && (React.createElement(Popup, { firstLine: "", secondLine: 'Successfully send command ' +
                selectedCommand.name +
                ' to device ' +
                selectedDevice.name, closePopup: () => setShowSuccessModal(false), button: React.createElement("div", { className: "popup__remove-buttons" },
                React.createElement("button", { onClick: () => setShowSuccessModal(false) }, "OK")) })),
        showErrorModal && (React.createElement(Popup, { firstLine: "Error", secondLine: errorModalMessage, closePopup: () => setShowErrorModal(false), button: React.createElement("div", { className: "popup__remove-buttons" },
                React.createElement("button", { onClick: () => setShowErrorModal(false) }, "OK")) }))));
}
HitDecoderContainer.propTypes = {
    customerId: PropTypes.number,
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(HitDecoderContainer);
