import { MAP_TOKEN_REQUEST, MAP_TOKEN_SUCCESS, MAP_TOKEN_FAILURE } from '../constants';
const mapTokenReducerDefaultState = {
    mapToken: '',
    isFetching: true,
    errorMessage: '',
};
export default (state = mapTokenReducerDefaultState, action) => {
    switch (action.type) {
        case MAP_TOKEN_REQUEST:
            return Object.assign(Object.assign({}, state), { isFetching: true });
        case MAP_TOKEN_SUCCESS:
            return {
                mapToken: action.mapToken,
                isFetching: false,
            };
        case MAP_TOKEN_FAILURE:
            return Object.assign(Object.assign({}, state), { isFetching: false, errorMessage: action.errorMessage });
        default:
            return state;
    }
};
