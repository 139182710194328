import { STATUS_CHANGE_REQUEST, STATUS_CHANGE_SUCCESS, STATUS_CHANGE_FAILURE, STATUS_CHANGE_CHECK_SKIP, WORKORDER_REQUEST, WORKORDER_SUCCESS, WORKORDER_FAILURE, WORKORDER_REQUEST_BY_CUSTOMER_ID, } from '../workOrderConstants';
export const requestStatusChange = currentStatus => ({
    type: STATUS_CHANGE_REQUEST,
    currentStatus,
});
export const receiveStatusChange = status => ({
    type: STATUS_CHANGE_SUCCESS,
    status,
});
export const checkForStatusSkip = (currentStatus, workOrderId) => ({
    type: STATUS_CHANGE_CHECK_SKIP,
    currentStatus,
    workOrderId,
});
export const errorStatusChange = action => ({
    type: STATUS_CHANGE_FAILURE,
    workOrder: action.workOrder,
    errorMessage: action.errorMessage,
});
export const requestWorkOrderByCustomerInfo = customerId => ({
    type: WORKORDER_REQUEST_BY_CUSTOMER_ID,
    customerId: customerId,
});
export const requestWorkOrder = action => ({
    type: WORKORDER_REQUEST,
    workOrderId: action.workOrderId,
});
export const receiveWorkOrder = action => ({
    type: WORKORDER_SUCCESS,
    workOrder: action.workOrder,
});
export const errorWorkOrder = action => ({
    type: WORKORDER_FAILURE,
    errorMessage: action.errorMessage,
});
