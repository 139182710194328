import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../actions/workOrder';
import Status from '../../../../constants/status-codes';
import Timeout from '../../../../utils/timeout';
import InfoBox from '../../../../components/InfoBox/InfoBoxError';
import NOB from '../../../../constants/language-strings';
import SimpleButton from '../../../../components/Buttons/SimpleButton/SimpleButton';
import './changeStatusButton.scss';
import { TECHNICIAN } from '../../../Authentication/constants';
function ChangeStatusButtonContainer({ workOrder, requestStatusChange, workOrderList, isPrimaryFetching, errorMessage, isTechnician, }) {
    const handlePrimaryClick = () => {
        requestStatusChange(workOrder.status);
    };
    const canChangeStatus = workOrderList.startedWorkOrder.includes(workOrder.workOrderId) ||
        !workOrderList.startedWorkOrder.length ||
        !isTechnician;
    const buttonText = Status.ALLOCATED.includes(workOrder.status)
        ? NOB.WORKORDER_BUTTON.DRIVE
        : NOB.WORKORDER_BUTTON.WORK;
    return (React.createElement("div", { className: "work__order__button__container" },
        !Status.COMPLETE_ARRAY.includes(workOrder.status) &&
            !Status.INCOMPLETE_ARRAY.includes(workOrder.status) && (React.createElement(SimpleButton, { title: buttonText, disabled: !canChangeStatus, isFetching: isPrimaryFetching || workOrderList.isFetching, onClick: handlePrimaryClick })),
        (!canChangeStatus || errorMessage) && (React.createElement(InfoBox, { firstInfoBoxLine: !canChangeStatus ? NOB.WORKORDER_BUTTON.UNABLE_TO_START : errorMessage, background: false }))));
}
ChangeStatusButtonContainer.propTypes = {
    isPrimaryFetching: PropTypes.bool.isRequired,
    requestStatusChange: PropTypes.func.isRequired,
    workOrder: PropTypes.objectOf(PropTypes.any).isRequired,
    workOrderList: PropTypes.objectOf(PropTypes.any).isRequired,
    errorMessage: PropTypes.string.isRequired,
    isTechnician: PropTypes.string.isRequired,
};
const mapStateToProps = state => ({
    isPrimaryFetching: state.workOrder.isPrimaryFetching,
    statusChanged: state.workOrder.statusChanged,
    role: state.authentication.role,
    workOrder: state.workOrder.workOrder,
    workOrderList: state.workOrders,
    errorMessage: state.workOrder.statusChangeError,
    isTechnician: state.authentication.role === TECHNICIAN,
});
const mapDispatchToProps = dispatch => ({
    requestStatusChange: currentStatus => dispatch(actions.requestStatusChange(currentStatus)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Timeout(ChangeStatusButtonContainer));
