import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
const SVG = ({ width, height, fillColor }) => (React.createElement("svg", { width: width, height: height, viewBox: "0 0 12 12", version: "1.1" },
    React.createElement("title", null, "+"),
    React.createElement("defs", null),
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd", strokeLinecap: "square" },
        React.createElement("g", { id: "icon/pluss", fillRule: "nonzero", stroke: fillColor, strokeWidth: "1" },
            React.createElement("path", { d: "M6,1 L6,11", id: "Line-4" }),
            React.createElement("path", { d: "M1,6 L11,6", id: "Line-4-Copy" })))));
SVG.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    fillColor: PropTypes.string,
};
SVG.defaultProps = {
    width: "20px",
    height: "20px",
    fillColor: Variables.blackPrimary,
};
export default SVG;
