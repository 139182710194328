import { PREVIOUS_WORKORDER_LIST_REQUEST, PREVIOUS_WORKORDER_LIST_FAILURE, PREVIOUS_WORKORDER_LIST_SUCCESS, } from '../workOrderListConstants';
const prevWorkOrderListReducerDefaultState = {
    errorMessage: '',
    isFetching: false,
    workOrders: [],
    latestPreviousWorkOrderRequest: {},
};
export default (state = prevWorkOrderListReducerDefaultState, action) => {
    const { errorMessage, type, workOrderRequest } = action;
    switch (type) {
        case PREVIOUS_WORKORDER_LIST_REQUEST:
            return Object.assign(Object.assign({}, state), { isFetching: true });
        case PREVIOUS_WORKORDER_LIST_SUCCESS: {
            const workOrders = !workOrderRequest.first
                ? [...state.workOrders, ...workOrderRequest.content]
                : workOrderRequest.content;
            return Object.assign(Object.assign({}, state), { isFetching: false, errorMessage: '', workOrders, latestPreviousWorkOrderRequest: workOrderRequest });
        }
        case PREVIOUS_WORKORDER_LIST_FAILURE:
            return Object.assign(Object.assign({}, state), { isFetching: false, errorMessage });
        default:
            return state;
    }
};
