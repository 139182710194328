import React from "react";
import PropTypes from "prop-types";
import Variables from "../styles/variables.scss";
const SVG = ({ bodyFill, darkStroke, darkerStroke, darkestStroke, eyeFill, handFill, }) => (React.createElement("svg", { width: "123px", height: "56px", viewBox: "0 0 123 56", version: "1.1" },
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "icon/tilbakemelding_tenker" },
            React.createElement("g", { id: "Group-39", transform: "translate(22.000000, 0.000000)" },
                React.createElement("g", { id: "Group-38", transform: "translate(0.000000, 0.671800)" },
                    React.createElement("path", { id: "Fill-6", fill: bodyFill, d: "M54.2501,58.1716 L19.4831,52.8796 C16.2221,52.3826 13.9581,49.3066 14.4551,46.0446 L20.1491,8.6416 C20.6461,5.3796 23.7221,3.1166 26.9831,3.6136 L61.7501,8.9056 C65.0131,9.4026 67.2751,12.4786 66.7781,15.7406 L61.0841,53.1436 C60.5881,56.4056 57.5121,58.6686 54.2501,58.1716" }),
                    React.createElement("path", { id: "Stroke-8", stroke: darkestStroke, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round", d: "M54.2501,58.1716 L19.4831,52.8796 C16.2221,52.3826 13.9581,49.3066 14.4551,46.0446 L20.1491,8.6416 C20.6461,5.3796 23.7221,3.1166 26.9831,3.6136 L61.7501,8.9056 C65.0131,9.4026 67.2751,12.4786 66.7781,15.7406 L61.0841,53.1436 C60.5881,56.4056 57.5121,58.6686 54.2501,58.1716 Z" }),
                    React.createElement("path", { id: "Fill-10", fill: eyeFill, d: "M32.8175,18.1414 C32.4615,19.4674 31.3975,20.2544 30.4415,19.8984 C29.4855,19.5434 28.9985,18.1794 29.3545,16.8524 C29.7105,15.5264 30.7735,14.7404 31.7295,15.0954 C32.6855,15.4514 33.1725,16.8144 32.8175,18.1414" }),
                    React.createElement("path", { id: "Fill-12", fill: eyeFill, d: "M44.4425,20.4363 C44.0875,21.7643 43.0235,22.5523 42.0675,22.1963 C41.1105,21.8403 40.6245,20.4753 40.9805,19.1473 C41.3365,17.8193 42.3995,17.0323 43.3565,17.3883 C44.3125,17.7443 44.7985,19.1083 44.4425,20.4363" }),
                    React.createElement("path", { id: "Stroke-14", stroke: darkStroke, strokeWidth: "2", strokeLinecap: "round", d: "M43.2862,31.4641 C37.5532,29.7491 30.3432,28.6951 25.1002,28.6951" }),
                    React.createElement("path", { id: "Fill-16", fill: bodyFill, d: "M80.3634,52.5959 L80.3634,63.9999 C80.3634,64.5529 79.4674,64.9999 78.3634,64.9999 L2.8714,64.9999 C1.7654,64.9999 0.8714,64.5529 0.8714,63.9999 L0.8714,52.5959" }),
                    React.createElement("g", { id: "Group-21", transform: "translate(0.000000, 52.000000)" },
                        React.createElement("path", { id: "Fill-18", fill: bodyFill, d: "M0.8712,0.5959 L80.3632,0.5959" }),
                        React.createElement("path", { id: "Stroke-20", d: "M0.8712,0.5959 L80.3632,0.5959", stroke: darkestStroke, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })),
                    React.createElement("path", { id: "Stroke-22", stroke: darkestStroke, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round", d: "M65.1778,30.8923 C65.1778,30.8923 64.7898,44.3713 55.0248,45.3043 C48.9488,45.8853 44.0168,43.7313 41.1448,41.4703" }),
                    React.createElement("path", { id: "Stroke-24", d: "M71.4405,3.2258 L68.4035,6.5268", stroke: darkStroke, strokeWidth: "2", strokeLinecap: "round" }),
                    React.createElement("path", { id: "Stroke-26", d: "M74.798,10.1111 L71.047,11.0381", stroke: darkStroke, strokeWidth: "2", strokeLinecap: "round" }),
                    React.createElement("path", { id: "Stroke-28", d: "M64.3204,0.3284 L63.6884,4.2664", stroke: darkStroke, strokeWidth: "2", strokeLinecap: "round" }),
                    React.createElement("path", { id: "Fill-30", fill: handFill, d: "M40.2804,40.8562 C40.2804,40.8562 34.5164,40.2302 32.5794,35.6192 C30.6404,31.0082 33.4744,30.9822 35.7744,32.2192 C37.9974,33.4142 39.8764,37.4182 40.2804,40.8562" }),
                    React.createElement("path", { id: "Stroke-32", stroke: darkestStroke, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round", d: "M40.2804,40.8562 C40.2804,40.8562 34.5164,40.2302 32.5794,35.6192 C30.6404,31.0082 33.4744,30.9822 35.7744,32.2192 C37.9974,33.4142 39.8764,37.4182 40.2804,40.8562 Z" }),
                    React.createElement("path", { id: "Stroke-34", stroke: darkerStroke, strokeWidth: "2", strokeLinecap: "round", d: "M34.3849,10.1111 C33.2549,9.3461 30.9099,8.4661 29.6139,8.3011" }),
                    React.createElement("path", { id: "Stroke-36", stroke: darkerStroke, strokeWidth: "2", strokeLinecap: "round", d: "M46.548,14.717 C45.199,14.503 42.703,14.699 41.46,15.098" })))))));
SVG.propTypes = {
    bodyFill: PropTypes.string,
    darkStroke: PropTypes.string,
    darkerStroke: PropTypes.string,
    darkestStroke: PropTypes.string,
    eyeFill: PropTypes.string,
    handFill: PropTypes.string,
};
SVG.defaultProps = {
    bodyFill: Variables.whiteSecondary,
    darkStroke: Variables.feedbackDarkGray,
    darkerStroke: Variables.feedbackDarkGray,
    darkestStroke: Variables.feedbackDarkestGray,
    eyeFill: Variables.blackSecondary,
    handFill: Variables.yellowOnBlack,
};
export default SVG;
