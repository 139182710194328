import PropTypes from 'prop-types';
import React from 'react';
import capitalize from '../../../../utils/capitalizeString';
import { GOOGLE_MAPS } from '../../../../constants/popupTypes';
import { HOUSE, TELEPHONE, USER } from '../../../../constants/iconNames';
import Icon from '../../../../icons';
import './customerInfo.scss';
import Variables from '../../../../styles/variables.scss';
const CustomerInfo = ({ address: { city, flatOrApartment, houseNumberAlpha, houseNumberNumeric, postalCode, street }, customer: { firstName, phoneNumber, email, surname, birthDate }, togglePopup, isPastDue, }) => {
    const color = isPastDue ? Variables.redRemove : undefined;
    const birthDateExtracted = birthDate.split('T')[0].split('-');
    const formatedBirthDate = `${birthDateExtracted[2]}-${birthDateExtracted[1]}-${birthDateExtracted[0]}`; // dd-mm-yyyy
    return (React.createElement("div", { className: "customer__info__container__items" },
        React.createElement("button", { className: isPastDue ? 'address--red' : 'address', onClick: () => togglePopup(false, GOOGLE_MAPS) },
            React.createElement(Icon, { name: HOUSE, houseColor: color }),
            React.createElement("div", { className: "customer__info__container__items__item--address" },
                React.createElement("span", null, `${capitalize(street)} ${houseNumberNumeric}${houseNumberAlpha}, `),
                React.createElement("span", null, `${postalCode} ${capitalize(city)} `),
                flatOrApartment && React.createElement("span", null, flatOrApartment))),
        React.createElement("div", { className: "customer__info__container__items__item" },
            React.createElement("div", { className: "customer__info__container__items__item--name" },
                React.createElement("span", null,
                    React.createElement(Icon, { name: USER, color: color }),
                    `${capitalize(firstName)} ${capitalize(surname)}`),
                React.createElement("div", { className: "phoneAndDobContainer" },
                    React.createElement("div", null, formatedBirthDate),
                    React.createElement("div", null,
                        React.createElement("a", { href: `mailto:${email}` }, email)),
                    React.createElement("div", { className: isPastDue ? 'telephone--red' : 'telephone' },
                        React.createElement("span", { className: "customer__telephone__icon" },
                            React.createElement(Icon, { name: TELEPHONE, color: color })),
                        React.createElement("a", { href: `tel:${phoneNumber}` }, phoneNumber)))))));
};
CustomerInfo.propTypes = {
    address: PropTypes.objectOf(PropTypes.any).isRequired,
    customer: PropTypes.objectOf(PropTypes.any).isRequired,
    togglePopup: PropTypes.func.isRequired,
    isPastDue: PropTypes.bool.isRequired,
};
export default CustomerInfo;
