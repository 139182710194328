import { ADDRESS_HISTORY_REQUEST, ADDRESS_HISTORY_SUCCESS, ADDRESS_HISTORY_FAILURE, ADDRESS_HISTORY_BY_ADDRESS_ID_REQUEST, } from '../addressHistoryConstants';
const addressHistoryReducerDefaultState = {
    addressHistory: [],
    errorMessage: '',
    isFetching: false,
};
export default (state = addressHistoryReducerDefaultState, action) => {
    const { addressHistory, errorMessage, type, workOrderId } = action;
    switch (type) {
        case ADDRESS_HISTORY_BY_ADDRESS_ID_REQUEST:
            return {
                addressHistory: [],
                workOrderId: 0,
                isFetching: true,
            };
        case ADDRESS_HISTORY_REQUEST:
            return {
                addressHistory: [],
                workOrderId,
                isFetching: true,
            };
        case ADDRESS_HISTORY_SUCCESS:
            return Object.assign(Object.assign({}, state), { isFetching: false, addressHistory });
        case ADDRESS_HISTORY_FAILURE:
            return Object.assign(Object.assign({}, state), { isFetching: false, errorMessage });
        default:
            return state;
    }
};
