import { REMOVE_PRODUCT_FAILURE, REMOVE_PRODUCT_REQUEST, REMOVE_PRODUCT_SUCCESS, TO_BE_REMOVED, CLEAR_REMOVE_ERROR_MESSAGE, } from '../customerProductsConstants';
const removeProductReducerDefaultState = {
    agreementDetailId: 0,
    errorMessage: '',
    isFetching: false,
};
export default (state = removeProductReducerDefaultState, action) => {
    const { agreementDetailId, errorMessage, type, removedProductId } = action;
    switch (type) {
        case TO_BE_REMOVED:
            return Object.assign(Object.assign({}, state), { agreementDetailId });
        case REMOVE_PRODUCT_REQUEST:
            return {
                isFetching: true,
                agreementDetailId,
            };
        case REMOVE_PRODUCT_SUCCESS:
            return Object.assign(Object.assign({}, state), { isFetching: false, removedProductId });
        case REMOVE_PRODUCT_FAILURE:
            return Object.assign(Object.assign({}, state), { errorMessage, isFetching: false });
        case CLEAR_REMOVE_ERROR_MESSAGE:
            return Object.assign(Object.assign({}, state), { errorMessage: '', isFetching: false });
        default:
            return state;
    }
};
