import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NOB from '../../../constants/language-strings';
import SlideInPage from '../../../components/SlideInPage/SlideInPage';
import PropTypes from 'prop-types';
import InfoBox from '../../../components/InfoBox/InfoBox';
import Feedback from '../../../feedbacks';
import WorkOrderHistoryItem from '../components/WorkOrderHistoryItem';
import styled from 'styled-components';
const Container = styled.div `
    margin: 0 15px;
  }
`;
const StyledHeading = styled.h1 `
  padding-top: 10px;
  margin: 0 15px;
  color: #1e1c1e;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
`;
const HistoryListWrapper = styled.div `
  padding: 10px;
  margin-top: 5px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px #0000001a;
`;
const HistoryList = styled.div `
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
function WorkOrderHistoryContainer({ workOrderHistory: { workOrderHistory, isFetching, errorMessage }, workOrderId, }) {
    return (React.createElement(SlideInPage, { mainHeading: NOB.WORKORDER_HISTORY.WORKORDER_HISTORY, showLeftIcon: true, isFetching: isFetching }, !workOrderHistory || workOrderHistory.length === 0 ? (React.createElement(InfoBox, { feedback: React.createElement(Feedback, null), firstInfoBoxLine: NOB.FEEDBACKS.SORRY, secondInfoBoxLine: NOB.WORKORDER_HISTORY.NO_WORKORDER_HISTORY })) : (React.createElement(Container, null,
        React.createElement(StyledHeading, null,
            NOB.WORKORDER_HISTORY.WORKORDER_ID,
            " ",
            workOrderId),
        React.createElement(HistoryListWrapper, null,
            React.createElement(HistoryList, null, workOrderHistory.map((item, index) => (React.createElement(WorkOrderHistoryItem, { key: index, workOrderHistoryItem: item })))))))));
}
WorkOrderHistoryContainer.propTypes = {
    workOrderHistory: PropTypes.objectOf(PropTypes.any),
    workOrderId: PropTypes.number.isRequired,
};
WorkOrderHistoryContainer.defaultProps = {
    workOrderHistory: [],
};
const mapStateToProps = state => ({
    workOrderHistory: state.workOrderHistory,
    workOrderId: state.workOrder.workOrder.workOrderId,
});
export default withRouter(connect(mapStateToProps)(WorkOrderHistoryContainer));
