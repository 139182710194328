import React from 'react';
import PropTypes from 'prop-types';
import ProductItemContentActive from './ProductItemContentActive';
import ProductItemContentInactive from './ProductItemContentInactive';
import ListItem from '../../components/ListItem/ListItem';
import { ACTIVE } from '../../constants/productStatuses';
import SerialNumberList from '../../components/SerialNumber/SerialNumberList';
import './productListElement.scss';
function ProductListItem({ onDelete, onActivate, errorMessage, isFetching, shouldActivateActiveAnimation, serialNumberInputs, onClick, clickable, showDeleteButton, showLinkIcon, title, status, handleChange, handleSubmit, canBeActivated, onScannerClick, serialNumbers, onReset, }) {
    return (React.createElement("div", { clasname: "product-list-element" },
        React.createElement(ListItem, { activated: shouldActivateActiveAnimation, activeBorder: shouldActivateActiveAnimation, isFetching: isFetching, clickable: clickable, onClick: clickable ? onClick : null, errorMessage: errorMessage }, status === ACTIVE ? (React.createElement(ProductItemContentActive, { hasLinkOptions: showLinkIcon, name: title, showDeleteButton: showDeleteButton, removeProduct: onDelete, leftText: status, serialNumbers: serialNumbers })) : (React.createElement(ProductItemContentInactive, { onActivate: canBeActivated ? onActivate : null, name: title, showDeleteButton: showDeleteButton, removeProduct: onDelete, leftText: canBeActivated ? 'Trykk for å aktivere' : status }))),
        serialNumberInputs.length > 0 && (React.createElement(SerialNumberList, { devices: serialNumberInputs, handleChange: handleChange, handleSubmit: handleSubmit, onScannerClick: onScannerClick, onReset: onReset, isFetching: isFetching }))));
}
ProductListItem.propTypes = {
    onDelete: PropTypes.func,
    onActivate: PropTypes.func,
    errorMessage: PropTypes.string,
    isFetching: PropTypes.bool,
    shouldActivateActiveAnimation: PropTypes.bool,
    serialNumberInputs: PropTypes.arrayOf(PropTypes.shape({
        dpadId: PropTypes.number.isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        errorMessage: PropTypes.string,
    })),
    onClick: PropTypes.func,
    clickable: PropTypes.bool,
    showDeleteButton: PropTypes.bool,
    showLinkIcon: PropTypes.bool,
    title: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    canBeActivated: PropTypes.bool,
    onScannerClick: PropTypes.func,
    serialNumbers: PropTypes.arrayOf(PropTypes.string),
    onReset: PropTypes.func,
};
ProductListItem.defaultProps = {
    onDelete: () => { },
    onActivate: () => { },
    errorMessage: '',
    isFetching: false,
    shouldActivateActiveAnimation: false,
    serialNumberInputs: [],
    onClick: () => { },
    clickable: false,
    showDeleteButton: false,
    showLinkIcon: false,
    handleChange: () => { },
    handleSubmit: () => { },
    canBeActivated: false,
    onScannerClick: () => { },
    serialNumbers: [],
    onReset: () => { },
};
export default ProductListItem;
