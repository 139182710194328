import { call, put, takeEvery, select } from 'redux-saga/effects';
import { LINK_PRODUCT_REQUEST, LINK_SINGLE_PRODUCT_REQUEST } from '../customerProductsConstants';
import * as actions from '../actions/linkProductActions';
import * as customerActions from '../actions/customerProducts';
import { LINK_PRODUCT } from '../../../constants/endpoints';
import { putAPI } from '../../../api/apiConfig';
const getCustomerProducts = state => state.customerProducts.customerProducts;
function* callLinkProductSaga({ customerId, productId, dpadId }) {
    try {
        const customerProducts = yield select(getCustomerProducts);
        /* if dpad is already linked remove dpad for list to be linked, add all other dpads to
         * list that has linked = true;
         */
        const dpadIdsToLink = customerProducts
            .find(product => product.productId === productId)
            .linkOptions.filter(linkOption => {
            if (linkOption.dpadId.toString() === dpadId && linkOption.linked === false)
                return dpadId;
            else if (linkOption.dpadId.toString() !== dpadId && linkOption.linked === true)
                return dpadId;
            return false;
        })
            .map(linkOption => linkOption.dpadId);
        const url = LINK_PRODUCT(customerId, productId);
        const newProduct = yield call(putAPI, url, dpadIdsToLink);
        yield put(actions.linkProductSuccess());
        yield put(customerActions.updateSingleProduct(newProduct));
    }
    catch (error) {
        yield put(actions.linkProductFailure(error.data.message));
    }
}
function* callLinkSingleDpad({ customerId, productId, dpadId }) {
    try {
        const url = LINK_PRODUCT(customerId, productId);
        const newProduct = yield call(putAPI, url, [dpadId]);
        yield put(actions.linkProductSuccess());
        yield put(customerActions.updateSingleProduct(newProduct));
    }
    catch (error) {
        yield put(actions.linkProductFailure(error.data.message));
    }
}
export default [
    takeEvery(LINK_PRODUCT_REQUEST, callLinkProductSaga),
    takeEvery(LINK_SINGLE_PRODUCT_REQUEST, callLinkSingleDpad),
];
