import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import CustomerInfoContainer from '../../components/customerInfo/CustomerInfoContainer';
import Feedback from '../../../../feedbacks';
import FetchingSpinner from '../../../../utils/FetchingSpinner';
import { GOOGLE_MAPS } from '../../../../constants/popupTypes';
import GoogleMapsButton from '../../../../components/Popup/components/GoogleMapsButton';
import Popup from '../../../../components/Popup/containers/Popup';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import OrderedProducts from '../../components/orderedProducts/OrderedProducts';
import MapContainer from '../../../Map/containers/MapContainer';
import NOB from '../../../../constants/language-strings';
import Status, { COMPLETE, INCOMPLETE } from '../../../../constants/status-codes';
import TechnicalDetails from '../../components/technicalDetails/TechnicalDetails';
import { TECHNICIAN, EXTERNAL_INSTALLER } from '../../../Authentication/constants';
import { WAITING_ACTIVATION, SCHEDULED_ACTIVATION } from '../../../../constants/productStatuses';
import TextBox from '../../components/textBox/TextBox';
import UNAUTHORIZED from '../../../../constants/errorStatusCodes';
import ChangeStatusButtonContainer from '../changeStatusButton/ChangeStatusButtonContainer';
import * as toggleAction from '../../../../components/Popup/actions/popupActions';
import '../../../../components/Popup/popup.scss';
import './workOrder.scss';
import { workorderServicesRequest } from '../../../WorkLog/actions/workOrderServiceActions';
import NavigationButton from '../../../../components/Buttons/NavigationButton/NavigationButton';
import { ADDRESS_HISTORY_URL, HEALTH_CHECK_URL, CUSTOMER_PRODUCTS_URL, WORK_LOG_URL, NOT_HOME_URL, WORK_ORDER_HISTORY_URL, } from '../../../../constants/paths';
import Icon from '../../../../icons';
import Variables from '../../../../styles/variables.scss';
import { GET_BOX, HISTORY, HEALTH_CHECK, ARCHIVE } from '../../../../constants/iconNames';
import dispatchMainRequests from '../../../../utils/dispatchMainRequests';
import WorkOrderDetailsContainer from '../../components/workOrderDetails/containers/WorkOrderDetailsContainer';
class WorkOrderContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            completed: false,
        };
    }
    componentDidMount() {
        const { workOrderId, requestWorkOrderServices, dispatchAll } = this.props;
        requestWorkOrderServices(workOrderId);
        dispatchAll(workOrderId);
        this.handleClickFinish = this.handleClickFinish.bind(this);
    }
    componentDidUpdate(prevProps) {
        const { dispatchAll, workOrderId, requestWorkOrderServices } = this.props;
        if (workOrderId !== prevProps.workOrderId) {
            requestWorkOrderServices(workOrderId);
            dispatchAll(workOrderId);
        }
    }
    handleClickFinish() {
        this.setState({ completed: !this.state.completed });
    }
    render() {
        var _a;
        const { addressHistory, close, customerProducts, match, popupType, role, togglePopupFunc, workOrder: { workOrder, errorMessage, isFetching }, workOrderId, areProductsFetching, isWorkOrderHistoryFetching, orderedItems, history, } = this.props;
        const technician = role === TECHNICIAN;
        const allocated = Status.ALLOCATED.includes(workOrder.status);
        const onSite = Status.ON_SITE.includes(workOrder.status);
        const traveling = Status.TRAVELING.includes(workOrder.status);
        const done = COMPLETE.includes(workOrder.status) || INCOMPLETE.includes(workOrder.status);
        const installerNotDone = !workOrder.isThreeSixty && !done;
        const installerNotDoneOrOnSite = installerNotDone || onSite;
        const waitingActivationsLength = customerProducts.filter(waitingActivation => waitingActivation.status === WAITING_ACTIVATION ||
            waitingActivation.status === SCHEDULED_ACTIVATION).length;
        const newWorkOrderEntered = !workOrderId || match.params.id !== `${workOrderId}`;
        if (isFetching) {
            return (React.createElement("div", { className: "work__order__container" },
                React.createElement(FetchingSpinner, null)));
        }
        const noAccess = errorMessage.includes(UNAUTHORIZED);
        if (errorMessage) {
            return (React.createElement("div", { className: "work__order__container" },
                React.createElement(InfoBox, { feedback: React.createElement(Feedback, null), link: noAccess, secondInfoBoxLine: noAccess ? NOB.NO_WORK_ORDERS.NO_ACCESS : errorMessage })));
        }
        if (workOrder === undefined || workOrder.workOrderId === 0) {
            return (React.createElement("div", { className: "work__order__container" },
                React.createElement(InfoBox, { feedback: React.createElement(Feedback, null), link: true, secondInfoBoxLine: "Unable to retrieve work order information. Try to refresh browser or login again." })));
        }
        return (React.createElement("div", { className: "work__order__container" },
            React.createElement("div", null,
                React.createElement("div", { className: "work__order__details" },
                    React.createElement(WorkOrderDetailsContainer, { done: done, workOrder: workOrder, totalTimeEstimate: orderedItems.totalTimeEstimate, inWorkLog: false })),
                React.createElement("div", { className: "work__order" },
                    React.createElement("div", { className: "work__order__content" },
                        React.createElement("div", { className: "work__order__content--top" },
                            workOrder.isThreeSixty && (traveling || allocated) && (React.createElement(ChangeStatusButtonContainer, null)),
                            traveling && (React.createElement("div", { className: "not_home__button" },
                                React.createElement(Link, { to: NOT_HOME_URL(workOrderId), className: "" }, NOB.FOLLOW_UP.NOT_HOME))),
                            React.createElement("div", { className: "work__order__content--customer-info-container" },
                                React.createElement(CustomerInfoContainer, { address: workOrder.address, addressDetails: workOrder.addressDetails, customer: workOrder.customer, togglePopup: togglePopupFunc, workOrder: workOrder })),
                            !done && addressHistory.length > 0 && (React.createElement(NavigationButton, { URL: ADDRESS_HISTORY_URL(workOrderId), leftIcon: React.createElement(Icon, { name: HISTORY }), title: NOB.WORKORDER_TABS.ADDRESS_HISTORY, number: addressHistory.length })),
                            React.createElement("div", { className: "work__order__content__description" },
                                React.createElement(TextBox, { heading: NOB.COMMENT.DESCRIPTION_HEADING, text: workOrder.workDescription, attachments: workOrder.attachments, languageStrings: NOB.COMMENT }),
                                React.createElement(OrderedProducts, { customerProducts: customerProducts, done: done, orderedItems: orderedItems, technician: technician })),
                            React.createElement(NavigationButton, { URL: WORK_ORDER_HISTORY_URL(workOrderId), title: NOB.WORKORDER_HISTORY.WORKORDER_HISTORY, leftIcon: isWorkOrderHistoryFetching ? (React.createElement(ClipLoader, { size: 16, color: Variables.grayButton })) : (React.createElement(Icon, { name: ARCHIVE })) }),
                            React.createElement(NavigationButton, { URL: CUSTOMER_PRODUCTS_URL(workOrderId), title: NOB.CUSTOMER_PRODUCTS.CUSTOMER_PRODUCTS, leftIcon: areProductsFetching ? (React.createElement(ClipLoader, { size: 16, color: Variables.grayButton })) : (React.createElement(Icon, { fill: waitingActivationsLength === 0 ? Variables.iconBlue : Variables.buttonRed, name: GET_BOX })), number: waitingActivationsLength > 0 ? waitingActivationsLength : null, numberColor: waitingActivationsLength > 0 ? NOB.COMPONENT.RED : null }),
                            React.createElement(NavigationButton, { URL: HEALTH_CHECK_URL(workOrderId), leftIcon: React.createElement(Icon, { name: HEALTH_CHECK }), title: NOB.HEALTH_CHECK.HEALTH_CHECK }),
                            React.createElement(TechnicalDetails, { technicalDetails: workOrder.technicalDetails, capacity: (_a = workOrder === null || workOrder === void 0 ? void 0 : workOrder.capacity) === null || _a === void 0 ? void 0 : _a.data }),
                            done && (React.createElement(TextBox, { heading: NOB.COMMENT.COMMENT, text: workOrder.actionTaken, languageStrings: NOB.COMMENT }))),
                        !close && popupType === GOOGLE_MAPS && (React.createElement(Popup, { firstLine: NOB.GOOGLE_MAPS_POPUP.GOOGLE_MAPS, secondLine: NOB.GOOGLE_MAPS_POPUP.QUESTION, button: React.createElement(GoogleMapsButton, null) })),
                        ((technician && onSite) || !technician) && (React.createElement(MapContainer, { newWorkOrderEntered: newWorkOrderEntered })),
                        installerNotDoneOrOnSite && (React.createElement(NavigationButton, { URL: WORK_LOG_URL(workOrderId), title: NOB.WORK_LOG.COMPLETE_WORKORDER_BUTTON, customClass: "complete_workorder_button", onClick: waitingActivationsLength > 0 ? this.handleClickFinish : null })))),
                this.state.completed && waitingActivationsLength > 0 && (React.createElement(Popup, { firstLine: NOB.WORK_LOG.POPUP_TITLE, secondLine: NOB.WORK_LOG.POPUP_TEXT, closePopup: () => this.handleClickFinish(), button: React.createElement("div", { className: "popup__remove-buttons" },
                        React.createElement("button", { onClick: () => {
                                this.handleClickFinish();
                            } }, NOB.WORK_LOG.POPUP_CANCEL_BUTTON),
                        React.createElement("button", { onClick: () => {
                                history.push(WORK_LOG_URL(workOrderId));
                            } }, NOB.WORK_LOG.POPUP_COMPLETE_BUTTON)) })),
                // TODO: Hva er det her?
                !(COMPLETE.includes(/\d/.test(workOrder.status)
                    ? workOrder.status.slice(workOrder.status.lastIndexOf(' ') + 1)
                    : workOrder.status) ||
                    INCOMPLETE.includes(/\d/.test(workOrder.status)
                        ? workOrder.status.slice(workOrder.status.lastIndexOf(' ') + 1)
                        : workOrder.status)))));
    }
}
WorkOrderContainer.propTypes = {
    addressHistory: PropTypes.arrayOf(PropTypes.any).isRequired,
    close: PropTypes.bool,
    customerProducts: PropTypes.arrayOf(PropTypes.any),
    isFetching: PropTypes.bool,
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    dispatchAll: PropTypes.func.isRequired,
    popupType: PropTypes.string,
    role: PropTypes.string,
    togglePopupFunc: PropTypes.func.isRequired,
    workOrder: PropTypes.objectOf(PropTypes.any),
    orderedItems: PropTypes.objectOf(PropTypes.any).isRequired,
    workOrderId: PropTypes.number,
    areProductsFetching: PropTypes.bool.isRequired,
    isWorkOrderHistoryFetching: PropTypes.bool.isRequired,
    requestWorkOrderServices: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};
WorkOrderContainer.defaultProps = {
    close: true,
    customerProducts: [],
    popupType: '',
    workOrder: {
        workOrder: {},
        errorMessage: '',
    },
    role: EXTERNAL_INSTALLER,
    workOrderId: 0,
    isFetching: false,
};
const mapStateToProps = state => ({
    addressHistory: state.addressHistory.addressHistory,
    close: state.popup.close,
    customerProducts: state.customerProducts.customerProducts,
    isPrimaryFetching: state.workOrder.isPrimaryFetching,
    areProductsFetching: state.customerProducts.isFetching,
    isWorkOrderHistoryFetching: state.workOrderHistory.isFetching,
    popupType: state.popup.popupType,
    role: state.authentication.role,
    workOrder: state.workOrder,
    orderedItems: state.workOrderServices.orderedItems,
});
const mapDispatchToProps = dispatch => ({
    dispatchAll: workOrderId => dispatchMainRequests(dispatch, workOrderId),
    togglePopupFunc: (close, popupType) => dispatch(toggleAction.togglePopup({ close, popupType })),
    requestWorkOrderServices: workOrderId => dispatch(workorderServicesRequest(workOrderId)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkOrderContainer));
