import { WORK_ORDER_HISTORY_REQUEST, WORK_ORDER_HISTORY_FAILURE, WORK_ORDER_HISTORY_SUCCESS, } from '../workOrderHistoryConstants';
export const requestWorkOrderHistory = action => ({
    type: WORK_ORDER_HISTORY_REQUEST,
    workOrderId: action.workOrderId,
});
export const receiveWorkOrderHistory = action => ({
    type: WORK_ORDER_HISTORY_SUCCESS,
    workOrderHistory: action.workOrderHistory,
});
export const errorWorkOrderHistory = action => ({
    type: WORK_ORDER_HISTORY_FAILURE,
    errorMessage: action.errorMessage,
});
