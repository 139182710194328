import { DEVICE_POLLING_CUSTOMER_ERROR, DEVICE_POLLING_CUSTOMER_RECEIVE, DEVICE_POLLING_CUSTOMER_REQUEST, HEALTH_CHECK_ERROR, HEALTH_CHECK_RECEIVE, HEALTH_CHECK_REQUEST, ICC_CHECK_ERROR, ICC_CHECK_RECEIVE, ICC_CHECK_REQUEST, UPDATE_HEALTH_CHECK_REQUEST, } from '../constants';
export const requestHealthCheck = ({ customerId }) => ({
    type: HEALTH_CHECK_REQUEST,
    customerId,
});
export const receiveHealthCheck = ({ provisioning }) => ({
    type: HEALTH_CHECK_RECEIVE,
    provisioning,
});
export const errorHealthCheck = ({ errorMessage }) => ({
    type: HEALTH_CHECK_ERROR,
    errorMessage,
});
export const requestUpdateHealthCheck = ({ customerId, healthCheckIds }) => ({
    type: UPDATE_HEALTH_CHECK_REQUEST,
    customerId,
    healthCheckIds,
});
export const requestIccCheck = customerId => ({
    type: ICC_CHECK_REQUEST,
    customerId,
});
export const receiveIccCheck = ({ iccCheck }) => ({
    type: ICC_CHECK_RECEIVE,
    iccCheck,
});
export const errorIccCheck = ({ errorMessage }) => ({
    type: ICC_CHECK_ERROR,
    errorMessage,
});
export const requestDevicePollingCustomer = ({ customerId, mainHeading, subHeading }) => ({
    type: DEVICE_POLLING_CUSTOMER_REQUEST,
    customerId,
    mainHeading,
    subHeading,
});
export const receiveDevicePollingCustomer = ({ devicePollingCustomer }) => ({
    type: DEVICE_POLLING_CUSTOMER_RECEIVE,
    devicePollingCustomer,
});
export const errorDevicePollingCustomer = ({ errorMessage }) => ({
    type: DEVICE_POLLING_CUSTOMER_ERROR,
    errorMessage,
});
