import { NEW, SOLVED } from '../modules/WorkLog/workLogConstants';
export default function zendeskBody(workOrder, customerProducts, zendesk, status) {
    const { workOrderId, address: { city, flatOrApartment, houseNumberAlpha, houseNumberNumeric, postalCode, street }, customer: { customerId, email, firstName, phoneNumber, surname }, technicalDetails: { Hub, Node }, } = workOrder;
    const { commentBody, files, followUp } = zendesk;
    const fileList = [];
    if (files) {
        files.forEach(value => {
            fileList.push({
                fileName: value.fileName,
                base64String: value.file.split('base64,').pop(),
            });
        });
    }
    const zendeskStatus = followUp || !status ? NEW : SOLVED;
    const createdCommentBody = zendesk.followUpFormValues
        ? `
    \n
    ${Object.keys(zendesk.followUpFormValues).map(key => {
            let value;
            if (zendesk.followUpFormValues[key] === 'false')
                value = 'Ja';
            else if (zendesk.followUpFormValues[key] === 'true')
                value = 'Nei';
            else
                value = zendesk.followUpFormValues[key];
            return `\n **${key}** \n ${value}`;
        })}
  `
        : commentBody;
    const body = {
        workOrderId,
        commentBody: createdCommentBody,
        firstName,
        surName: surname,
        phoneNumber,
        email,
        customerId,
        streetName: street,
        houseNumberNumeric,
        houseNumberAlpha,
        flatOrApartment,
        postalCode,
        city,
        hub: Hub,
        node: Node,
        status: zendeskStatus,
        zendeskFileList: fileList,
        followUp,
        complete: status,
        customerProducts,
    };
    return body;
}
