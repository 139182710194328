import { AGREEMENTS_PRICE_FAILURE, AGREEMENTS_PRICE_REQUEST, AGREEMENTS_PRICE_SUCCESS, } from '../customerProductsConstants';
export const requestAgreementsPrice = () => ({
    type: AGREEMENTS_PRICE_REQUEST,
});
export const receiveAgreementsPrice = ({ agreements }) => ({
    type: AGREEMENTS_PRICE_SUCCESS,
    agreements,
});
export const errorAgreementsPrice = ({ errorMessage }) => ({
    type: AGREEMENTS_PRICE_FAILURE,
    errorMessage,
});
