import React from "react";
import Variables from "../styles/variables.scss";
const SVG = () => (React.createElement("svg", { width: "22px", height: "17px", viewBox: "0 0 22 17", version: "1.1" },
    React.createElement("title", null, "icon/varebil_ikkefyltsvart"),
    React.createElement("desc", null, "Created with Sketch."),
    React.createElement("defs", null),
    React.createElement("g", { id: "Symbols", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
        React.createElement("g", { id: "icon/varebil_ikkefyltsvart", transform: "translate(1.000000, 1.000000)", stroke: Variables.blackPrimary },
            React.createElement("g", { id: "Flytting-Copy" },
                React.createElement("path", { d: "M17.4182454,12.9915622 L19.0839589,12.9915622 C19.5291407,12.9915622 19.8909939,12.632628 19.8909939,12.1898603 L19.8909939,8.39912821 C19.8909939,7.11730392 17.9243228,4.41043734 17.9243228,4.41043734 C17.6752323,4.04422457 17.1059083,3.74966482 16.6583702,3.74966482 L14.1111129,3.74966482 C13.9587765,3.74966482 13.8352833,3.62883451 13.8352833,3.48003356 L13.8352833,0.937613939 C13.8352833,0.490594776 13.4737999,0.133270184 13.0278874,0.133270184 L0.916402058,0.133270184 C0.469095282,0.133270184 0.109006121,0.49338715 0.109006121,0.937613939 L0.109006121,12.1872185 C0.109006121,12.6342376 0.470489595,12.9915622 0.916402058,12.9915622 L1.7575051,12.9915622 L1.7575051,12.9915622 M5.60400272,12.9915622 L13.5717478,12.9915622", id: "Rectangle-2-Copy-2" }),
                React.createElement("ellipse", { id: "Oval-1", cx: "3.68075391", cy: "12.9915622", rx: "1.92324881", ry: "1.87516759" }),
                React.createElement("ellipse", { id: "Oval-1-Copy", cx: "15.4949966", cy: "12.9915622", rx: "1.92324881", ry: "1.87516759" }))))));
export default SVG;
