import React from 'react';
import PropTypes from 'prop-types';
import './infobox.scss';
export default function InfoBox({ feedback, firstInfoBoxLine, background }) {
    const className = background ? 'info-box-error-background' : 'info-box-error';
    return (React.createElement("div", { className: className },
        feedback,
        React.createElement("h3", null, firstInfoBoxLine)));
}
InfoBox.defaultProps = {
    background: true,
    feedback: null,
    firstInfoBoxLine: '',
};
InfoBox.propTypes = {
    background: PropTypes.bool,
    feedback: PropTypes.element,
    firstInfoBoxLine: PropTypes.string,
};
