import PropTypes from 'prop-types';
import React from 'react';
import DropdownButton from './DropdownButton';
import DropdownContent from './DropdownContent';
import '../containers/addressHistory.scss';
const AddressHistoryDropdown = ({ addressHistory, opened, toggleDropdown }) => {
    const spanLightText = content => (React.createElement("span", { className: "dropdown__content--item--light" }, content));
    const dropdown = addressHistory.map(singleAddressHistory => {
        const open = opened === 0 ? addressHistory[0].workOrderId : opened;
        const closeDropdown = open !== singleAddressHistory.workOrderId;
        return (React.createElement("div", { className: "address_history__dropdown", key: singleAddressHistory.workOrderId },
            React.createElement(DropdownButton, { closeDropdown: closeDropdown, open: open, singleAddressHistory: singleAddressHistory, toggleDropdown: toggleDropdown }),
            React.createElement(DropdownContent, { closeDropdown: closeDropdown, spanLightText: spanLightText, singleAddressHistory: singleAddressHistory })));
    });
    return React.createElement("div", { className: "dropdown" }, dropdown);
};
AddressHistoryDropdown.propTypes = {
    addressHistory: PropTypes.arrayOf(PropTypes.any).isRequired,
    opened: PropTypes.number.isRequired,
    toggleDropdown: PropTypes.func.isRequired,
};
export default AddressHistoryDropdown;
