import { call, put, select, takeEvery } from 'redux-saga/effects';
import { REMOVE_PRODUCT_REQUEST } from '../customerProductsConstants';
import * as actions from '../actions/removeProductActions';
import { requestAgreementsPrice } from '../actions/agreementPriceActions';
import { receiveCustomerProducts, requestCustomerProductsBackground, } from '../actions/customerProducts';
import { REMOVE_PRODUCT } from '../../../constants/endpoints';
import { deleteAPI } from '../../../api/apiConfig';
export function* callRemoveService({ agreementDetailId, customerId }) {
    try {
        const url = REMOVE_PRODUCT(agreementDetailId);
        yield call(deleteAPI, url);
        const customerProductList = yield select(state => state.customerProducts.customerProducts);
        // finds index of the product to be removed
        const index = customerProductList.findIndex(item => item.productId === agreementDetailId);
        // removes the product
        customerProductList.splice(index, 1);
        yield put(receiveCustomerProducts({
            customerProducts: customerProductList,
        }));
        yield put(actions.receiveRemoveProduct(agreementDetailId));
        // request new price information for agreement
        yield put(requestAgreementsPrice());
        yield put(requestCustomerProductsBackground({ customerId }));
    }
    catch (error) {
        yield put(actions.errorRemoveProduct({
            errorMessage: `Could not remove product. ${error.message}`,
        }));
    }
}
export default [takeEvery(REMOVE_PRODUCT_REQUEST, callRemoveService)];
