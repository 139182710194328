import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ACTIVE } from '../../../../constants/productStatuses';
import useForm from '../../../../utils/customHooks/useForm';
import { ACTIVATE_PRODUCT, ACTIVATE_AGREEMENT_DETAIL, REMOVE_PRODUCT, } from '../../../../constants/endpoints';
import { removeProductSuccess } from '../../actions/activateProduct';
import { putAPI, deleteAPI } from '../../../../api/apiConfig';
import DeviceScannerContainer from '../../../DeviceScanner/DeviceActivationScannerContainer';
import { updateSingleProduct, requestCustomerProductsBackground, } from '../../actions/customerProducts';
import SingleProductContainer from '../SingleProduct/SingleProductContainer';
import ProductListElement from '../../../../components/ProductListElement/ProductListElement';
import Popup from '../../../../components/Popup/containers/Popup';
import { requestAgreementsPrice } from '../../actions/agreementPriceActions';
function ProductListElementContainer({ product, updateProduct, deleteProduct, languageStrings, updateAllProducts, requestAgreementPrice, customerId, }) {
    const { canBeRemoved, devices, linkOptions, name, status, productId } = product;
    const [isFetching, setIsFetching] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [showScanner, setShowScanner] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showProductFullscreen, setShowProductFullscreen] = useState(false);
    const [activatedAnimation, setActivatedAnimation] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const activateProduct = async (requestBody) => {
        var _a;
        setIsFetching(true);
        try {
            const result = await putAPI(ACTIVATE_PRODUCT(productId), requestBody);
            updateAllProducts(customerId);
            updateProduct(result);
            setActivatedAnimation(true);
        }
        catch (error) {
            setIsFetching(false);
            setErrorMessages((_a = error === null || error === void 0 ? void 0 : error.data) !== null && _a !== void 0 ? _a : []);
        }
        setIsFetching(false);
    };
    const onDelete = async () => {
        setIsFetching(true);
        try {
            await deleteAPI(REMOVE_PRODUCT(productId));
            updateAllProducts(customerId);
            requestAgreementPrice();
            deleteProduct(productId);
        }
        catch (error) {
            setIsFetching(false);
            setErrorMessage(error.data.message);
        }
    };
    const onActivate = async () => {
        var _a, _b, _c;
        setIsFetching(true);
        try {
            const result = await putAPI(ACTIVATE_AGREEMENT_DETAIL(productId));
            updateAllProducts(customerId);
            updateProduct(result);
            setActivatedAnimation(true);
        }
        catch (error) {
            setIsFetching(false);
            setErrorMessage((_c = (_b = (_a = error.data) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : error === null || error === void 0 ? void 0 : error.message) !== null && _c !== void 0 ? _c : 'Unexpected error. Check network connection and try again.');
        }
        setIsFetching(false);
    };
    const onSubmit = formValues => {
        // Only try to activate when all serialNumbers are present
        if (devices.filter(({ serialNumber }) => !serialNumber).length !==
            Object.values(formValues).filter(value => value).length)
            return;
        const requestBody = Object.entries(formValues).map(([key, value]) => ({
            dpadId: key,
            serialNumbers: [value],
        }));
        activateProduct(requestBody);
    };
    const { values, handleChange, handleSubmit, overrideValue } = useForm(() => {
        onSubmit(values);
    });
    const handleInputChange = e => {
        e.persist();
        setErrorMessages(errorMessages.filter(message => message.dpadId !== parseInt(e.target.name, 10)));
        handleChange(e);
    };
    const onReset = dpadId => {
        overrideValue(`${dpadId}`, '');
        setErrorMessages(errorMessages.filter(message => message.dpadId !== dpadId));
    };
    const onSerialNumberFound = (serialNumbers, errors) => {
        serialNumbers.forEach((serialNumber, index) => overrideValue(devices[index].dpadId, serialNumber.serialNumber));
        setErrorMessages(errors);
        if (errors.data && errors.data.message)
            setErrorMessage(errors.data.message);
    };
    const missingSerialNumbers = devices
        .filter(({ serialNumber }) => !serialNumber)
        .map(({ dpadId }) => ({
        dpadId,
        value: values[dpadId],
        errorMessage: errorMessages.find(dpad => dpad.dpadId === dpadId)
            ? errorMessages.find(dpad => dpad.dpadId === dpadId).errorMessage
            : '',
    }));
    const clickable = status === ACTIVE && (devices.length > 0 || linkOptions.length > 0);
    const canBeActivated = status !== ACTIVE && !missingSerialNumbers.length;
    return (React.createElement("div", null,
        React.createElement(ProductListElement, { clickable: clickable, canBeActivated: canBeActivated, serialNumberInputs: missingSerialNumbers, onClick: () => setShowProductFullscreen(true), handleChange: handleInputChange, handleSubmit: handleSubmit, isFetching: isFetching, shouldActivateActiveAnimation: activatedAnimation, title: name, status: status, onActivate: onActivate, onDelete: () => setShowDeleteModal(true), errorMessage: errorMessage, showDeleteButton: canBeRemoved && !clickable, onScannerClick: () => setShowScanner(true), serialNumbers: devices.map(device => device.serialNumber), onReset: onReset, showLinkIcon: linkOptions.length > 0 }),
        showScanner && (React.createElement(DeviceScannerContainer, { onClose: () => setShowScanner(false), dpadIdsToActivate: missingSerialNumbers.map(device => device.dpadId), productId: productId, closeScanner: () => setShowScanner(false), onActivation: () => setActivatedAnimation(true), onSerialNumberFound: onSerialNumberFound })),
        React.createElement(SingleProductContainer, { onDelete: onDelete, product: product, close: () => setShowProductFullscreen(false), display: showProductFullscreen }),
        showDeleteModal && (React.createElement(Popup, { firstLine: languageStrings.REMOVE_PRODUCT_POPUP.REMOVE_PRODUCT, secondLine: languageStrings.REMOVE_PRODUCT_POPUP.REMOVE_PRODUCT_QUESTION, closePopup: () => setShowDeleteModal(false), 
            // TODO: dette er copy-pastet ved refaktorering, Popup komponenten bør refaktorers slik at knapper ikke lages her.
            // Eventuelt skifte navn på propsene slik at de gir mer mening
            button: React.createElement("div", { className: "popup__remove-buttons" },
                React.createElement("button", { onClick: () => setShowDeleteModal(false) }, languageStrings.REMOVE_PRODUCT_POPUP.CANCEL),
                React.createElement("button", { onClick: () => {
                        onDelete();
                        setShowDeleteModal(false);
                    } }, languageStrings.REMOVE_PRODUCT_POPUP.REMOVE)) }))));
}
ProductListElementContainer.propTypes = {
    product: PropTypes.shape({
        canBeRemoved: PropTypes.bool.isRequired,
        devices: PropTypes.arrayOf(PropTypes.any).isRequired,
        linkOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        productId: PropTypes.number.isRequired,
    }).isRequired,
    updateProduct: PropTypes.func.isRequired,
    deleteProduct: PropTypes.func.isRequired,
    languageStrings: PropTypes.objectOf(PropTypes.any).isRequired,
    updateAllProducts: PropTypes.func.isRequired,
    customerId: PropTypes.number.isRequired,
    requestAgreementPrice: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    languageStrings: state.NOB,
    customerId: state.workOrder.workOrder.customer.customerId,
});
const mapDispatchToProps = dispatch => ({
    updateProduct: newProduct => dispatch(updateSingleProduct(newProduct)),
    deleteProduct: deletedAgreementDetail => dispatch(removeProductSuccess(deletedAgreementDetail)),
    updateAllProducts: customerId => dispatch(requestCustomerProductsBackground(customerId)),
    requestAgreementPrice: () => dispatch(requestAgreementsPrice()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductListElementContainer);
