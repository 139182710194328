import removeFirstValueFromBracketString from './removeFirstValueFromBracketString';
export default function selectDiagnosticService(selectedServiceType) {
    const values = [];
    selectedServiceType.map(service => values.push({
        description: removeFirstValueFromBracketString(service.description),
        serviceId: service.serviceId,
        price: service.servicePrices.length ? service.servicePrices[0].amount : 0,
        time: service.timeUnitPerService,
        quantity: 1,
    }));
    return values;
}
