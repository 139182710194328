exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"backgroundFadedGreen": "#d0dedf",
	"backgroundGray": "#f2f2f2",
	"blackPrimary": "#1e1c1e",
	"blackSecondary": "#000",
	"buttonRed": "#f41c50",
	"darkStormyGray": "#444242",
	"errorRed": "#bf1000",
	"feedbackBlack": "#1d1b1d",
	"feedbackDarkestGray": "#221f1f",
	"feedbackDarkerGray": "#231f20",
	"feedbackBlue": "#7fdae4",
	"feedbackDarkGray": "#363335",
	"feedbackGreen": "#7dab7e",
	"feedbackPurple": "#a0025a",
	"grayButton": "#777",
	"grayHeading": "#f4f4f4",
	"grayPrimary": "#e8e8e8",
	"iconBlue": "#086670",
	"lightGrayButton": "#e9e8e7",
	"opacityWhiteLight": "hsla(0,0%,100%,.9)",
	"redRemove": "#e91145",
	"samGreen": "#1e851e",
	"samOrange": "#f5a623",
	"samRed": "#e81418",
	"samGray": "#777",
	"turquoiseBlue": "#008391",
	"warningOrange": "#f46200",
	"whitePrimary": "#fdfdfd",
	"whiteSecondary": "#fff",
	"yellowOnBlack": "#ffe500",
	"yellowPrimary": "#ffdc00"
};