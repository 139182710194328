import React from 'react';
import PropTypes from 'prop-types';
import Heading from '../Heading';
import Icon from '../../../../../icons';
import NOB from '../../../../../constants/language-strings';
import { SWITCH } from '../../../../../constants/iconNames';
import './changeProduct.scss';
import useForm from '../../../../../utils/customHooks/useForm';
import DeviceElement from './DeviceElement';
export default function ChangeProduct({ changeProductState: { changedProduct, dpadId, errorProducts, isFetchingChangeProduct }, clearErrorMessage, product, requestChangeProduct, }) {
    const onSubmit = values => {
        const reload = !(product.devices.length > 1);
        Object.entries(values)
            .filter(([, value]) => value)
            .forEach(([key, value]) => {
            requestChangeProduct(product.productId, key, value, reload);
        });
    };
    const { values, handleChange, handleSubmit, overrideValue } = useForm(() => {
        onSubmit(values);
    });
    const clearErrorMessages = dpadIdToRemove => {
        errorProducts.forEach(error => {
            if (parseInt(error.dpadId, 10) === dpadIdToRemove)
                clearErrorMessage(error);
        });
    };
    const onReset = dpad => {
        overrideValue(`${dpad}`, '');
        clearErrorMessages(dpad);
    };
    const handleInputChange = e => {
        e.persist();
        clearErrorMessages(parseInt(e.target.name, 10));
        handleChange(e);
    };
    // Nedenfor er copy-pastet etter refaktorering. Bør definitivt gjøres om til en egen komponent/container
    const mapDevices = devices => devices.map(device => {
        const hasChanged = changedProduct.includes(device.serialNumber);
        const errorMessage = errorProducts.find(errorProduct => parseInt(errorProduct.dpadId, 10) === device.dpadId)
            ? errorProducts
                .reverse()
                .find(errorProduct => parseInt(errorProduct.dpadId, 10) === device.dpadId).errorMessage
            : '';
        const serialNumberInput = {
            dpadId: device.dpadId,
            value: values[device.dpadId],
            errorMessage,
        };
        const onScannerDetect = result => {
            const regex = new RegExp('^[A-Z0-9]*$');
            if (regex.test(result.codeResult.code)) {
                overrideValue(`${device.dpadId}`, result.codeResult.code);
                clearErrorMessages(device.dpadId);
                const reload = !(product.devices.length > 1);
                requestChangeProduct(product.productId, device.dpadId, result.codeResult.code, reload);
            }
        };
        return (React.createElement(DeviceElement, { device: device, onReset: onReset, hasChanged: hasChanged, serialNumberInput: serialNumberInput, onScannerDetect: onScannerDetect, isFetching: isFetchingChangeProduct && parseInt(dpadId, 10) === device.dpadId, handleChange: handleInputChange, handleSubmit: handleSubmit, status: product.status, key: device.dpadId }));
    });
    return (React.createElement("div", { className: "change-product" },
        React.createElement(Heading, { icon: React.createElement(Icon, { name: SWITCH }), mainHeading: NOB.CHANGE_PRODUCT.SWAP_UNIT, subHeading: NOB.CHANGE_PRODUCT.ACTIVATE_UNIT }),
        React.createElement("div", { className: "change-product__input" }, mapDevices(product.devices))));
}
ChangeProduct.propTypes = {
    changeProductState: PropTypes.objectOf(PropTypes.any).isRequired,
    clearErrorMessage: PropTypes.func.isRequired,
    product: PropTypes.objectOf(PropTypes.any).isRequired,
    requestChangeProduct: PropTypes.func.isRequired,
};
