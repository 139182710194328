import { VERIFY_TOKEN_FAILURE, VERIFY_TOKEN_REQUEST, VERIFY_TOKEN_SUCCESS } from '../constants';
export const requestTokenVerification = ({ token }) => ({
    type: VERIFY_TOKEN_REQUEST,
    token,
});
export const successTokenVerification = ({ token, role }) => ({
    type: VERIFY_TOKEN_SUCCESS,
    token,
    role,
});
export const failureTokenVerification = ({ errorMessage }) => ({
    type: VERIFY_TOKEN_FAILURE,
    errorMessage,
});
