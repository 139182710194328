import React, { Component } from 'react';
const Timeout = Composition => class _Timeout extends Component {
    componentDidMount() {
        this.timeouts = [];
    }
    componentWillUnmount() {
        this.clearTimeouts();
    }
    setTimeout(handler, time) {
        this.timeouts.push(setTimeout(handler, time));
    }
    clearTimeouts() {
        this.timeouts.forEach(clearTimeout);
    }
    render() {
        const { timeouts, setTimeout, clearTimeouts } = this;
        return (React.createElement(Composition, Object.assign({ timeouts: timeouts, setTimeout: setTimeout, clearTimeouts: clearTimeouts }, this.props)));
    }
};
export default Timeout;
