import PropTypes from 'prop-types';
import React from 'react';
import { SlideToggle } from 'react-slide-toggle';
import * as Animations from '../../../../constants/animation';
import { ARROW_DOWN } from '../../../../constants/iconNames';
import Icon from '../../../../icons';
import NOB from '../../../../constants/language-strings';
import Variables from '../../../../styles/variables.scss';
import './technicalDetails.scss';
const TechnicalDetails = ({ technicalDetails, capacity }) => {
    const technicalDetailsList = Object.keys(technicalDetails);
    const Details = () => {
        const Azimuth5G = () => {
            var _a, _b, _c, _d;
            return (React.createElement("div", { className: "technical__details__container--items", key: "5G" },
                React.createElement("div", { className: "technical__details__container--item" },
                    React.createElement("div", { className: "technical__details__container--item--key" }, "Anbefalt retning i grader (5G)"),
                    React.createElement("div", null, (_b = (_a = capacity[1]) === null || _a === void 0 ? void 0 : _a.direction) !== null && _b !== void 0 ? _b : '-')),
                React.createElement("div", { className: "technical__details__container--item" },
                    React.createElement("div", { className: "technical__details__container--item--key" }, "Kapasitet / dekning"),
                    React.createElement("div", null, !capacity[1]
                        ? 'Ingen dekning'
                        : ((_c = capacity[1]) === null || _c === void 0 ? void 0 : _c.capacityLeft) === 0
                            ? 'Ingen kapasitet'
                            : (_d = capacity[1]) === null || _d === void 0 ? void 0 : _d.capacityLeft))));
        };
        const Azimuth4G = () => {
            var _a, _b, _c, _d;
            return (React.createElement("div", { className: "technical__details__container--items", key: "4G" },
                React.createElement("div", { className: "technical__details__container--item" },
                    React.createElement("div", { className: "technical__details__container--item--key" }, "Alternativ retning i grader (4G)"),
                    React.createElement("div", null, (_b = (_a = capacity[0]) === null || _a === void 0 ? void 0 : _a.direction) !== null && _b !== void 0 ? _b : '-')),
                React.createElement("div", { className: "technical__details__container--item" },
                    React.createElement("div", { className: "technical__details__container--item--key" }, "Kapasitet / dekning"),
                    React.createElement("div", null, !capacity[0]
                        ? 'Ingen dekning'
                        : ((_c = capacity[0]) === null || _c === void 0 ? void 0 : _c.capacityLeft) === 0
                            ? 'Ingen kapasitet'
                            : (_d = capacity[0]) === null || _d === void 0 ? void 0 : _d.capacityLeft))));
        };
        const AllTechnicalDetails = () => {
            return technicalDetailsList
                .filter(it => it.key != 'Azimuth_proposed')
                .map((key, index) => {
                return (React.createElement("div", { className: "technical__details__container--items", key: `${key}${index}` }, technicalDetails[key] && (React.createElement("div", { className: "technical__details__container--item" },
                    React.createElement("div", { className: "technical__details__container--item--key" }, key),
                    React.createElement("div", null, technicalDetails[key])))));
            });
        };
        return (React.createElement(React.Fragment, null,
            React.createElement(AllTechnicalDetails, null),
            capacity && (React.createElement(React.Fragment, null,
                React.createElement(Azimuth5G, null),
                React.createElement(Azimuth4G, null)))));
    };
    return (React.createElement("div", null, technicalDetailsList.length > 0 && (React.createElement(SlideToggle, { duration: Animations.DURATION, collapsed: true, easeExpand: Animations.EXPAND, easeCollapse: Animations.COLLAPSE, render: ({ onToggle, setCollapsibleElement, toggleState }) => (React.createElement("div", { className: "technical__details" },
            React.createElement("button", { onClick: onToggle, className: "technical__details__dropdown" },
                React.createElement("div", null,
                    React.createElement("span", { className: "technical__details__dropdown--text" }, NOB.WORKORDER_TABS.TECHNICAL_INFORMATION_HEADING),
                    React.createElement("span", { className: toggleState === Animations.EXPANDING || toggleState === Animations.EXPANDED
                            ? 'rotate-up'
                            : 'rotate-down' },
                        React.createElement(Icon, { name: ARROW_DOWN, stroke: Variables.iconBlue })))),
            React.createElement("div", { className: "technical__details__container", ref: setCollapsibleElement },
                React.createElement(Details, null)))) }))));
};
TechnicalDetails.propTypes = {
    technicalDetails: PropTypes.objectOf(PropTypes.any).isRequired,
    capacity: PropTypes.any,
};
export default TechnicalDetails;
