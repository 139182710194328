import React from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { FRONT_PAGE_PATH, SIGN_OUT_PATH, WORK_ORDER_ID_PATH, WORK_LOG_PATH, ADDRESS_SEARCH, CUSTOMER_INFO_PATH, } from '../constants/paths';
import InfoBox from '../components/InfoBox/InfoBox';
import SignOut from '../modules/Authentication/containers/SignOutContainer';
import WorkOrderContainer from '../modules/Workorder/containers/workOrder/WorkOrderContainer';
import WorkOrderListContainer from '../modules/WorkorderList/containers/WorkOrderListContainer';
import WorkLogContainer from '../modules/WorkLog/containers/WorkLogContainer';
import { AddressSearch } from '../features/address-search';
import CustomerPage from '../modules/Workorder/containers/customerPage/CustomerPage';
const Routes = () => (React.createElement(Switch, null,
    React.createElement(Route, { exact: true, path: FRONT_PAGE_PATH, render: () => React.createElement(WorkOrderListContainer, null) }),
    React.createElement(Route, { exact: true, path: WORK_ORDER_ID_PATH, render: ({ match }) => React.createElement(WorkOrderContainer, { workOrderId: +match.params.id }) }),
    React.createElement(Route, { exact: true, path: CUSTOMER_INFO_PATH, render: ({ match }) => React.createElement(CustomerPage, { customerId: +match.params.customerId }) }),
    React.createElement(Route, { exact: true, path: SIGN_OUT_PATH, render: () => React.createElement(SignOut, null) }),
    React.createElement(Route, { exact: true, path: WORK_LOG_PATH, render: ({ match }) => React.createElement(WorkLogContainer, { workOrderId: +match.params.id }) }),
    React.createElement(Route, { exact: true, path: ADDRESS_SEARCH, render: () => React.createElement(AddressSearch, null) }),
    React.createElement(Route, { path: "*", render: () => (React.createElement("div", { className: "no__know__route" },
            React.createElement(InfoBox, null))) })));
export default Routes;
